import { createSlice } from '@reduxjs/toolkit';
import api from 'services/api';

const initialState = {
  vendorList: [],
  selectedVendor: null,
  loadingVendors: false,
  loadedVendors: false,
  updatingVendor: false,
  updatedVendor: false,
  creatingVendor: false,
  createdVendor: false,
  loadingVendorDetails: false,
  loadedVendorDetails: false,
  loadVendorsError: '',
  loadVendorDetailsError: '',
  updateVendorError: '',
  createVendorError: '',
  updateVendorMessage: '',
  createVendorMessage: '',
  statusMessage: '',
  uploadingVendor: false,
  uploadedVendor: false,
  uploadVendorError: '',
  uploadVendorMessage: '',
};

export const vendors = createSlice({
  name: 'vendors',
  initialState,
  reducers: {
    loadVendorsStart: (state) => {
      state.loadingVendors = true;
      state.loadedVendors = false;
    },
    loadVendorsComplete: (state, { payload }) => {
      const { vendorList } = payload;
      state.vendorList = vendorList;
      state.loadedVendors = true;
      state.loadingVendors = false;
    },
    loadVendorsFailed: (state, { payload }) => {
      const { error } = payload;
      state.loadingVendors = false;
      state.loadedVendors = false;
      state.loadVendorsError = error;
    },
    updateVendorStart: (state) => {
      state.updatingVendor = true;
      state.updatedVendor = false;
    },
    updateVendorComplete: (state, { payload }) => {
      const { vendorList, updateVendorMessage, statusMessage } = payload;
      state.vendorList = vendorList;
      state.updatingVendor = false;
      state.updatedVendor = true;
      state.updateVendorMessage = updateVendorMessage;
      state.statusMessage = statusMessage;
    },
    updateVendorFailed: (state, { payload }) => {
      const { error } = payload;
      state.updatingVendor = false;
      state.updatedVendor = false;
      state.updateVendorError = error;
    },
    createVendorStart: (state) => {
      state.creatingVendor = true;
      state.createdVendor = false;
    },
    createVendorComplete: (state, { payload }) => {
      const { vendorList, createVendorMessage } = payload;
      state.vendorList = vendorList;
      state.createdVendor = true;
      state.creatingVendor = false;
      state.createVendorMessage = createVendorMessage;
    },
    createVendorFailed: (state, { payload }) => {
      const { error } = payload;
      state.creatingVendor = false;
      state.createdVendor = false;
      state.createVendorError = error;
    },
    loadVendorDetailsStart: (state) => {
      state.loadingVendorDetails = true;
      state.loadedVendorDetails = false;
    },
    loadVendorDetailsComplete: (state, { payload }) => {
      const { vendorDetails } = payload;
      state.selectedVendor = vendorDetails;
      state.loadingVendorDetails = false;
      state.loadedVendorDetails = true;
    },
    loadVendorDetailsFailed: (state, { payload }) => {
      const { error } = payload;
      state.loadingVendorDetails = false;
      state.loadedVendorDetails = false;
      state.loadVendorDetailsError = error;
    },
    clearSelectedVendor: (state) => {
      state.selectedVendor = null;
    },
    clearVendors: (state) => {
      state.vendorList = [];
      state.selectedVendor = null;
      state.loadingVendors = false;
      state.loadedVendors = false;
      state.loadVendorsError = '';
      state.updatedVendor = '';
      state.createdVendor = '';
      // state.updateVendorMessage = '';
      // state.createVendorMessage = '';
      // state.statusMessage = '';
    },
    uploadVendorsStart: (state) => {
      state.uploadingVendor = true;
      state.uploadedVendor = false;
    },
    uploadVendorsComplete: (state, { payload }) => {
      const { data } = payload;
      state.uploadingVendor = false;
      state.uploadedVendor = true;
      state.uploadVendorMessage = data.message;
    },
    uploadVendorsFailed: (state, { payload }) => {
      const { error } = payload;
      state.uploadingVendor = false;
      state.uploadedVendor = false;
      state.uploadVendorError = error;
      if (error) {
        state.uploadVendorMessage = error.message;
      }
    },
    clearUpdaloadVendorStates: (state) => {
      state.uploadingVendor = false;
      state.uploadedVendor = false;
      state.uploadVendorError = '';
      state.uploadVendorMessage = '';
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  loadVendorsStart,
  loadVendorsComplete,
  loadVendorsFailed,
  updateVendorStart,
  updateVendorComplete,
  updateVendorFailed,
  createVendorStart,
  createVendorComplete,
  createVendorFailed,
  loadVendorDetailsStart,
  loadVendorDetailsComplete,
  loadVendorDetailsFailed,
  clearSelectedVendor,
  clearVendors,
  uploadVendorsStart,
  uploadVendorsComplete,
  uploadVendorsFailed,
  clearUpdaloadVendorStates,
} = vendors.actions;

export default vendors.reducer;

//Async Functions
export const retrieveVendorList = () => async (dispatch) => {
  dispatch(loadVendorsStart);
  api.vendor.getVendors().then((res) => {
    const { results, error } = res;
    if (results) {
      dispatch(loadVendorsComplete({ vendorList: results }));
    } else {
      dispatch(loadVendorsFailed({ error: error || 'API_ERROR' }));
    }
  });
};

export const retrieveVendorDetails = (vendorSelected) => async (dispatch) => {
  dispatch(loadVendorDetailsStart);
  const { vendorID, vendorName } = vendorSelected;
  api.vendor
    .getVendorDetail(vendorID, vendorName === undefined ? '' : vendorName)
    .then((res) => {
      const { results, error } = res;
      if (results) {
        dispatch(loadVendorDetailsComplete({ vendorDetails: results }));
      } else {
        dispatch(loadVendorDetailsFailed({ error: error || 'API_ERROR' }));
      }
    });
};

export const updateVendor = (vendorDetails) => async (dispatch, getState) => {
  dispatch(updateVendorStart);
  const {
    vendors: { vendorList },
  } = getState();
  api.vendor.updateVendor(vendorDetails).then((res) => {
    const { results } = res;
    if (results.status !== 'ERROR') {
      const currentVendors = vendorList.map((vendor) => {
        if (vendor.vendorName === vendorDetails.vendorName) {
          return vendorDetails;
        }
        return vendors;
      });
      dispatch(
        updateVendorComplete({
          vendorList: currentVendors,
          updateVendorMessage: results.message,
          statusMessage: results.status,
        })
      );
    } else {
      dispatch(updateVendorFailed({ error: results.message || 'API_ERROR' }));
    }
  });
};

export const createVendor = (vendorDetails) => async (dispatch, getState) => {
  dispatch(createVendorStart);
  const {
    vendors: { vendorList },
  } = getState();
  api.vendor.createVendor(vendorDetails).then((res) => {
    const { results } = res;
    if (results.status !== 'ERROR') {
      const currentVendors = vendorList.map((vendor) => {
        if (vendor.vendorName === vendorDetails.vendorName) {
          return vendorDetails;
        }
        return vendor;
      });
      dispatch(
        createVendorComplete({
          vendorList: currentVendors,
          createVendorMessage: results.message,
        })
      );
    } else {
      dispatch(createVendorFailed({ error: results.message || 'API_ERROR ' }));
    }
  });
};

export const uploadVendorFile = (batchVendors) => async (dispatch) => {
  dispatch(uploadVendorsStart());
  api.vendor.uploadVendors(batchVendors).then((res) => {
    const { results, error } = res;
    if (results) {
      dispatch(
        uploadVendorsComplete({
          data: results,
        })
      );
    } else {
      dispatch(uploadVendorsFailed({ error: error || 'API_ERROR ' }));
    }
  });
};
