import React, { useState, useEffect } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { Divider } from 'antd';
import LoadingIcons from 'react-loading-icons';
import '../../NewOrdersStyle.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  submitNewOrder,
  submitUpdateOrder,
  clearSubmitUpdateOrder,
  clearSubmitNewOrder,
} from 'store/modules/order';
import PopUp from 'components/Common/Popup/PopUpWindow';

export default function ReviewOrderDetails(props) {
  const dispatch = useDispatch();
  const {
    submittedNewOrder,
    submittingNewOrder,
    submitNewOrderError,
    newOrderFailed,
    newOrderApiError,
    submittedUpdateOrder,
    submitUpdateOrderError,
    updateOrderFailed,
  } = useSelector((state) => state.orders);

  const [submitFormData, setSubmitFormData] = useState(props.formData);
  const [boardPeriod, setBoardPeriod] = useState('');
  const [optionMonth, setOptionMonth] = useState('');
  const [isSeen, setIsSeen] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [errorKey, setErrorKey] = useState();

  useEffect(() => {
    if (newOrderFailed === true || updateOrderFailed === false) {
      if (newOrderApiError === true) {
        setErrorMessage(JSON.stringify(Object.values(submitNewOrderError)[0]));
        setErrorKey(
          'A Field is Missing or is not in the correct format. Kindly check and add/update the required fields and adjust if it should be Alpha, Numeric or Alpha Numeric characters.'
        );
      } else if (newOrderApiError === false) {
        setErrorMessage(JSON.stringify(Object.values(submitNewOrderError)[0]));
        setErrorKey(
          JSON.stringify(Object.keys(submitNewOrderError)[0]) +
            ' is not in the correct format. Kindly check and add/update the required fields and adjust if it should be Alpha, Numeric or Alpha Numeric characters.'
        );
      } else {
        setErrorMessage();
        setErrorKey();
      }
    } else if (newOrderFailed === false || updateOrderFailed === true) {
      if (
        submitUpdateOrderError != null ||
        submitUpdateOrderError != undefined
      ) {
        setErrorMessage(
          JSON.stringify(Object.values(submitUpdateOrderError)[0])
        );
        setErrorKey(
          JSON.stringify(Object.keys(submitUpdateOrderError)[0]) +
            ' is not in the correct format. Kindly check and add/update the required fields and adjust if it should be Alpha, Numeric or Alpha Numeric characters.'
        );
      } else {
        setErrorMessage('');
        setErrorKey(
          'A Field is Missing or is not in the correct format. Kindly check and add/update the required fields and adjust if it should be Alpha, Numeric or Alpha Numeric characters.'
        );
      }
    } else {
      setErrorMessage();
      setErrorKey();
    }
  }, [submitNewOrderError, submitUpdateOrderError]);

  useEffect(() => {
    if (errorMessage != undefined || errorMessage != null) {
      setIsSeen(true);
    }
  }, [errorMessage]);

  const togglePop = () => {
    setIsSeen(!isSeen);
    setErrorMessage();
    setErrorKey();
    dispatch(clearSubmitUpdateOrder());
    dispatch(clearSubmitNewOrder());
  };

  useEffect(() => {
    if (submittedNewOrder === true || submittedUpdateOrder === true) {
      props.onModeChange('completedorder');
    }
  }, [submittedNewOrder, submittedUpdateOrder]);

  useEffect(() => {
    setBoardPeriod(
      props.formData.commoditySymbol +
        props.formData.futuresMonth.charAt(0) +
        props.formData.optionYear
    );

    setOptionMonth(props.formData.futuresMonth.charAt(0));
  }, []);

  const submitOrder = () => {
    const split = submitFormData.vendorName.split('-');
    const vendorID = split.pop();
    Object.assign(submitFormData, {
      vendorID: vendorID,
    });

    // if ('optionMonth' in submitFormData) {
    Object.assign(submitFormData, {
      optionMonth: submitFormData.futuresMonth.charAt(0),
    });
    // }

    if (!('boardPeriod' in submitFormData)) {
      Object.assign(submitFormData, {
        boardPeriod:
          submitFormData.commoditySymbol.replace(/\s/g, '') +
          submitFormData.futuresMonth.charAt(0) +
          submitFormData.optionYear,
      });
    } else {
      Object.assign(submitFormData, {
        boardPeriod:
          submitFormData.commoditySymbol.replace(/\s/g, '') +
          submitFormData.futuresMonth.charAt(0) +
          submitFormData.optionYear,
      });
    }

    [
      'locationName',
      'vendorName',
      'futuresMonth',
      'auditWHEN',
      'auditWHO',
      'cancelled',
      'fillDate',
      'fillPrice',
      'filled',
      'legacyID',
      'locked',
      'submitDate',
      'userName',
      'verified',
    ].forEach((key) => delete submitFormData[key]);

    if (!('id' in submitFormData)) {
      dispatch(submitNewOrder(submitFormData));
    } else {
      dispatch(submitUpdateOrder(submitFormData));
    }
  };

  const returnToForm = () => {
    props.onModeChange('form');
    if ('id' in submitFormData) {
      props.isUpdateMode('edit', submitFormData);
    } else {
      props.isUpdateMode('edit');
    }
  };

  const cancelOrder = () => {
    props.onModeChange('form');
    if ('id' in submitFormData) {
      props.onModeChange('list');
    } else {
      props.onModeChange('form');
      props.isUpdateMode('cancel');
    }
  };

  return submittingNewOrder ? (
    <LoadingIcons.Oval stroke="#006730" />
  ) : (
    <div className="newOrder-container">
      {isSeen ? <PopUp content={errorKey} toggle={togglePop} /> : null}
      <h2 style={{ marginLeft: '20px', marginTop: '10px' }}>
        Review Hedge Order
      </h2>
      <Divider style={{ margin: '10px' }} />
      <div className="new-order-detail">
        <div className="first-column">
          <div className="data-item" style={{ fontWeight: 'bold' }}>
            <label>Contract Type:</label>
            {submitFormData.contractType === undefined
              ? ''
              : ' ' + submitFormData.contractType}
          </div>
          <div className="data-item">
            <label>East/West:</label>
            {submitFormData.section === undefined
              ? ''
              : ' ' + submitFormData.section}
          </div>
          <div className="data-item">
            <label>Order Group:</label>
            {submitFormData.group === undefined
              ? ''
              : ' ' + submitFormData.group}
          </div>
          <div className="data-item" style={{ fontWeight: 'bold' }}>
            <label>Selling Location:</label>
            {submitFormData.locationName === undefined
              ? ''
              : ' ' + submitFormData.locationName}
          </div>
          <div className="data-item" style={{ fontWeight: 'bold' }}>
            <label>Vendor/Farmer:</label>
            {submitFormData.vendorName === undefined
              ? ''
              : ' ' + submitFormData.vendorName}
          </div>
          <div className="data-item">
            <label>Item Type:</label>
            {submitFormData.itemType === undefined
              ? ''
              : ' ' + submitFormData.itemType}
          </div>
          <div className="data-item" style={{ fontWeight: 'bold' }}>
            <label>Quantity :</label>
            {submitFormData.quantity === undefined
              ? ''
              : ' ' + submitFormData.quantity + ' ' + submitFormData.uom}
          </div>
          <div className="data-item">
            <label>Commodity:</label>
            {submitFormData.commodity === undefined
              ? ''
              : ' ' + submitFormData.commodity}
          </div>
          <div className="data-item">
            <label>Forecast Type:</label>
            {submitFormData.forecastType === undefined
              ? ''
              : ' ' + submitFormData.forecastType}
          </div>
          <div className="data-item">
            <label>Option Type:</label>
            {submitFormData.optionType === undefined
              ? ''
              : ' ' + submitFormData.optionType}
          </div>
          <div className="data-item">
            <label>Option Action:</label>
            {submitFormData.optionAction === undefined
              ? ''
              : ' ' + submitFormData.optionAction}
          </div>
          <div className="data-item">
            <label>Option Month:</label>
            {optionMonth === undefined ? '' : ' ' + optionMonth}
          </div>
          <div className="data-item">
            <label>Strike Price:</label>
            {submitFormData.strikePrice === undefined
              ? ''
              : ' ' + submitFormData.strikePrice}
          </div>
          <div className="data-item">
            <label>Contract Count:</label>
            {submitFormData.contractCount === undefined
              ? ''
              : ' ' + submitFormData.contractCount}
          </div>
          <div className="data-item" style={{ fontWeight: 'bold' }}>
            <label>Board Period:</label> {boardPeriod}
          </div>
          <div className="data-item" style={{ fontWeight: 'bold' }}>
            <label>Order Type:</label>
            {submitFormData.orderType === undefined
              ? ''
              : ' ' + submitFormData.orderType}
          </div>
        </div>
        <div className="second-column">
          <div className="data-item">
            <label>Exchange Type:</label>
            {submitFormData.exchangeType === undefined
              ? ''
              : ' ' + submitFormData.exchangeType}
          </div>
          <div className="data-item">
            <label>Basis Price:</label>
            {submitFormData.basisPrice === undefined
              ? ''
              : ' ' + submitFormData.basisPrice}
          </div>
          <div className="data-item" style={{ fontWeight: 'bold' }}>
            <label>Limit Price:</label>
            {submitFormData.wOlimitPrice === undefined
              ? ''
              : ' ' + submitFormData.wOlimitPrice}
          </div>
          <div className="data-item">
            <label>Working Order Type:</label>
            {submitFormData.wOtype === undefined
              ? ''
              : ' ' + submitFormData.wOtype}
          </div>
          <div className="data-item">
            <label>Stop Price:</label>
            {submitFormData.wOstopPrice === undefined
              ? ''
              : ' ' + submitFormData.wOstopPrice}
          </div>
          <div className="data-item" style={{ fontWeight: 'bold' }}>
            <label>Exchange Price:</label>
            {submitFormData.exchangePrice === undefined
              ? ''
              : ' ' + submitFormData.exchangePrice}
          </div>
          <div className="data-item" style={{ fontWeight: 'bold' }}>
            <label>Account Name and Number:</label>
            {submitFormData.exchangeAccount === undefined
              ? ''
              : ' ' + submitFormData.exchangeAccount}
          </div>
          <div className="data-item">
            <label>Delivery Period:</label>
            <div style={{ marginLeft: '20px' }}>
              <label>{'Start Date: '}</label>
              {submitFormData.deliveryStart === undefined
                ? ''
                : ' ' + submitFormData.deliveryStart.toDateString()}
              {/* {props.formData.deliveryStart === undefined
                ? ' '
                : props.formData.deliveryStart.toDateString()} */}
            </div>
            <div style={{ marginLeft: '20px' }}>
              <label>{'End Date: '}</label>
              {submitFormData.deliveryEnd === undefined
                ? ''
                : ' ' + submitFormData.deliveryEnd.toDateString()}
              {/* {props.formData.deliveryEnd === undefined
                ? ' '
                : props.formData.deliveryEnd.toDateString()} */}
            </div>
          </div>
          <div className="data-item">
            <label>Shipment:</label>
            {submitFormData.shipment === undefined
              ? ''
              : ' ' + submitFormData.shipment}
          </div>
          <div className="data-item">
            <label>FOB Price:</label>
            {submitFormData.foBprice === undefined
              ? ''
              : ' ' + submitFormData.foBprice}
          </div>
          <div className="data-item">
            <label>Ship Mode:</label>
            {submitFormData.shipMode === undefined
              ? ''
              : ' ' + submitFormData.shipMode}
          </div>
          <div className="data-item">
            <label>Contract Number:</label>
            {submitFormData.contractNumber === undefined
              ? ''
              : ' ' + submitFormData.contractNumber}
          </div>
          <div className="data-item">
            <label>Alt. Contract Type:</label>
            {submitFormData.altContractType === undefined
              ? ''
              : ' ' + submitFormData.altContractType}
          </div>
          <div className="data-item">
            <label>Other Deliv. Location/s:</label>
            {submitFormData.altLocation === undefined
              ? ''
              : ' ' + submitFormData.altLocation}
          </div>
          <div className="data-item">
            <label>Alt. Vendor:</label>
            {submitFormData.altVendor === undefined
              ? ''
              : ' ' + submitFormData.altVendor}
          </div>
          <div className="data-item">
            <label>Notes:</label>
            {submitFormData.note === undefined ? '' : ' ' + submitFormData.note}
          </div>
        </div>
      </div>
      <div className="action-buttons">
        <Button onClick={submitOrder}>Finalize</Button>
        <Button style={{ marginLeft: '10px' }} onClick={returnToForm}>
          Edit
        </Button>
        <Button style={{ marginLeft: '10px' }} onClick={cancelOrder}>
          Cancel
        </Button>
      </div>
    </div>
  );
}
