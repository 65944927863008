import React, { useState, useEffect } from 'react';
import SidebarItem from './SidebarItem';
import { SidebarTop } from './SidebarTop';
import './SideBarStyle.css';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function Sidebar(props) {
  const { userRoles } = useSelector((state) => state.currentUser);
  const [isOriginator, setIsOriginator] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isHedgeDesk, setIsHedgeDesk] = useState(false);
  const [isModerator, setIsModerator] = useState(false);

  useEffect(() => {
    setIsAdmin(Array.from(userRoles).includes('Administrator'));
    setIsModerator(Array.from(userRoles).includes('Moderator'));
    setIsOriginator(Array.from(userRoles).includes('Originator'));
    setIsHedgeDesk(Array.from(userRoles).includes('HedgeDesk'));
  }, []);

  return (
    <div className="appSidebar">
      <div className="fullBar">
        <div className="sidebarStyle">
          <SidebarTop />
          {isOriginator && !isAdmin ? (
            <Link to="/">
              <SidebarItem name="Home" />
            </Link>
          ) : (
            <Link to="/">
              <SidebarItem name="Home" />
            </Link>
          )}
          <Link to="/neworder">
            <SidebarItem name="New Order" />
          </Link>
          <Link to="/admin">
            <SidebarItem name="Admin" />
          </Link>
          <div className="version">v{props.version}</div>
        </div>
      </div>
    </div>
  );
}
