import * as React from 'react';
import {
  GridColumnMenuFilter,
  // GridColumnMenuGroup,
  // GridColumnMenuSort,
} from '@progress/kendo-react-grid';

import { formatDate } from '@telerik/kendo-intl';

export const ColumnMenu = (props) => {
  return (
    <div>
      <GridColumnMenuFilter {...props} {...props} expanded={true} />
      {/* <GridColumnMenuGroup {...props} {...props} expanded={true} /> */}
      {/* <GridColumnMenuSort {...props} {...props} expanded={true} /> */}
    </div>
  );
};

export const DateCell = (props) => {
  const value = props.dataItem[props.field];
  return <td>{formatDate(new Date(value), 'g')}</td>;
};
