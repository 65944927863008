export const locationFormModel = {
  columns: 2,
  formInputs: {
    active: {
      type: 'drop-down',
      isVisible: true,
      label: 'Status',
      value: '',
      column: 1,
      randomNumber: 1,
      options: [
        {
          value: '',
          label: 'Select Status',
        },
        {
          value: true,
          label: 'Activate',
        },
        {
          value: false,
          label: 'Deactivate',
        },
      ],
    },
    locationID: {
      type: 'input',
      isVisible: true,
      label: 'Location ID',
      value: '',
      column: 1,
      randomNumber: 1,
    },
    locationName: {
      type: 'input',
      isVisible: true,
      label: 'Location Name',
      value: '',
      column: 1,
      randomNumber: 1,
    },
    assignment: {
      type: 'input',
      isVisible: true,
      label: 'Assignment',
      value: '',
      column: 1,
      randomNumber: 1,
    },
    direction: {
      type: 'input',
      isVisible: true,
      label: 'Direction',
      value: '',
      column: 2,
      randomNumber: 1,
    },
    locationType: {
      type: 'input',
      isVisible: true,
      label: 'Location Type',
      value: '',
      column: 2,
      randomNumber: 1,
    },
    fullAddress: {
      type: 'input',
      isVisible: true,
      label: 'Full Address',
      value: '',
      column: 2,
      randomNumber: 1,
    },
  },
};

export const locationUpdateFormModel = {
  columns: 2,
  formInputs: {
    active: {
      type: 'drop-down',
      isVisible: true,
      label: 'Status',
      value: '',
      column: 1,
      randomNumber: 1,
      options: [
        {
          value: '',
          label: 'Select Status',
        },
        {
          value: true,
          label: 'Activate',
        },
        {
          value: false,
          label: 'Deactivate',
        },
      ],
    },
    locationID: {
      type: 'input',
      isVisible: true,
      label: 'Location ID',
      value: '',
      column: 1,
      randomNumber: 1,
    },
    locationName: {
      type: 'input',
      isVisible: true,
      label: 'Location Name',
      value: '',
      column: 1,
      randomNumber: 1,
    },
    assignment: {
      type: 'input',
      isVisible: true,
      label: 'Assignment',
      value: '',
      column: 1,
      randomNumber: 1,
    },
    direction: {
      type: 'input',
      isVisible: true,
      label: 'Direction',
      value: '',
      column: 2,
      randomNumber: 1,
    },
    locationType: {
      type: 'input',
      isVisible: true,
      label: 'Location Type',
      value: '',
      column: 2,
      randomNumber: 1,
    },
    fullAddress: {
      type: 'input',
      isVisible: true,
      label: 'Full Address',
      value: '',
      column: 2,
      randomNumber: 1,
    },
  },
};
