import React, { useEffect, useState } from 'react';
import api from 'services/api';
import LoadingIcons from 'react-loading-icons';

import VendorListContainer from './Container/VendorListContainer';
import NewVendorWindowContainer from './Container/NewVendorContainer';
import VendorUploadForm from './Container/VendorUploadFormContainer';
import VendorDetailContainer from './Container/VendorDetailContainer';
import { useDispatch, useSelector } from 'react-redux';
import { clearSelectedVendor, retrieveVendorList } from 'store/modules/vendor';

export default function VendorWindow(props) {
  const dispatch = useDispatch();
  const { selectedVendor } = useSelector((state) => state.vendors);
  const [currentMode, setCurrentMode] = useState('list');
  const [formData, setFormData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleModeChange = (mode) => {
    setCurrentMode(mode);
  };
  const handleValueUpdates = (childData) => {
    setFormData(childData);
  };

  const changeToList = () => {
    dispatch(clearSelectedVendor());
    dispatch(retrieveVendorList());
  };

  const handleCurrentContainer = () => {
    switch (currentMode) {
      case 'list':
        return <VendorListContainer onModeChange={handleModeChange} />;

      case 'form':
        return (
          <NewVendorWindowContainer
            onModeChange={handleModeChange}
            isUpdateMode={selectedVendor !== null}
            valuesUpdate={handleValueUpdates}
            returnBack={changeToList}
          />
        );

      case 'detail':
        return (
          <VendorDetailContainer
            onModeChange={handleModeChange}
            returnBack={changeToList}
          />
        );

      case 'upload':
        return (
          <VendorUploadForm
            returnBack={changeToList}
            onModeChange={handleModeChange}
          />
        );

      default:
        return <div>{"I'm sorry an error has occured"}</div>;
    }
  };
  return isLoading ? (
    <div className="vendor-loading-icon">
      <LoadingIcons.Oval stroke="#006730" />
      <br />
      <br />
    </div>
  ) : (
    <div className="vendor-form">
      <div> {handleCurrentContainer()} </div>
    </div>
  );
}
