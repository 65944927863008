import axios from 'axios';

const API_CONFIG = {
  //baseURL: '{%BaseURL%}',
  baseURL: 'https://grainhedgingapi.smithfield.com/api/',
  //baseURL: 'https://grainhedgingapi-qa.smithfield.com/api/',
  // baseURL: 'https://localhost:44323/api/',
  responseType: 'json',
};

const API_uploadCONFIG = {
  //baseURL: '{%BaseURL%}',
  baseURL: 'https://grainhedgingapi.smithfield.com/api/',
  //baseURL: 'https://grainhedgingapi-qa.smithfield.com/api/',
  // responseType: 'json',
};

const api = {
  client: axios.create(API_CONFIG),
  uploadclient: axios.create(API_uploadCONFIG),
};

export const initializeAPI = () => {};

export const initializeAPIwithAuthentication = (token) => {
  API_CONFIG.headers = {
    ...API_CONFIG.headers,
    Authorization: `BEARER ${token}`,
  };
  api.client = axios.create(API_CONFIG);

  API_uploadCONFIG.headers = {
    ...API_uploadCONFIG.headers,
    Authorization: `BEARER ${token}`,
    // 'Content-Type': `multipart/form-data; charset=utf-8; boundary=${+Math.random()
    //   .toString()
    //   .substring(2)}" ;`,
    // Accept: '*/*',
    'Content-Type': 'multipart/form-data',
  };

  api.uploadclient = axios.create(API_uploadCONFIG);
};

export default api;
