import api from '../../core/index';

const user = {
  getUserList: () => {
    const userList = `User/getlist/${false}`;
    return api.client.get(userList).then(
      (result) => {
        const { status, data } = result;
        if (status === 200) {
          return { results: data.payload };
        } else {
          return { error: 'Unknown Error' };
        }
      },
      (error) => {
        return { error };
      }
    );
  },
  getUserDetails: (userName) => {
    const userDetails = `User/get/${userName}`;
    return api.client.get(userDetails).then(
      (result) => {
        const { status, data } = result;
        if (status === 200) {
          return { results: data.payload };
        } else {
          return { error: 'Unknown Error' };
        }
      },
      (error) => {
        return { error };
      }
    );
  },
  getUserRole: (userName) => {
    const userRole = `User/memberof/${userName}`;
    return api.client.get(userRole).then(
      (result) => {
        const { status, data } = result;
        if (status === 200 && data.status === 'OK') {
          return {
            userRoles: data.payload,
            status: 'Success',
          };
        } else {
          return { error: 'Unexpected Error' };
        }
      },
      (error) => {
        console.log(error);
        return { error: error };
      }
    );
  },
  getRoleMembers: (roleName) => {
    const roleMembers = `/api/User/members/rolename${roleName}`;
    return api.client.get(roleMembers).then(
      (result) => {
        const { status, data } = result;
        if (status === 200 && data.status === 'OK') {
          return data.payload;
        } else {
          return {};
        }
      },
      (error) => {
        console.log(error);
      }
    );
  },
  createUser: (createUserDetails) => {
    const { userName, email, password, phone, fullName } = createUserDetails;
    const createUser = 'User/create';
    return api.client
      .post(createUser, {
        userName,
        email,
        password,
        phone,
        fullName,
        deviceName: 'TestPC',
      })
      .then(
        (result) => {
          return result;
        },
        (error) => {
          return error;
        }
      );
  },

  updateUserRole: (userDetails, action) => {
    const { userName, userType } = userDetails;
    const userMembership = 'User/membership';
    return api.client
      .put(userMembership, {
        userName,
        roles: action === 'Add' ? [userType] : userType,
      })
      .then(
        (result) => {
          const { status, data } = result;
          if (status === 200 || data.status === 'OK') {
            return { results: data, status: 'Success' };
          } else {
            return { error: 'Unknown Error' };
          }
        },
        (error) => {
          return { error };
        }
      );
  },

  updateUser: (updateUserDetails) => {
    const updateUser = 'User/update';
    return api.client
      .put(updateUser, {
        ...updateUserDetails,
        deviceName: 'TestPC',
        resetRequest: true,
      })
      .then(
        (result) => {
          const { status, data } = result;
          if (status === 200 || data.status === 'OK') {
            return { results: data, status: 'Success' };
          } else {
            return { error: 'Unknown Error' };
          }
        },
        (error) => {
          return { error };
        }
      );
  },
  updateUserStatus: (selectedUser) => {
    const { active, userName } = selectedUser;
    const updateStatus = '/User/status';
    return api.client
      .put(updateStatus, {
        userName,
        active,
        deviceName: 'TestPC',
      })
      .then(
        (result) => {
          const { status, data } = result;
          if (status === 200 || data.status === 'OK') {
            return { results: data, status: 'Success' };
          } else {
            return { error: 'Unknown Error' };
          }
        },
        (error) => {
          return { error };
        }
      );
  },
  resetUserPassword: (selectedUser) => {
    const { userName } = selectedUser;
    const userResetPassword = `User/resetpwd/${userName}`;
    return api.client.put(userResetPassword).then(
      (result) => {
        const { status, data } = result;
        if (status === 200 || data.status === 'OK') {
          return { results: data, status: 'Success' };
        } else {
          return { error: 'Unknown Error' };
        }
      },
      (error) => {
        return { error };
      }
    );
  },
  userChangePassword: (userName, newPassword, oldPassword) => {
    const changePassUrl = 'User/changepwd';
    return api.client
      .put(changePassUrl, {
        userName,
        newPassword,
        oldPassword,
        deviceName: 'TestPC',
      })
      .then(
        (result) => {
          return result;
        },
        (error) => {
          return error;
        }
      );
  },
};

export default user;
