import api from '../../core/index';

const vendor = {
  getVendors: () => {
    const vendorList = 'Vendor/GetList';
    return api.client.get(vendorList).then(
      (result) => {
        const { status, data } = result;
        if (status === 200) {
          return { results: data.payload };
        } else {
          return { error: 'Unknown Error' };
        }
      },
      (error) => {
        return { error };
      }
    );
  },
  getVendorDetail: (vendorID, vendorName) => {
    const vendorDetail = `Vendor/GetVendor?vendorID=${vendorID}&vendorName=${vendorName}`;
    return api.client.get(vendorDetail).then(
      (result) => {
        const { status, data } = result;
        if (status === 200) {
          return { results: data.payload[0] };
        } else {
          return { error: 'Unknown Error' };
        }
      },
      (error) => {
        return { error };
      }
    );
  },
  createVendor: (vendorDetails) => {
    const createUrl = 'Vendor/Create';
    return api.client
      .post(createUrl, {
        ...vendorDetails,
        active: vendorDetails.active === 'true' ? true : false,
        deviceName: 'TestPC',
      })
      .then(
        (result) => {
          const { status, data } = result;
          if (status === 200 || data.status === 'OK') {
            return { results: data, status: 'Success' };
          } else {
            return { error: 'Unknown Error' };
          }
        },
        (error) => {
          return { error };
        }
      );
  },
  updateVendor: (vendorDetails) => {
    const updateUrl = 'Vendor/Update';
    return api.client
      .put(updateUrl, {
        ...vendorDetails,
        deviceName: 'TestPC',
      })
      .then(
        (result) => {
          const { status, data } = result;
          if (status === 200 || data.status === 'OK') {
            return { results: data, status: 'Success' };
          } else {
            return { error: 'Unknown Error' };
          }
        },
        (error) => {
          return { error };
        }
      );
  },
  uploadVendors: (batchVendors) => {
    const uploadUrl = 'Vendor/Upload';
    return api.uploadclient.post(uploadUrl, batchVendors).then(
      (result) => {
        const { status, data } = result;
        if (status === 200 || data.status === 'OK') {
          return { results: data, status: 'Success' };
        } else {
          return { error: 'Unknown Error' };
        }
      },
      (error) => {
        return { error };
      }
    );
  },
};

export default vendor;
