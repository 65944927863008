export const userFormModel = {
  columns: 2,
  formInputs: {
    userName: {
      id: 0,
      type: 'input',
      isVisible: true,
      label: 'User Name',
      value: '',
      column: 1,
      randomNumber: 1,
      isDisabled: false,
    },
    fullName: {
      id: 1,
      type: 'input',
      isVisible: true,
      label: 'Full Name',
      value: '',
      column: 1,
      randomNumber: 1,
      isDisabled: false,
    },
    email: {
      id: 2,
      type: 'input',
      isVisible: true,
      label: 'Email',
      value: '',
      column: 1,
      randomNumber: 1,
      isDisabled: false,
    },
    password: {
      id: 3,
      type: 'password',
      isVisible: true,
      label: 'Password',
      value: '',
      column: 2,
      randomNumber: 1,
      isDisabled: false,
    },
    phone: {
      id: 4,
      type: 'input',
      isVisible: true,
      label: 'Contact Number',
      value: '',
      column: 2,
      randomNumber: 1,
      isDisabled: false,
    },
    userType: {
      type: 'drop-down',
      isVisible: true,
      label: 'User Type',
      placeholder: 'Choose User Type',
      value: '',
      column: 2,
      randomNumber: 1,
      options: [
        {
          value: '',
          label: 'Select User Type',
          flag: 0,
        },
        {
          value: 'Administrator',
          label: 'Administrator',
          flag: 1,
        },
        {
          value: 'Originator',
          label: 'Originator',
          flag: 2,
        },
        {
          value: 'Moderator',
          label: 'Moderator',
          flag: 3,
        },
      ],
      isDisabled: false,
    },
  },
};

export const userUpdateFormModel = {
  columns: 2,
  formInputs: {
    userName: {
      id: 0,
      type: 'input',
      isVisible: true,
      label: 'User Name',
      value: '',
      column: 1,
      randomNumber: 1,
      isDisabled: true,
    },
    fullName: {
      id: 0,
      type: 'input',
      isVisible: true,
      label: 'Full Name',
      value: '',
      column: 1,
      randomNumber: 1,
      isDisabled: false,
    },
    email: {
      id: 0,
      type: 'input',
      isVisible: true,
      label: 'Email',
      value: '',
      column: 2,
      randomNumber: 1,
      isDisabled: false,
    },
    phone: {
      id: 0,
      type: 'input',
      isVisible: true,
      label: 'Contact Number',
      value: '',
      column: 2,
      randomNumber: 1,
      isDisabled: false,
    },
  },
};
