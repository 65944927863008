import React from 'react';
import { Divider } from 'antd';
import { Button } from '@progress/kendo-react-buttons';
import { clearSubmitUpdateOrder } from 'store/modules/order';
import { useDispatch } from 'react-redux';

export default function OrderUpdateCompleted(props) {
  const dispatch = useDispatch();
  const backToList = () => {
    dispatch(clearSubmitUpdateOrder());
    props.onModeChange('list');
  };

  return (
    <div className="orderCompleted-container" style={{ textAlign: 'center' }}>
      <h2 style={{ marginLeft: '20px', marginTop: '10px' }}>
        Hedge Order Update
      </h2>
      <Divider style={{ margin: '10px 0px' }} />

      <div style={{ marginLeft: '20px', marginTop: '20px' }}>
        Your hedge order has been updated
      </div>
      <Button
        onClick={backToList}
        style={{
          backgroundColor: 'green',
          color: 'white',
          marginLeft: '20px',
          marginTop: '20px',
        }}
      >
        Back to Order List
      </Button>
    </div>
  );
}
