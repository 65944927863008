import { createSlice } from '@reduxjs/toolkit';
import api from 'services/api';
import jwt from 'jwt-decode';
import sessionCookies from 'utils/cookies/sessionCookies';

const initialState = {
  userRoles: [],
  userName: '',
  loadingUser: false,
  loadedUser: false,
  userLoadError: '',
};

export const currentUser = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    loadUserStart: (state) => {
      state.loadingUser = true;
      state.loadedUser = false;
    },
    loadUserComplete: (state) => {
      state.loadingUser = false;
      state.loadedUser = true;
    },
    loadUserFailed: (state, { payload }) => {
      const { error } = payload;
      state.loadingUser = false;
      state.loadedUser = false;
      state.userLoadError = error;
    },
    setUserRoles: (state, { payload }) => {
      const { userRoles, userName } = payload;
      state.userRoles = userRoles;
      state.userName = userName;
    },
    clearAuthorizationState: (state) => {
      state.userRoles = [];
      state.loadingUser = false;
      state.loadedUser = false;
      state.userLoadError = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  loadUserStart,
  loadUserComplete,
  loadUserFailed,
  setUserRoles,
  clearAuthorizationState,
} = currentUser.actions;

export default currentUser.reducer;

//Async Functions
export const retrieveUserRoles = (userName) => async (dispatch) => {
  const { token } = sessionCookies.getSessionCookies();
  const decodedToken = jwt(token);
  decodedToken.role =
    decodedToken[
      'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
    ];
  delete decodedToken[
    'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
  ];
  decodedToken.name =
    decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'];
  delete decodedToken[
    'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'
  ];

  dispatch(loadUserStart());

  if (Array.isArray(decodedToken.role)) {
    if (!Array.from(decodedToken.role).includes('Administrator')) {
      const userRoles = [decodedToken.role];
      dispatch(setUserRoles({ userRoles, userName }));
      dispatch(loadUserComplete());
    } else {
      const results = await api.user.getUserRole(userName);
      const { status, userRoles, error } = results;
      if (status === 'Success') {
        dispatch(setUserRoles({ userRoles, userName }));
        dispatch(loadUserComplete());
      } else {
        dispatch(loadUserFailed({ error }));
      }
    }
  } else {
    if (decodedToken.role !== 'Administrator') {
      const userRoles = [decodedToken.role];
      dispatch(setUserRoles({ userRoles, userName }));
      dispatch(loadUserComplete());
    } else {
      const results = await api.user.getUserRole(userName);
      const { status, userRoles, error } = results;
      if (status === 'Success') {
        dispatch(setUserRoles({ userRoles, userName }));
        dispatch(loadUserComplete());
      } else {
        dispatch(loadUserFailed({ error }));
      }
    }
  }
};
