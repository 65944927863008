import React, { useState, useEffect } from 'react';
import StandardForm from 'components/Common/Forms/StandardFormV2';
import { BiXCircle } from 'react-icons/bi';
import api from 'services/api';
import PopUp from 'components/Common/Popup/PopUpWindow';
import PropTypes from 'prop-types';
import { Divider, Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import '../VendorStyle.css';
import {
  vendorFormModel,
  vendorUpdateFormModel,
} from 'utils/forms/vendorListModel';
import { updateVendor, createVendor, clearVendors } from 'store/modules/vendor';

export default function NewVendorWindowContainer(props) {
  const dispatch = useDispatch();
  const {
    selectedVendor,
    updatedVendor,
    createdVendor,
    updateVendorMessage,
    statusMessage,
  } = useSelector((state) => state.vendors);

  const generateFormFromModel = () => {
    const initialForm = {};
    if (!props.isUpdateMode) {
      Object.keys(vendorFormModel.formInputs).forEach((formKey) => {
        const currentInput = vendorFormModel.formInputs[formKey];
        initialForm[formKey] = currentInput.value;
      });
    } else {
      Object.keys(vendorUpdateFormModel.formInputs).forEach((formKey) => {
        const newKey = formKey[0] + formKey.slice(1);
        const currentInput = selectedVendor[newKey];
        initialForm[formKey] = currentInput;
      });
    }
    return initialForm;
  };

  const [formData, setFormData] = useState(generateFormFromModel());
  const [successNotif, setSuccessNotif] = useState('');
  const [isSeen, setIsSeen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setFormData(generateFormFromModel());
  }, []);

  // useEffect(() => {
  //   handleShowModal();
  // }, [updatedVendor, createdVendor]);

  const handleValueUpdates = (newFormData) => {
    setFormData({ ...formData, ...newFormData });
  };

  // const handleShowModal = () => {
  //   if (
  //     updatedVendor === true &&
  //     updateVendorMessage === 'Entity Updated Successfully'
  //   ) {
  //     setIsSeen(!isSeen);
  //     setSuccessNotif('Update Success');
  //     dispatch(clearVendors());
  //     setShowModal(true);
  //   } else if (createdVendor === true) {
  //     setIsSeen(!isSeen);
  //     setSuccessNotif('Creation Success');
  //     dispatch(clearVendors());
  //     setShowModal(true);
  //   }
  // };

  const togglePop = () => {
    if (!props.isUpdateMode) {
      setIsSeen(!isSeen);
      setSuccessNotif('Creation Success');
    } else {
      setIsSeen(!isSeen);
      setSuccessNotif('Update Success');
    }
  };

  const submitChanges = (e) => {
    e.preventDefault();
    if (!props.isUpdateMode) {
      dispatch(createVendor(formData));
      togglePop();
    } else {
      dispatch(updateVendor(formData));
      togglePop();
    }
  };
  const returnHome = () => {
    props.returnBack();
    props.onModeChange('list');
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <div className="container">
      <div className="return-button" id="return-button">
        <h2>{!props.isUpdateMode ? 'Add a Vendor' : 'Update a Vendor'}</h2>

        <BiXCircle className="close-button" size={40} onClick={returnHome} />
      </div>
      <Divider />
      <StandardForm
        valuesUpdate={handleValueUpdates}
        formModel={
          !props.isUpdateMode ? vendorFormModel : vendorUpdateFormModel
        }
        formValues={formData}
      />
      {isSeen ? <PopUp content={successNotif} toggle={togglePop} /> : null}
      {/* <Modal
        visible={showModal}
        footer={null}
        onCancel={handleCloseModal}
        title={'Hello'}
      ></Modal> */}
      <div className="button-holder">
        <button className="middle-button" type="submit" onClick={submitChanges}>
          {!props.isUpdateMode ? 'Add Vendor' : 'Update Vendor'}
        </button>
      </div>
    </div>
  );
}

NewVendorWindowContainer.propTypes = {
  isUpdateMode: PropTypes.bool,
  returnBack: PropTypes.func,
  onModeChange: PropTypes.func,
};
