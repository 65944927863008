import React from 'react';
import Accordion from '../Common/Accordion/Accordion';
import OpenOrderWindow from './OpenOrders/OpenOrderWindow';
import HedgeFillsWindow from './HedgeFills/HedgeFillsWindow';

const subWindows = ['Open Orders', 'Hedge Fills'];
const subForms = [
  <OpenOrderWindow key="openOrderWindow" />,
  <HedgeFillsWindow key="hedgeFillsWindow" />,
];

export default function OriginatorScreen(props) {
  return (
    <div id="adminwindow" style={{ height: '100%' }}>
      <Accordion forms={subForms} data={subWindows}></Accordion>
    </div>
  );
}
