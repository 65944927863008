import React from 'react';
import { BiXCircle } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { Divider } from 'antd';
import LoadingIcons from 'react-loading-icons';
import PropTypes from 'prop-types';
import '../VendorStyle.css';

export default function VendorDetailContainer(props) {
  const { selectedVendor, loadedVendors, loadingVendors } = useSelector(
    (state) => state.vendors
  );

  const returnHome = () => {
    props.returnBack();
    props.onModeChange('list');
  };

  const updateVendor = () => {
    props.onModeChange('form');
  };

  if (loadingVendors) {
    <div>
      <LoadingIcons.Oval stroke="#006730" />
    </div>;
  }

  if (selectedVendor) {
    return (
      <div className="container">
        <div className="return-button" id="return-button">
          <h2>Vendor Details</h2>
          <BiXCircle className="close-button" size={40} onClick={returnHome} />
        </div>
        <Divider className="divider" />
        <br />
        <div>
          <label className="vendor-detail-label">Vendor ID:</label>{' '}
          {selectedVendor.vendorID}
        </div>
        <br />
        <div>
          <label className="vendor-detail-label">Vendor Name:</label>{' '}
          {selectedVendor.vendorName}
        </div>
        <br />
        <div>
          <label className="vendor-detail-label">Updated By:</label>{' '}
          {selectedVendor.auditWHO}
        </div>
        <br />
        <div>
          <label className="vendor-detail-label">Updated Date:</label>{' '}
          {selectedVendor.auditWHEN}
        </div>
        <br />
        <div>
          <label className="vendor-detail-label">Vendor EIN:</label>{' '}
          {selectedVendor.vendorEIN}
        </div>
        <br />
        <div>
          <label className="vendor-detail-label">Full Name:</label>{' '}
          {selectedVendor.fullName}
        </div>
        <br />
        <div>
          <label className="vendor-detail-label">Contact Email:</label>{' '}
          {selectedVendor.contactEmail}
        </div>
        <br />
        <div>
          <label className="vendor-detail-label">Contact Address:</label>{' '}
          {selectedVendor.contactAddress}
        </div>
        <br />
        <div>
          <label className="vendor-detail-label">Status:</label>{' '}
          {selectedVendor.active ? 'Active' : 'Inactive'}
        </div>
        <br />
        <div className="button-holder">
          <button className="middle-button" onClick={updateVendor}>
            Update Vendor Details
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div className="return-button" id="return-button">
        <h2 style={{ color: 'red' }}>NO DATA TO DISPLAY</h2>
        <BiXCircle className="close-button" size={40} onClick={returnHome} />
      </div>
    );
  }
}

VendorDetailContainer.propTypes = {
  returnBack: PropTypes.func,
  onModeChange: PropTypes.func,
};
