import React, { useState } from 'react';
import './FormStyle.css';
import { FormInput } from './FormParts/FormInput';
import CheckBox from './FormParts/CheckBox';
import { FormDropDown } from './FormParts/FormDropDown';
import { Input, Form, Select, Checkbox } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import PropTypes from 'prop-types';

export default function StandardForm({ valuesUpdate, formModel, formValues }) {
  const [form] = Form.useForm();
  const { Option } = Select;
  const handleChange = (value, name) => {
    valuesUpdate({ [name]: value });
  };

  const handleChangeInput = (event) => {
    const {
      target: { value, name },
    } = event;

    handleChange(value, name);
  };

  const renderFormLayout = () => {
    return Object.keys(formModel.formInputs).map((formKey, index) => {
      const currentField = formModel.formInputs[formKey];
      const currentFieldValue = formValues[formKey];
      const disableInput = formModel.formInputs[formKey].isDisabled;
      switch (currentField.type) {
        case 'input':
          return (
            <Form.Item
              key={formKey}
              label={currentField.label}
              className="form-item-row"
              style={{ margin: 0, padding: '0.5rem' }}
            >
              <Input
                disabled={disableInput}
                name={formKey}
                value={currentFieldValue}
                onChange={handleChangeInput}
              />
            </Form.Item>
          );

        case 'drop-down':
          return (
            <Form.Item
              key={formKey}
              label={currentField.label}
              className="form-item-row"
              style={{ margin: 0, padding: '0.5rem' }}
            >
              <Select
                name={formKey}
                placeholder={currentField.placeholder || ''}
                onChange={(value) => {
                  handleChange(value, formKey);
                }}
              >
                {currentField.options.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          );

        case 'password':
          return (
            <Form.Item
              key={formKey}
              label={currentField.label}
              className="form-item-row"
              style={{ margin: 0, padding: '0.5rem' }}
            >
              <Input.Password
                name={formKey}
                value={currentFieldValue}
                onChange={handleChangeInput}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
          );

        case 'checkbox':
          return (
            <Form.Item key={formKey} label={currentField.label}>
              {/* <CheckBox
                value={currentFieldValue}
                onChange={handleChange}
                name={formKey}
                label={currentField.label}
                checkboxItems={currentField.options}
              /> */}
              <Checkbox.Group
                options={currentField.options}
                onChange={(checkedValues) => {
                  handleChange(checkedValues, formKey);
                }}
                value={currentFieldValue}
              />
            </Form.Item>
          );
        default:
          break;
      }
      return null;
    });
  };

  const createContainers = () => {
    // let content = [];
    // let calcWidth = '';
    // const { columns } = formModel;

    // if (columns === 3) {
    //   calcWidth = '50%';
    // } else {
    //   calcWidth = 100 / columns + '%';
    // }

    // var theStyle = { float: 'right', width: calcWidth };

    // for (let i = columns; i > 0; i--) {
    //   content.push(
    //     <div style={theStyle}>
    //       <div className="standard-form-item">
    //         <Form
    //           form={form}
    //           onFinish={() => {
    //             console.log('finish');
    //           }}
    //         >
    //           {renderFormLayout(i)}
    //         </Form>
    //       </div>
    //     </div>
    //   );
    // }
    return (
      <div className="standard-form-item">
        <Form
          layout="inline"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          form={form}
          onFinish={() => {
            console.log('finish');
          }}
        >
          {renderFormLayout()}
        </Form>
      </div>
    );
  };

  return <div id="form-window">{createContainers()}</div>;
}

StandardForm.propTypes = {
  valuesUpdate: PropTypes.func,
  formModel: PropTypes.object,
  formValues: PropTypes.object,
};
