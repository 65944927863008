import React from 'react';
import '../UserWindowStyle.css';
import { BiXCircle } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import LoadingIcons from 'react-loading-icons';
import PropTypes from 'prop-types';

export default function UserDetailsContainer({ returnBack, onModeChange }) {
  const { selectedUser, loadingUsers, loadedUsers } = useSelector(
    (state) => state.users
  );

  const returnHome = () => {
    returnBack();
    onModeChange('list');
  };

  const updateUser = () => {
    onModeChange('form');
  };

  return !loadingUsers && selectedUser ? (
    <div className="container">
      <div className="return-button" id="return-button">
        <h2>User Details</h2>
        <BiXCircle size={40} className="close-button" onClick={returnHome} />
      </div>
      <br />
      <div>
        <label className="user-detail-label">Full Name: </label>
        {selectedUser.fullName}
      </div>
      <br />
      <div>
        <label className="user-detail-label">Email: </label>
        {selectedUser.email}
      </div>
      <br />
      <div>
        <label className="user-detail-label">Contact Number: </label>
        {selectedUser.phone}
      </div>
      <br />
      <div>
        <label className="user-detail-label">Status: </label>
        {selectedUser.active ? 'Active' : 'Inactive'}
      </div>
      <br />
      <div>
        <label className="user-detail-label">ASP ID: </label>
        {selectedUser.aspID}
      </div>
      <br />
      <div>
        <label className="user-detail-label">Updated By: </label>
        {selectedUser.auditWHO}
      </div>
      <br />
      <div>
        <label className="user-detail-label">Updated Date: </label>
        {selectedUser.auditWHEN}
      </div>
      <br />
      <div className="button-holder">
        <button className="middle-button" onClick={updateUser}>
          Update User Details
        </button>
      </div>
    </div>
  ) : (
    <div className="loading-details">
      <LoadingIcons.Oval stroke="#006730" />
    </div>
  );
}

UserDetailsContainer.propTypes = {
  returnBack: PropTypes.func,
  onModeChange: PropTypes.func,
};
