import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ChangePasswordForm from './ChangePasswordForm';
import usePasswordUpdate from 'hooks/usePasswordUpdate';
import Loader from 'react-loader-spinner';
import './AccountSettings.css';
import PopUp from 'components/Common/Popup/PopUpWindow';

export default function AccountSettingsWindow() {
  // const { userName, userRoles } = useSelector((state) => state.user);
  const {
    updatingPassword,
    // passwordUpdated,
    // updateError,
    successMessage,
    showModal,
    changePassword,
  } = usePasswordUpdate();
  const { userRoles, userName } = useSelector((state) => state.currentUser);
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSeen, setIsSeen] = useState(false);

  useEffect(() => {
    if (showModal) {
      setIsSeen(true);
    }
  }, [showModal]);

  const handleChange = (event) => {
    const {
      target: { value, name },
    } = event;

    if (name === 'currentPassword') {
      setCurrentPassword(value);
    } else if (name === 'newPassword') {
      setNewPassword(value);
    } else if (name === 'confirmPassword') {
      setConfirmPassword(value);
    }
  };

  const submitCredentials = () => {
    changePassword(userName, currentPassword, newPassword);
  };

  const handleChangePasswordButton = () => {
    setIsChangingPassword(true);
  };

  const togglePop = () => {
    setIsSeen(!isSeen);
  };

  return (
    <div className="account-setting-page">
      {isSeen ? <PopUp content={successMessage} toggle={togglePop} /> : null}
      {updatingPassword ? (
        <Loader type="TailSpin" color="#00732E" />
      ) : (
        <>
          <div className="account-setting-section">
            <span className="account-settings-label">Username: </span>
            <span>{userName}</span>
          </div>
          <div className="account-setting-section">
            <span className="account-settings-label">{`User Type${
              userRoles.length > 1 ? 's' : ''
            }:`}</span>
            {userRoles.map((role, index) => (
              <span key={index}>
                {role}
                {userRoles.length >= 2 ? ', ' : ''}
              </span>
            ))}
          </div>
          <div className="account-setting-section">
            {!isChangingPassword ? (
              <div className="account-setting-password-display">
                <span className="account-settings-label">Password</span>
                <button onClick={handleChangePasswordButton}>Change</button>
              </div>
            ) : (
              <ChangePasswordForm
                onChange={handleChange}
                currentPassword={currentPassword}
                newPassword={newPassword}
                confirmPassword={confirmPassword}
                onSubmitPasswordChange={submitCredentials}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
}
