import React from 'react';
import Accordion from '../Common/Accordion/Accordion';
// import { NewOrderContainer } from './Containers/NewOrderContainer';
// import { BalancingOrderContainer } from './Containers/BalancingOrderContainer';
// import { SoybeanSeedOrderContainer } from './Containers/SoybeanSeedOrderContainer';
import NewHedgeOrderWindow from './NewHedgeOrders/NewHedgeOrderWindow';

export class NewOrderScreen extends React.Component {
  state = {
    window: '0',
    xHidden: false,
  };

  subWindows = [
    'New Hedge Order',
    // 'Balancing Order ',
    // 'Soybean Seed Order',
    // 'Wheat Seed Order',
  ];
  subForms = [
    <NewHedgeOrderWindow key="newHedgeOrder" />,
    // <NewOrderContainer key="newOrder" />,
    // <BalancingOrderContainer key="balancingOrder" />,
    // <SoybeanSeedOrderContainer key="soybeenOrder" />,
    <br key="1" />,
    <br key="2" />,
  ];

  render() {
    return (
      <div id="adminwindow" style={{ height: '100%' }}>
        <Accordion forms={this.subForms} data={this.subWindows}></Accordion>
      </div>
    );
  }
}
