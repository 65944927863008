import React, { useState, useEffect } from 'react';
import StandardForm from '../../../Common/Forms/StandardFormV2';
import api from 'services/api';
import '../LocationStyle.css';
import PopUp from 'components/Common/Popup/PopUpWindow';
import { BiXCircle } from 'react-icons/bi';
import PropTypes from 'prop-types';
import { Divider } from 'antd';
import { useSelector } from 'react-redux';
import {
  locationFormModel,
  locationUpdateFormModel,
} from 'utils/forms/locationListModel';
export default function NewLocationWindowContainer(props) {
  const { selectedLocation } = useSelector((state) => state.locations);

  const generateFormFromModel = () => {
    const initialForm = {};
    if (!props.isUpdateMode) {
      Object.keys(locationFormModel.formInputs).forEach((formKey) => {
        const currentInput = locationFormModel.formInputs[formKey];
        initialForm[formKey] = currentInput.value;
      });
    } else {
      Object.keys(locationUpdateFormModel.formInputs).forEach((formKey) => {
        const newKey = formKey[0] + formKey.slice(1);
        const currentInput = selectedLocation[newKey];
        initialForm[formKey] = currentInput;
      });
    }
    return initialForm;
  };

  const [formData, setFormData] = useState(generateFormFromModel());
  const [successNotif, setSuccessNotif] = useState('');
  const [isSeen, setIsSeen] = useState(false);

  useEffect(() => {
    setFormData(generateFormFromModel());
  }, []);

  const handleValueUpdates = (newFormData) => {
    setFormData({ ...formData, ...newFormData });
  };

  const togglePop = () => {
    if (!props.isUpdateMode) {
      setIsSeen(!isSeen);
      setSuccessNotif('Creation Success');
    } else {
      setIsSeen(!isSeen);
      setSuccessNotif('Update Success');
    }
  };
  const submitChanges = (e) => {
    e.preventDefault();
    if (!props.isUpdateMode) {
      api.location.createLocation(formData).then((results) => {
        if (results.status === 'Success' && results.results.status === 'OK') {
          togglePop();
        }
      });
    } else {
      api.location.updateLocation(formData).then((results) => {
        if (results.status === 'Success' && results.results.status === 'OK') {
          togglePop();
        }
      });
    }
  };

  const returnHome = () => {
    props.returnBack();
    props.onModeChange('list');
  };

  return (
    <div className="container">
      <div className="return-button" id="return-button">
        <h2>{!props.isUpdateMode ? 'Add a Location' : 'Update a Location'}</h2>

        <BiXCircle size={40} onClick={returnHome} className="close-button" />
      </div>
      <Divider />
      <StandardForm
        valuesUpdate={handleValueUpdates}
        formModel={
          !props.isUpdateMode ? locationFormModel : locationUpdateFormModel
        }
        formValues={formData}
      />
      {isSeen ? <PopUp content={successNotif} toggle={togglePop} /> : null}
      <div className="button-holder">
        <button className="middle-button" type="submit" onClick={submitChanges}>
          Submit
        </button>
      </div>
    </div>
  );
}
NewLocationWindowContainer.propTypes = {
  isUpdateMode: PropTypes.bool,
  returnBack: PropTypes.func,
  onModeChange: PropTypes.func,
};
