import React, { Component } from 'react';
import { BiXCircle } from 'react-icons/bi';

export default function PopUp(props) {
  const confirmStatus = () => {
    if (props.isResetPwd === true) {
      props.resetPassword(props.userName);
    } else {
      props.userActivation(props.userName, props.status);
    }
  };

  return (
    <div className="popup-modal">
      <div className="popup-modal_content">
        <span
          className="close"
          style={{ cursor: 'pointer', float: 'right' }}
          onClick={props.toggle}
        >
          <BiXCircle size={40} />
        </span>
        {props.content}
        {props.showConfirm ? (
          <button
            style={{ marginLeft: '125px', marginTop: '20px' }}
            onClick={confirmStatus}
          >
            Confirm
          </button>
        ) : null}
      </div>
    </div>
  );
}
