import React from 'react';
import Accordion from '../Common/Accordion/Accordion';
import AllOrdersWindow from './AllOrders/AllOrdersWindow';
import AllFillsWindow from './AllFills/AllFillsWindow';

const subWindows = ['All Orders', 'All Fills'];
const subForms = [
  <AllOrdersWindow key="allOrdersWindow" />,
  <AllFillsWindow key="allFillsWindow" />,
];

export default function OrderScreen(props) {
  return (
    <div id="adminwindow" style={{ height: '100%' }}>
      <Accordion forms={subForms} data={subWindows}></Accordion>
    </div>
  );
}
