import api from '../../core/index';

const lov = {
  getLOVList: (lovCode) => {
    const lovList = `Utility/GetDropDownList?lovType=${lovCode}`;
    return api.client.get(lovList).then(
      (result) => {
        const { status, data, config } = result;
        if (status === 200) {
          return { results: data.payload, config: config.url };
        } else {
          return { error: 'Unknown Error' };
        }
      },
      (error) => {
        return { error };
      }
    );
  },
  getLocationLOVList: (lovCode) => {
    const lovList = `Location/GetList?activeonly=true&direction=${lovCode}`;
    return api.client.get(lovList).then(
      (result) => {
        const { status, data } = result;
        if (status === 200 && data.message === 'Successful Entity List Query') {
          return { results: data.payload };
        } else {
          return { error: 'Unknown Error' };
        }
      },
      (error) => {
        return { error };
      }
    );
  },
  getLocationInfo: (lovName) => {
    const lovList = `Location/GetList?activeonly=true&nameLike=${lovName}`;
    return api.client.get(lovList).then(
      (result) => {
        const { status, data } = result;
        if (status === 200 && data.message === 'Successful Entity List Query') {
          return { results: data.payload };
        } else {
          return { error: 'Unknown Error' };
        }
      },
      (error) => {
        return { error };
      }
    );
  },
  getVendorLOVList: () => {
    const lovList = `Vendor/GetList?activeonly=true`;
    return api.client.get(lovList).then(
      (result) => {
        const { status, data } = result;
        if (status === 200 && data.message === 'Successful Entity List Query') {
          return { results: data.payload };
        } else {
          return { error: 'Unknown Error' };
        }
      },
      (error) => {
        return { error };
      }
    );
  },

  getVendorInfo: (lovName) => {
    const lovList = `Vendor/GetList?activeonly=true&nameLike=${lovName}`;
    return api.client.get(lovList).then(
      (result) => {
        const { status, data } = result;
        if (status === 200 && data.message === 'Successful Entity List Query') {
          return { results: data.payload };
        } else {
          return { error: 'Unknown Error' };
        }
      },
      (error) => {
        return { error };
      }
    );
  },
};

export default lov;
