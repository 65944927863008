import React, { useEffect, useState } from 'react';
import LoadingIcons from 'react-loading-icons';
import { useDispatch, useSelector } from 'react-redux';
import NewHedgeOrder from './Containers/NewHedgeOrder';
import ReviewOrderDetails from './Containers/ReviewOrderDetails';
import OrderCompleted from './Containers/OrderCompleted';

export default function NewHedgeOrderWindow(props) {
  const dispatch = useDispatch();

  const [currentMode, setCurrentMode] = useState('form');
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState();
  const [isUpdate, setIsUpdate] = useState(false);

  const handleModeChange = (mode) => {
    setCurrentMode(mode);
  };

  const formMode = (mode) => {
    if (mode === 'edit') {
      setIsUpdate(true);
      setFormData(formData);
    } else {
      setIsUpdate(false);
      setFormData();
    }
  };

  const handleFormValues = (formData) => {
    setFormData(formData);
  };

  const handleCurrentContainer = () => {
    switch (currentMode) {
      case 'form':
        return (
          <NewHedgeOrder
            onModeChange={handleModeChange}
            formValue={handleFormValues}
            isUpdate={isUpdate}
            initialValues={formData}
          />
        );
      case 'detailreview':
        return (
          <ReviewOrderDetails
            onModeChange={handleModeChange}
            formData={formData}
            isUpdateMode={formMode}
          />
        );

      case 'completedorder':
        return (
          <OrderCompleted
            onModeChange={handleModeChange}
            isUpdateMode={formMode}
          />
        );

      // case 'detail':
      //   return (
      //     <LocationDetailContainer
      //       onModeChange={handleModeChange}
      //       returnBack={changeToList}
      //     />
      //   );

      default:
        return <div>{"I'm sorry an error has occured"}</div>;
    }
  };

  return isLoading ? (
    <div className="order-loading-icon">
      <LoadingIcons.Oval stroke="#006730" />
      <br /> <br />
    </div>
  ) : (
    <div className="order-form">
      <div className="order-form-container">{handleCurrentContainer()}</div>
    </div>
  );
}
