import React, { useState, useEffect } from 'react';
import StandardForm from '../../../Common/Forms/StandardFormV2';
import '../UserWindowStyle.css';
import api from 'services/api';
import PopUp from 'components/Common/Popup/PopUpWindow';
import { BiXCircle } from 'react-icons/bi';
import PropTypes from 'prop-types';
import { Divider } from 'antd';
import { userFormModel, userUpdateFormModel } from 'utils/forms/usersListModel';
import { useSelector } from 'react-redux';
// *********** REDUX WAY - NOT YET COMPELTED*************//
// import { useSelector, useDispatch } from 'react-redux';
// import { createUser, updateUserMembership } from 'store/modules/users';
export default function NewUserWindowContainer(props) {
  const { selectedUser } = useSelector((state) => state.users);

  // *********** REDUX WAY - NOT YET COMPELTED*************//
  // const dispatch = useDispatch();
  // const { selectedUser, apiResponse } = useSelector((state) => state.users);

  const generateFormFromModel = () => {
    const initialForm = {};
    if (!props.isUpdateMode) {
      Object.keys(userFormModel.formInputs).forEach((formKey) => {
        const currentInput = userFormModel.formInputs[formKey];
        initialForm[formKey] = currentInput.value;
      });
    } else {
      Object.keys(userUpdateFormModel.formInputs).forEach((formKey) => {
        const newKey = formKey[0] + formKey.slice(1);
        const currentInput = selectedUser[newKey];
        initialForm[formKey] = currentInput;
      });
    }
    return initialForm;
  };

  const [formData, setFormData] = useState(generateFormFromModel());
  const [successNotif, setSuccessNotif] = useState('');
  const [isSeen, setIsSeen] = useState(false);

  useEffect(() => {
    setFormData(generateFormFromModel());
  }, []);

  const handleValueUpdates = (newFormData) => {
    setFormData({ ...formData, ...newFormData });
  };

  const togglePop = () => {
    if (!props.isUpdateMode) {
      setIsSeen(!isSeen);
    } else {
      setIsSeen(!isSeen);
    }
  };
  /******* USING REDUX WAY - NOT YET COMPLETE ********/
  // const submitChanges = (e) => {
  //   e.preventDefault();
  //   if (!props.isUpdateMode) {
  //     dispatch(createUser(formData));
  //     if (apiResponse.status === 200) {
  //       const action = 'Add';
  //       dispatch(updateUserMembership(formData, action));
  //       if (apiResponse.status === 200) {
  //         togglePop();
  //       }
  //     }
  //     // api.user.createUser(formData).then((results) => {
  //     //   if (results.status === 200) {
  //     //     const action = 'Add';
  //     //     api.user.userMembership(formData, action).then((results) => {
  //     //       if (results.status === 200) {
  //     //         togglePop();
  //     //       }
  //     //     });
  //     //   }
  //     // });
  //   } else {
  //     api.user.updateUser(formData).then((results) => {
  //       if (results.status === 200) {
  //         togglePop();
  //       }
  //     });
  //   }
  // };

  const submitChanges = (e) => {
    e.preventDefault();
    if (!props.isUpdateMode) {
      const action = 'Add';
      api.user.createUser(formData).then((results) => {
        if (results.status === 200 && results.data.status !== 'ERROR') {
          api.user.updateUserRole(formData, action).then((results) => {
            if (results.status === 'Success') {
              togglePop();
              setSuccessNotif('Creation Success');
            }
          });
        } else {
          togglePop();
          setSuccessNotif(results.data.message);
        }
      });
    } else {
      api.user.updateUser(formData).then((results) => {
        if (results.status === 'Success') {
          togglePop();
          setSuccessNotif(results.results.message);
        } else {
          togglePop();
          setSuccessNotif(results.results.message);
        }
      });
    }
  };

  const returnHome = () => {
    props.returnBack();
    props.onModeChange('list');
  };

  return (
    <div className="user-container">
      <div className="return-button">
        <h2 className="form-header">
          {!props.isUpdateMode ? 'Input User Data' : 'Update User Data'}
        </h2>
        <BiXCircle
          style={{ cursor: 'pointer' }}
          size={40}
          onClick={returnHome}
        />
      </div>
      <Divider />
      <StandardForm
        valuesUpdate={handleValueUpdates}
        formModel={!props.isUpdateMode ? userFormModel : userUpdateFormModel}
        formValues={formData}
      />
      {isSeen ? <PopUp content={successNotif} toggle={togglePop} /> : null}
      <div className="btn-holder">
        <button className="add-user-btn" onClick={submitChanges}>
          {!props.isUpdateMode ? 'Add User' : 'Update User'}
        </button>
      </div>
    </div>
  );
}

NewUserWindowContainer.propTypes = {
  isUpdateMode: PropTypes.bool,
  returnBack: PropTypes.func,
  onModeChange: PropTypes.func,
  selectedUser: PropTypes.object,
};
