import React, { useState, useEffect } from 'react';
import { Table, Button, Input, Space } from 'antd';
import '../VendorStyle.css';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  retrieveVendorList,
  retrieveVendorDetails,
} from 'store/modules/vendor';
export default function VendorListContainer(props) {
  const dispatch = useDispatch();
  const { vendorList, selectedVendor, loadingVendors, loadedVendors } =
    useSelector((state) => state.vendors);

  const [filteredVendorList, setFilteredVendorList] = useState(vendorList);
  const [formData, setFormData] = useState([]);

  useEffect(() => {
    dispatch(retrieveVendorList());
  }, []);

  useEffect(() => {
    if (loadedVendors) {
      setFilteredVendorList(vendorList);
    }
  }, [vendorList]);

  const handleVendorSelection = (vendorSelected) => {
    dispatch(retrieveVendorDetails(vendorSelected));
    props.onModeChange('detail');
  };

  const handleValueUpdates = (childData) => {
    setFormData(childData);
  };

  const handleAddVendor = () => {
    props.onModeChange('form');
  };

  const handleUploadVendor = () => {
    props.onModeChange('upload');
  };

  const onSearch = (event) => {
    const {
      target: { value },
    } = event;

    const newVendorList = vendorList.filter(
      (vendor) =>
        vendor.vendorID.toLowerCase().includes(value.toLowerCase()) ||
        vendor.vendorName.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredVendorList(newVendorList);
  };

  const vendorColumns = [
    {
      title: 'Vendor ID',
      dataIndex: 'vendorID',
      key: 'vendorID',
      render: (text, record) => (
        <Button type="link" onClick={() => handleVendorSelection(record)}>
          {text}
        </Button>
      ),
      onHeaderCell: () => {
        return { className: 'vendor-header-cell' };
      },
    },
    {
      title: 'Vendor Name',
      dataIndex: 'vendorName',
      key: 'vendorName',
      width: 500,
      onHeaderCell: () => {
        return { className: 'vendor-header-cell' };
      },
    },
    {
      title: 'Status',
      dataIndex: 'active',
      key: 'active',
      width: 300,
      render: (text, record) => (
        <span>{record.active ? 'Active' : 'Inactive'}</span>
      ),
      onHeaderCell: () => {
        return { className: 'vendor-header-cell' };
      },
    },
  ];

  return (
    <div className="vendor-list">
      <div className="table-container">
        <Input
          placeholder="Search Vendor"
          onChange={onSearch}
          className="search-input"
        />
        <Button
          className="vendor-upload"
          size="large"
          onClick={handleUploadVendor}
        >
          Upload Vendor
        </Button>
        <Button className="add-button" size="large" onClick={handleAddVendor}>
          Add New Vendor
        </Button>
        <Table
          columns={vendorColumns}
          dataSource={filteredVendorList}
          filterSearch={true}
          loading={loadingVendors || !loadedVendors}
          rowKey="vendorName"
          rowClassName={(record) => {
            return record.active ? 'active-vendor-row' : 'inactive-vendor-row';
          }}
        />
      </div>
    </div>
  );
}

VendorListContainer.propTypes = {
  vendorList: PropTypes.array,
  onModeChange: PropTypes.func,
  onVendorSelect: PropTypes.string,
};
