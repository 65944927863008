import vendor from './modules/vendor';
import authentication from './modules/authentication';
import location from './modules/location';
import notification from './modules/notification';
import user from './modules/user';
import orders from './modules/orders';
import lov from './modules/lov';

export default {
  vendor,
  authentication,
  location,
  notification,
  user,
  orders,
  lov,
};
