import React, { useState, useEffect, useRef } from 'react';
import {
  retrieveOrderDetails,
  retrieveOrderList,
  retrieveAllFillsDataState,
} from 'store/modules/order';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Input } from '@progress/kendo-react-inputs';
import { process } from '@progress/kendo-data-query';
import EditFill from '../../FillOrders/EditFill';
import MyCell from '../../FillOrders/CustomCell';
import { ColumnMenu } from '../../../Common/columnMenu';
import '../../OrdersStyle.css';
import * as moment from 'moment';
import {
  ExcelExport,
  ExcelExportColumn as ExportColumn,
} from '@progress/kendo-react-excel-export';
import { Loader } from '@progress/kendo-react-indicators';
import { styles } from './styles';

export default function AllFillsList(props) {
  const dispatch = useDispatch();
  const {
    allFillsList,
    loadedAllFills,
    loadedEditFill,
    submittedEditFill,
    filtersAllFillsDataState,
    withAllFillsFilters,
  } = useSelector((state) => state.orders);

  const createDataState = (dataState) => {
    return {
      result: process(allFillsList.slice(0), dataState),
      sort: dataState.sort,
      dataState: dataState,
    };
  };

  const initialDataState = createDataState({
    sort: [
      {
        field: 'fillDate',
        dir: 'desc',
      },
    ],
    take: 15,
    skip: 0,
  });

  const [result, setResult] = useState();
  const [dataState, setDataState] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const d = new Date();
    d.setMonth(d.getMonth() - 6);
    const date = moment(d).format('MM/DD/YYYY');
    dispatch(retrieveOrderList(true, date, ''));
  }, []);

  useEffect(() => {
    setLoading(true);
    if (submittedEditFill) {
      const d = new Date();
      d.setMonth(d.getMonth() - 6);
      const date = moment(d).format('MM/DD/YYYY');
      dispatch(retrieveOrderList(true, date, ''));
    }
  }, [submittedEditFill]);

  useEffect(() => {
    if (loadedAllFills && !withAllFillsFilters) {
      setLoading(false);
      setResult(initialDataState.result);
      setDataState(initialDataState.dataState);
    } else if (loadedAllFills && withAllFillsFilters) {
      setLoading(false);
      const updatedState = createDataState(filtersAllFillsDataState);
      setResult(updatedState.result);
      setDataState(updatedState.dataState);
    }
  }, [allFillsList]);

  const dataStateChange = (e) => {
    const updatedState = createDataState(e.dataState);
    setResult(updatedState.result);
    setDataState(updatedState.dataState);
    dispatch(retrieveAllFillsDataState(e.dataState));
  };

  const showAllFills = () => {
    dispatch(retrieveOrderList(true, '', ''));
    setLoading(true);
  };

  const handleOrderSelection = (orderSelected) => {
    dispatch(retrieveOrderDetails(orderSelected));
    props.onModeChange('detail');
  };

  // const onSearch = (event) => {
  //   const {
  //     target: { value },
  //   } = event;

  //   const newOrderList = allFillsList.filter(
  //     (order) =>
  //       order.boardPeriod.toLowerCase().includes(value.toLowerCase()) ||
  //       order.commodity.toLowerCase().includes(value.toLowerCase()) ||
  //       order.contractCount
  //         .toString()
  //         .toLowerCase()
  //         .includes(value.toLowerCase()) ||
  //       order.contractType.toLowerCase().includes(value.toLowerCase()) ||
  //       order.submitDateOnly
  //         .toString()
  //         .toLowerCase()
  //         .includes(value.toLowerCase()) ||
  //       order.submitTimeOnly
  //         .toString()
  //         .toLowerCase()
  //         .includes(value.toLowerCase()) ||
  //       order.group.toLowerCase().includes(value.toLowerCase()) ||
  //       order.id.toString().toLowerCase().includes(value.toLowerCase()) ||
  //       order.locationID.toLowerCase().includes(value.toLowerCase()) ||
  //       order.orderType.toLowerCase().includes(value.toLowerCase()) ||
  //       order.quantity.toString().toLowerCase().includes(value.toLowerCase()) ||
  //       order.section.toLowerCase().includes(value.toLowerCase()) ||
  //       order.deliveryEndDateOnly
  //         .toString()
  //         .toLowerCase()
  //         .includes(value.toLowerCase()) ||
  //       order.deliveryEndTimeOnly
  //         .toString()
  //         .toLowerCase()
  //         .includes(value.toLowerCase()) ||
  //       order.uom.toLowerCase().includes(value.toLowerCase()) ||
  //       order.userFullName.toLowerCase().includes(value.toLowerCase()) ||
  //       order.userName.toLowerCase().includes(value.toLowerCase()) ||
  //       order.wOlimitPrice
  //         .toString()
  //         .toLowerCase()
  //         .includes(value.toLowerCase()) ||
  //       order.wOstopPrice.toString().toLowerCase().includes(value.toLowerCase())
  //   );
  //   setResult(newOrderList);
  // };

  const myCustomCell = (props) => {
    return <MyCell {...props} onRowClick={props.dataItem} editFill={true} />;
  };

  const _export = useRef(null);

  const handleExport = () => {
    setLoading(false);
  };

  const excelExport = () => {
    delete dataState.take;
    const exportList = process(allFillsList.slice(0), dataState);
    if (!_export.current) {
      return;
    }
    setLoading(true);
    _export.current.save(exportList);
  };

  return (
    <div id="adminwindow">
      <div className="market-fill-order">{loadedEditFill && <EditFill />}</div>
      <div className="all-open-orders-grid">
        <div className="toolbar-container">
          {/* <div className="search-box">
            <Input
              onChange={onSearch}
              label="Enter Text Here"
              className="search-input"
            />
          </div> */}
          <div className="export-btn">
            <button
              style={{ marginRight: '5px' }}
              className="export-open k-button k-buttorsn-md k-rounded-md k-button-solid k-button-solid-primary"
              onClick={showAllFills}
            >
              Show All Fills
            </button>
            <button
              title="Export Excel"
              className="export-open k-button k-buttorsn-md k-rounded-md k-button-solid k-button-solid-primary"
              onClick={excelExport}
            >
              Export All Fills
            </button>
          </div>
        </div>
        <ExcelExport
          ref={_export}
          fileName="Filled Orders.xlsx"
          onExportComplete={handleExport}
        >
          <ExportColumn field="id" title="Id" />
          <ExportColumn field="verified" title="Verified" />
          <ExportColumn field="filled" title="Filled" />
          <ExportColumn field="locked" title="Locked" />
          <ExportColumn field="contractType" title="Contract Type" />
          <ExportColumn field="section" title="Section" />
          <ExportColumn field="group" title="Order Group" />
          <ExportColumn field="locationID" title="Location ID" />
          <ExportColumn field="locationName" title="Location Name" />
          <ExportColumn field="altLocation" title="Alt Location" />
          <ExportColumn field="vendorID" title="Farmer ID" />
          <ExportColumn field="vendorName" title="Farmer Name" />
          <ExportColumn field="altVendor" title="Alt Farmer" />
          <ExportColumn field="itemType" title="Item Type" />
          <ExportColumn field="quantity" title="Quantity" />
          <ExportColumn field="uom" title="UOM" />
          <ExportColumn field="commodity" title="Commodity" />
          <ExportColumn field="commoditySymbol" title="Commodity Symbol" />
          <ExportColumn field="forecastType" title="Forecast Type" />
          <ExportColumn field="optionAction" title="Put/Call" />
          <ExportColumn field="optionType" title="Buy/Sell" />
          <ExportColumn field="strikePrice" title="Strike Price" />
          <ExportColumn field="contractCount" title="# of Contracts" />
          <ExportColumn field="optionMonth" title="Option Month" />
          <ExportColumn field="optionYear" title="Option Year" />
          <ExportColumn field="boardPeriod" title="Board Period" />
          <ExportColumn field="orderType" title="Order Type" />
          <ExportColumn field="wOlimitPrice" title="W.O. Limit Price" />
          <ExportColumn field="wOstopPrice" title="W.O. Stop Price" />
          <ExportColumn field="wOtype" title="W.O. Type" />
          <ExportColumn field="limitPrice" title="Limit Price" />
          <ExportColumn field="exchangePrice" title="Exchange Price" />
          <ExportColumn field="exchangeAccount" title="Exchange Account" />
          <ExportColumn field="exchangeType" title="Exchange Type" />
          <ExportColumn field="basisPrice" title="Basis Price" />
          <ExportColumn field="deliveryStart" title="Delivery Start" />
          <ExportColumn field="deliveryEnd" title="Delivery End" />
          <ExportColumn field="shipment" title="Shipment" />
          <ExportColumn field="foBprice" title="FOB Price" />
          <ExportColumn field="contractNumber" title="Contract Number" />
          <ExportColumn field="shipMode" title="Ship Mode" />
          <ExportColumn field="altContractType" title="Alt Contract Type" />
          <ExportColumn field="note" title="Note/s" />
          <ExportColumn field="submitDate" title="Submit Date" />
          <ExportColumn field="fillDate" title="Fill Date" />
          <ExportColumn field="fillPrice" title="fill Price" />
          <ExportColumn field="userFullName" title="Name" />
          <ExportColumn field="fillId" title="Fill ID" />
          <ExportColumn field="contractCount2" title="Fill Count" />
          <ExportColumn field="fillQty" title="Contracts Filled" />
        </ExcelExport>
        <Grid
          data={result}
          {...dataState}
          onDataStateChange={dataStateChange}
          pageable={true}
          resizable={true}
          onRowClick={handleOrderSelection}
          sortable={{
            allowUnsort: true,
            mode: 'multiple',
          }}
        >
          <Column title="Action" cell={myCustomCell} width="60px" />
          <Column
            field="section"
            title="Region"
            filter={'text'}
            columnMenu={ColumnMenu}
            width="95px"
          />
          <Column
            field="group"
            title="Order Group"
            filter={'text'}
            columnMenu={ColumnMenu}
          />
          <Column
            field="id"
            title="Order Trans #"
            filter={'text'}
            columnMenu={ColumnMenu}
          />
          <Column
            field="submitDateOnly"
            title="Sub. Date"
            filter={'text'}
            columnMenu={ColumnMenu}
          />
          {/* <Column
            field="submitTimeOnly"
            title="Sub. Time"
            filter={'text'}
            columnMenu={ColumnMenu}
          /> 
          <Column
            field="submitDate"
            title="Sub. Date"
            filter={'text'}
            columnMenu={ColumnMenu}
        /> */}
          <Column
            field="orderType"
            title="Trade Type"
            filter={'text'}
            columnMenu={ColumnMenu}
          />
          <Column
            field="userFullName"
            title="Trader"
            filter={'text'}
            columnMenu={ColumnMenu}
          />
          {/* <Column
            field="vendorID"
            title="Vendor ID"
            filter={'text'}
            columnMenu={ColumnMenu}
          /> */}
          <Column
            field="vendorName"
            title="Farmer/ Vendor"
            filter={'text'}
            columnMenu={ColumnMenu}
          />
          <Column
            field="contractType"
            title="Buy / Sell"
            filter={'text'}
            columnMenu={ColumnMenu}
          />
          <Column
            field="contractCount"
            title="# Contracts"
            filter={'text'}
            columnMenu={ColumnMenu}
          />
          <Column
            field="fillPrice"
            title="Fill Price"
            filter={'text'}
            columnMenu={ColumnMenu}
          />
          <Column
            field="boardPeriod"
            title="Board Period"
            filter={'text'}
            columnMenu={ColumnMenu}
          />
          <Column
            field="deliveryEndDateOnly"
            title="Deliv. Date"
            filter={'text'}
            columnMenu={ColumnMenu}
          />
          <Column
            field="quantity"
            title="Quantity"
            filter={'text'}
            columnMenu={ColumnMenu}
          />
          <Column
            field="locationID"
            title="Delivery Location"
            filter={'text'}
            columnMenu={ColumnMenu}
          />
          <Column
            field="commodity"
            title="Commodity"
            filter={'text'}
            columnMenu={ColumnMenu}
          />
          <Column
            field="uom"
            title="UOM"
            filter={'text'}
            columnMenu={ColumnMenu}
          />
        </Grid>
        <style>{styles}</style>
        {loading && (
          <Loader
            size={'large'}
            style={{
              position: 'absolute',
              top: '30%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
            }}
            type={'infinite-spinner'}
          />
        )}
      </div>
    </div>
  );
}
