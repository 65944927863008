import api from '../../core/index';

const orders = {
  getOrderList: (filled, date, splitType) => {
    const marketOrderList = `Order/GetList?filled=${filled}&submitSince=${date}&splitOrder=${splitType}`;
    return api.client.get(marketOrderList).then(
      (result) => {
        const { status, data, config } = result;
        if (status === 200) {
          return { results: data.payload, config: config.url };
        } else {
          return { error: 'Unknown Error' };
        }
      },
      (error) => {
        return { error };
      }
    );
  },
  getOrderDetails: (orderID) => {
    const orderDetails = `Order/GetOrder?orderID=${orderID}`;
    return api.client.get(orderDetails).then(
      (result) => {
        const { status, data } = result;
        if (status === 200) {
          return { results: data.payload };
        } else {
          return { error: 'Unknown Error ' };
        }
      },
      (error) => {
        return { error };
      }
    );
  },
  submitOrderFill: (fillData) => {
    const fillOrderUrl = 'Fill/Create';
    return api.client
      .post(fillOrderUrl, { ...fillData, deviceName: 'TestPC' })
      .then(
        (results) => {
          const { status, data } = results;
          if (status === 200 || data.status === 'OK') {
            return { results: data, status: 'Success' };
          } else {
            return { error: 'Unknown Error ' };
          }
        },
        (error) => {
          return { error };
        }
      );
  },
  submitFillEdit: (fillData) => {
    const editFillOrderUrl = 'Fill/Update';
    return api.client
      .put(editFillOrderUrl, { ...fillData, deviceName: 'TestPC' })
      .then(
        (results) => {
          const { status, data } = results;
          if (status === 200 || data.status === 'OK') {
            return { results: data, status: 'Success' };
          } else {
            return { error: 'Unknown Error ' };
          }
        },
        (error) => {
          return { error };
        }
      );
  },
  newOrderSubmit: (formData) => {
    const newOrderUrl = 'Order/Create';
    return api.client
      .post(newOrderUrl, { ...formData, deviceName: 'TestPC' })
      .then(
        (results) => {
          const { status, data } = results;
          if (status === 200 && data.status === 'OK') {
            return { results: data, status: 'Success' };
          } else {
            const { message } = results.data;
            return { error: message };
          }
        },
        (error) => {
          return { error };
        }
      );
  },
  UpdateOrderSubmit: (formData) => {
    const newOrderUrl = 'Order/Update';
    return api.client
      .put(newOrderUrl, { ...formData, deviceName: 'TestPC' })
      .then(
        (results) => {
          const { status, data } = results;
          if (status === 200 && data.status === 'OK') {
            return { results: data, status: 'Success' };
          } else {
            return { error: 'Unknown Error ' };
          }
        },
        (error) => {
          return { error };
        }
      );
  },
  cancelOrder: (formData) => {
    const cancelOrderURL = 'Order/Cancel';
    return api.client
      .put(cancelOrderURL, { ...formData, deviceName: 'myPC' })
      .then(
        (results) => {
          const { status, data } = results;
          if (status === 200 && data.status === 'OK') {
            return { results: data, status: 'Success' };
          } else {
            return { error: 'Unknown Error ' };
          }
        },
        (error) => {
          return { error };
        }
      );
  },
  getFillInfo: (orderId) => {
    const newFillData = `Fill/GetList?orderID=${orderId}`;
    return api.client.get(newFillData).then(
      (result) => {
        const { status, data } = result;
        if (status === 200) {
          return { results: data.payload };
        } else {
          return { error: 'Unknown Error ' };
        }
      },
      (error) => {
        return { error };
      }
    );
  },
};

export default orders;
