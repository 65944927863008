import { createSlice } from '@reduxjs/toolkit';
import api from 'services/api';

const initialState = {
  orderList: [],
  marketOrderList: [],
  loadedMarketOrders: false,
  loadingMarketOrders: false,
  workingOrderList: [],
  loadedWorkingOrders: false,
  loadingWorkingOrders: false,
  selectedOrder: null,
  loadingOrders: false,
  loadedOrders: false,
  loadOrdersError: '',
  loadingOrderDetails: false,
  loadedOrderDetails: false,
  loadOrderDetailsError: '',
  selectedFillOrder: null,
  selectedFillEdit: null,
  loadingOrderFill: false,
  loadedOrderFIll: false,
  loadingEditFill: false,
  loadedEditFill: false,
  loadingFillData: false,
  loadedFillData: false,
  fillDataInfo: null,
  fillDataFailed: '',
  submittingFill: false,
  submittedFill: false,
  submitFillError: '',
  submitFillMessage: '',
  submittingEditFill: false,
  submittedEditFill: false,
  submitEditFillMessage: '',
  submitEditFillError: '',
  submittingNewOrder: false,
  submittedNewOrder: false,
  newOrderFailed: false,
  newOrderApiError: false,
  submitNewOrderError: {},
  submitNewOrderMessage: '',
  allFillsList: [],
  loadedAllFills: false,
  loadingAllFills: false,
  cancellingOrder: false,
  cancelledOrder: false,
  cancelOrderError: '',
  cancelOrderMessage: '',
  submittingUpdateOrder: false,
  submittedUpdateOrder: false,
  updateOrderFailed: false,
  updateOrderApiError: false,
  submitUpdateOrderMessage: '',
  submitUpdateOrderError: '',
  submittingMarketFill: false,
  submittedMarketFill: false,
  submitFillMarketMessage: '',
  submitMarketFillError: '',
  submittingWorkingFill: false,
  submittedWorkingFill: false,
  submitFillWorkingMessage: '',
  submitWorkingFillError: '',
  selectedMarketOrder: null,
  loadingMarketOrderFill: false,
  loadedMarketOrderFill: false,
  selectedWorkingOrder: null,
  loadingWorkingOrderFill: false,
  loadedWorkingOrderFill: false,

  loadingMarketFilterStart: false,
  loadedMarketFilters: false,
  filtersMarketDataState: {},
  filterMarketDatatStateError: '',
  withMarketFilters: false,

  loadingWorkingFilterStart: false,
  loadedWorkingFilters: false,
  filtersWorkingDataState: {},
  filterWorkingDatatStateError: '',
  withWorkingFilters: false,

  loadingAllFillsFilterStart: false,
  loadedAllFillsFilters: false,
  filtersAllFillsDataState: {},
  filterAllFillsDatatStateError: '',
  withAllFillsFilters: false,

  loadingOpenOrdersFilterStart: false,
  loadedOpenOrdersFilters: false,
  filtersOpenOrdersDataState: {},
  filterOpenOrdersDatatStateError: '',
  withOpenOrdersFilters: false,
};

export const orders = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    //LOAD ORDERS TABLE START
    loadOrdersStart: (state) => {
      state.loadingOrders = true;
      state.loadedOrders = false;
      state.loadedAllFills = false;
      state.loadingAllFills = true;
    },
    loadOrdersComplete: (state, { payload }) => {
      const { orderList, filled, config } = payload;
      if (filled === true) {
        state.allFillsList = orderList;
        state.loadedAllFills = true;
        state.loadingAllFills = false;
      } else if (config.includes('splitOrder=0') === true) {
        state.marketOrderList = orderList;
        state.loadedMarketOrders = true;
        state.loadingMarketOrders = false;
      } else if (config.includes('splitOrder=1') === true) {
        state.workingOrderList = orderList;
        state.loadedWorkingOrders = true;
        state.loadingWorkingOrders = false;
      } else {
        state.loadedOrders = true;
        state.loadingOrders = false;
        state.orderList = orderList;
      }
    },
    loadOrdersFailed: (state, { payload }) => {
      const { error } = payload;
      state.loadingOrders = false;
      state.loadedOrders = false;
      state.loadedAllFills = false;
      state.loadingAllFills = false;
      state.loadOrdersError = error;
    },
    //LOAD ORDERS TABLE END
    //LOAD ORDER DETAILS TABLE START
    loadOrderDetailsStart: (state) => {
      state.loadingOrderDetails = true;
      state.loadedOrderDetails = false;
    },
    loadOrderDetailsComplete: (state, { payload }) => {
      const { orderDetails } = payload;
      state.selectedOrder = orderDetails;
      state.loadingOrderDetails = false;
      state.loadedOrderDetails = true;
    },
    loadOrderDetailsFailed: (state, { payload }) => {
      const { error } = payload;
      state.loadingOrderDetails = false;
      state.loadedOrderDetails = false;
      state.loadOrdersError = error;
    },
    //LOAD ORDER DETAILS TABLE END
    //CLEARING ORDERS STATE VALUES START
    clearSelectedOrder: (state) => {
      state.selectedOrder = null;
    },
    clearOrders: (state) => {
      state.orderderList = [];
      state.allFillsList - [];
      state.selectedOrder = null;
      state.loadingOrders = false;
      state.loadedOrders = false;
      state.loadOrdersError = '';
      state.loadingOrderDetails = false;
      state.loadedOrderDetails = false;
      state.loadOrderDetailsError = '';
    },
    //CLEARING ORDERS STATE VALUES START
    //GETTING FILL DATA START
    loadFillDataStart: (state) => {
      state.loadingFillData = true;
      state.loadedFillData = false;
    },
    loadFillDataCompleted: (state, { payload }) => {
      state.fillDataInfo = payload.data[0];
      state.loadingFillData = false;
      state.loadedFillData = true;
    },
    loadFillDataFailed: (state, { payload }) => {
      const { error } = payload;
      state.loadingFillData = false;
      state.loadedFillData = false;
      state.fillDataFailed = error;
    },
    //GETTING FILL DATA END
    //ASSIGN MARKET ORDER DATA SELECTED FOR FILLING START
    assignSelectedMarketOrder: (state, { payload }) => {
      const orderDetails = payload;
      state.selectedMarketOrder = orderDetails;
      state.loadingMarketOrderFill = false;
      state.loadedMarketOrderFill = true;
    },
    clearSelectedMarketOrder: (state) => {
      state.selectedMarketOrder = null;
      state.loadingMarketOrderFill = false;
      state.loadedMarketOrderFill = false;
      state.submittedMarketFill = false;
    },
    //ASSIGN MARKET ORDER DATA SELECTED FOR FILLING END
    //ASSIGN WORKING ORDER DATA SELECTED FOR FILLING START
    assignSelectedWorkingOrder: (state, { payload }) => {
      const orderDetails = payload;
      state.selectedWorkingOrder = orderDetails;
      state.loadingWorkingOrderFill = false;
      state.loadedWorkingOrderFill = true;
    },
    clearSelectedWorkingOrder: (state) => {
      state.selectedWorkingOrder = null;
      state.loadingWorkingOrderFill = false;
      state.loadedWorkingOrderFill = false;
      state.submittedWorkingFill = false;
    },
    //ASSIGN WORKING ORDER DATA SELECTED FOR FILLING END
    //ASSIGN EDIT ORDER FILL STATES START
    assignSelectedFillEdit: (state, { payload }) => {
      const fillDetails = payload;
      state.selectedFillEdit = fillDetails;
      state.loadingEditFill = false;
      state.loadedEditFill = true;
      state.loadedOrderFill = false;
    },
    clearSelectedFillEdit: (state) => {
      state.selectedFillEdit = null;
      state.loadingEditFill = false;
      state.loadedEditFill = false;
      state.submittedFill = false;
      state.submittedEditFill = false;
    },
    //ASSIGN EDIT ORDER FILL STATES END
    //SUBMIT FILL MARKET ORDER START
    submitFillMarketOrderStart: (state) => {
      state.submittingMarketFill = true;
      state.submittedMarketFill = false;
    },
    submitFillMarketOrderCompleted: (state, payload) => {
      const { data } = payload.payload;
      const { message } = data;
      state.submittingMarketFill = false;
      state.submittedMarketFill = true;
      state.submitFillMarketMessage = message;
    },
    submitFillMarketOrderFailed: (state, payload) => {
      const { error } = payload;
      state.submittingMarketFill = false;
      state.submittedMarketFill = false;
      state.submitMarketFillError = error;
    },
    //SUBMIT FILL MARKET ORDER END
    //SUBMIT FILL WORKING ORDER START
    submitFillWorkingOrderStart: (state) => {
      state.submittingWorkingFill = true;
      state.submittedWorkingFill = false;
    },
    submitFillWorkingOrderCompleted: (state, payload) => {
      const { data } = payload.payload;
      const { message } = data;
      state.submittingWorkingFill = false;
      state.submittedWorkingFill = true;
      state.submitFillWorkingMessage = message;
    },
    submitFillWorkingOrderFailed: (state, payload) => {
      const { error } = payload;
      state.submittingWorkingFill = false;
      state.submittedWorkingFill = false;
      state.submitWorkingFillError = error;
    },
    //SUBMIT FILL WORKING ORDER END
    //SUBMIT EDIT FILL START
    submitEditFillStart: (state) => {
      state.submittingEditFill = true;
      state.submittedEditFill = false;
    },
    submitEditFillCompleted: (state, { payload }) => {
      const { data } = payload;
      const { message } = data;
      state.submittingEditFill = false;
      state.submittedEditFill = true;
      state.submitEditFillMessage = message;
      state.loadedEditFill = false;
    },
    submitEditFillFailed: (state, payload) => {
      const { error } = payload;
      state.submittingEditFill = false;
      state.submittedEditFill = false;
      state.submitEditFillError = error;
    },
    //SUBMIT ORDER EDIT FILL END
    //NEW ORDER FUNCTIONS START
    submitNewOrderStart: (state) => {
      state.submittingNewOrder = true;
      state.submittedNewOrder = false;
    },
    submitNewOrderCompleted: (state, payload) => {
      const { data } = payload.payload;
      const { message } = data;
      state.submittingNewOrder = false;
      state.submittedNewOrder = true;
      state.newOrderFailed = false;
      state.submitNewOrderMessage = message;
    },
    submitNewOrderFailed: (state, payload) => {
      const { error } = payload.payload;
      const { response } = payload.payload.error;
      state.submittingNewOrder = false;
      state.submittedNewOrder = false;
      state.newOrderFailed = true;
      if (response !== undefined) {
        state.submitNewOrderError = response.data.errors;
      } else {
        state.submitNewOrderError = error;
        state.newOrderApiError = true;
      }
    },
    clearSubmitNewOrder: (state) => {
      state.submittingNewOrder = false;
      state.submittedNewOrder = false;
      state.submitNewOrderMessage = '';
      state.submitNewOrderError = '';
      state.newOrderFailed = false;
      state.newOrderApiError = false;
    },
    //UPDATE ORDER FUNCTIONS START
    submitUpdateOrderStart: (state) => {
      state.submittingUpdateOrder = true;
      state.submittedUpdateOrder = false;
    },
    submitUpdateOrderCompleted: (state, payload) => {
      const { data } = payload.payload;
      const { message } = data;
      state.submittingUpdateOrder = false;
      state.submittedUpdateOrder = true;
      state.updateOrderFailed = false;
      state.submitUpdateOrderMessage = message;
    },
    submitUpdateOrderFailed: (state, payload) => {
      const { error } = payload;
      const { response } = payload.payload.error;
      state.submittingUpdateOrder = false;
      state.submittedUpdateOrder = false;
      state.updateOrderFailed = true;
      if (response !== undefined) {
        state.submitUpdateOrderError = response.data.errors;
      } else {
        state.submitUpdateOrderError = error;
        state.updateOrderApiError = false;
      }
    },
    clearSubmitUpdateOrder: (state) => {
      state.submittingUpdateOrder = false;
      state.submittedUpdateOrder = false;
      state.updateOrderFailed = false;
      state.submitUpdateOrderMessage = '';
      state.submitUpdateOrderError = '';
      state.updateOrderApiError = false;
    },
    //CANCEL ORDER
    cancelOrderStart: (state) => {
      state.cancellingOrder = true;
      state.cancelledOrder = false;
    },
    cancelOrderCompleted: (state, payload) => {
      const { data } = payload.payload;
      const { message } = data;
      state.cancellingOrder = false;
      state.cancelledOrder = true;
      state.cancelOrderMessage = message;
    },
    cancelOrderFailed: (state, payload) => {
      const { error } = payload.payload;
      state.cancellingOrder = false;
      state.cancelledOrder = false;
      state.cancelOrderError = error;
    },
    clearCancelOrder: (state) => {
      state.cancelledOrder = false;
      state.cancellingOrder = false;
      state.cancelOrderError = '';
      state.cancelOrderMessage = '';
    },
    loadMarketFilterDataStateStart: (state) => {
      state.loadingMarketFilterStart = true;
      state.loadedMarketFilters = false;
      state.filtersMarketDataState = {};
      state.filterMarketDatatStateError = '';
    },
    loadMarketFilterDataStateCompleted: (state, payload) => {
      const dataState = payload.payload;
      if (dataState.filter !== undefined) {
        state.withMarketFilters = true;
      } else {
        state.withMarketFilters = false;
      }
      state.loadingMarketFilterStart = false;
      state.loadedMarketFilters = true;
      state.filtersMarketDataState = dataState;
      state.filterMarketDatatStateError = '';
    },
    loadMarketFilterDataStateFailed: (state) => {
      state.loadingMarketFilterStart = false;
      state.loadedMarketFilters = false;
      state.filtersMarketDataState = {};
      state.filterMarketDatatStateError = 'ERROR';
    },
    loadWorkingFilterDataStateStart: (state) => {
      state.loadingWorkingFilterStart = true;
      state.loadedWorkingFilters = false;
      state.filtersWorkingDataState = {};
      state.filterWorkingDatatStateError = '';
    },
    loadWorkingFilterDataStateCompleted: (state, payload) => {
      const dataState = payload.payload;
      if (dataState.filter !== undefined) {
        state.withWorkingFilters = true;
      } else {
        state.withWorkingFilters = false;
      }
      state.loadingWorkingFilterStart = false;
      state.loadedWorkingFilters = true;
      state.filtersWorkingDataState = dataState;
      state.filterWorkingDatatStateError = '';
    },
    loadWorkingFilterDataStateFailed: (state) => {
      state.loadingWorkingFilterStart = false;
      state.loadedWorkingFilters = false;
      state.filtersWorkingDataState = {};
      state.filterWorkingDatatStateError = 'ERROR';
    },
    loadAllFillsFilterDataStateStart: (state) => {
      state.loadingAllFillsFilterStart = true;
      state.loadedAllFillsFilters = false;
      state.filtersAllFillsDataState = {};
      state.filterAllFillsDatatStateError = '';
    },
    loadAllFillsFilterDataStateCompleted: (state, payload) => {
      const dataState = payload.payload;
      if (dataState.filter !== undefined) {
        state.withAllFillsFilters = true;
      } else {
        state.withAllFillsFilters = false;
      }
      state.loadingAllFillsFilterStart = false;
      state.loadedAllFillsFilters = true;
      state.filtersAllFillsDataState = dataState;
      state.filterAllFillsDatatStateError = '';
    },
    loadAllFillsFilterDataStateFailed: (state) => {
      state.loadingAllFillsFilterStart = false;
      state.loadedAllFillsFilters = false;
      state.filtersAllFillsDataState = {};
      state.filterAllFillsDatatStateError = 'ERROR';
    },
    loadOpenOrdersFilterDataStateStart: (state) => {
      state.loadingOpenOrdersFilterStart = true;
      state.loadedOpenOrdersFilters = false;
      state.filtersOpenOrdersDataState = {};
      state.filterOpenOrdersDatatStateError = '';
    },
    loadOpenOrdersFilterDataStateCompleted: (state, payload) => {
      const dataState = payload.payload;
      if (dataState.filter !== undefined) {
        state.withOpenOrdersFilters = true;
      } else {
        state.withOpenOrdersFilters = false;
      }
      state.loadingOpenOrdersFilterStart = false;
      state.loadedOpenOrdersFilters = true;
      state.filtersOpenOrdersDataState = dataState;
      state.filterOpenOrdersDatatStateError = '';
    },
    loadOpenOrdersFilterDataStateFailed: (state) => {
      state.loadingOpenOrdersFilterStart = false;
      state.loadedOpenOrdersFilters = false;
      state.filtersOpenOrdersDataState = {};
      state.filterOpenOrdersDatatStateError = 'ERROR';
    },
  },
});

//Action creators are generated for each case reducer function
export const {
  loadOrdersStart,
  loadOrdersComplete,
  loadOrdersFailed,
  loadOrderDetailsStart,
  loadOrderDetailsComplete,
  loadOrderDetailsFailed,
  clearSelectedOrder,
  clearOrders,
  loadSelectedFillOrderFailed,
  clearSelectedFillOrder,
  assignSelectedFillOrder,
  submitFillOrderStart,
  submitFillOrderCompleted,
  submitFillOrderFailed,
  submitEditFillStart,
  submitEditFillCompleted,
  submitEditFillFailed,
  submitNewOrderStart,
  submitNewOrderCompleted,
  submitNewOrderFailed,
  clearSubmitNewOrder,
  assignSelectedFillEdit,
  loadFillDataStart,
  loadFillDataCompleted,
  loadFillDataFailed,
  clearSelectedFillEdit,
  cancelOrderStart,
  cancelOrderCompleted,
  cancelOrderFailed,
  clearCancelOrder,
  submitUpdateOrderStart,
  submitUpdateOrderCompleted,
  submitUpdateOrderFailed,
  clearSubmitUpdateOrder,
  assignSelectedMarketOrder,
  assignSelectedWorkingOrder,
  clearSelectedMarketOrder,
  clearSelectedWorkingOrder,
  submitFillMarketOrderStart,
  submitFillMarketOrderCompleted,
  submitFillMarketOrderFailed,
  submitFillWorkingOrderStart,
  submitFillWorkingOrderCompleted,
  submitFillWorkingOrderFailed,

  loadMarketFilterDataStateStart,
  loadMarketFilterDataStateCompleted,
  loadMarketFilterDataStateFailed,
  withMarketFilters,

  loadWorkingFilterDataStateStart,
  loadWorkingFilterDataStateCompleted,
  loadWorkingFilterDataStateFailed,
  withWorkingFilters,

  loadAllFillsFilterDataStateStart,
  loadAllFillsFilterDataStateCompleted,
  loadAllFillsFilterDataStateFailed,
  withAllFillsFilters,

  loadOpenOrdersFilterDataStateStart,
  loadOpenOrdersFilterDataStateCompleted,
  loadOpenOrdersFilterDataStateFailed,
  withOpenOrdersFilters,
} = orders.actions;

export default orders.reducer;

//Async Functions
export const retrieveOrderList =
  (filled, date, splitType) => async (dispatch) => {
    dispatch(loadOrdersStart());
    api.orders.getOrderList(filled, date, splitType).then((res) => {
      const { results, error, config } = res;
      if (results) {
        dispatch(
          loadOrdersComplete({
            orderList: results,
            filled: filled,
            config: config,
          })
        );
      } else {
        dispatch(loadOrdersFailed({ error: error || 'API_ERROR' }));
      }
    });
  };

export const retrieveOrderDetails = (selectedOrder) => (dispatch) => {
  dispatch(loadOrderDetailsStart());
  const { id } = selectedOrder.dataItem;
  api.orders.getOrderDetails(id).then((res) => {
    const { results, error } = res;
    if (results) {
      dispatch(loadOrderDetailsComplete({ orderDetails: results[0] }));
    } else {
      dispatch(loadOrderDetailsFailed({ error: error || 'API_ERROR' }));
    }
  });
};

export const submitNewOrder = (formData) => (dispatch) => {
  dispatch(submitNewOrderStart());
  api.orders.newOrderSubmit(formData).then((res) => {
    const { results, error } = res;
    if (results) {
      dispatch(submitNewOrderCompleted({ data: results }));
    } else {
      dispatch(submitNewOrderFailed({ error: error || 'API_ERROR' }));
    }
  });
};

export const submitUpdateOrder = (formData) => (dispatch) => {
  dispatch(submitUpdateOrderStart());
  api.orders.UpdateOrderSubmit(formData).then((res) => {
    const { results, error } = res;
    if (results) {
      dispatch(submitUpdateOrderCompleted({ data: results }));
    } else {
      dispatch(submitUpdateOrderFailed({ error: error || 'API_ERROR' }));
    }
  });
};

export const cancelOrder = (formData) => (dispatch) => {
  dispatch(cancelOrderStart());
  api.orders.cancelOrder(formData).then((res) => {
    const { results, error } = res;
    if (results) {
      dispatch(cancelOrderCompleted({ data: results }));
    } else {
      dispatch(cancelOrderFailed({ error: error || 'API_ERROR' }));
    }
  });
};

export const submitFillMarketOrder = (fillData) => (dispatch) => {
  dispatch(submitFillMarketOrderStart());
  api.orders.submitOrderFill(fillData).then((res) => {
    const { results, error } = res;
    if (results) {
      dispatch(submitFillMarketOrderCompleted({ data: results }));
    } else {
      dispatch(submitFillMarketOrderFailed({ error: error || 'API_ERROR' }));
    }
  });
};

export const submitFillWorkingOrder = (fillData) => (dispatch) => {
  dispatch(submitFillWorkingOrderStart());
  api.orders.submitOrderFill(fillData).then((res) => {
    const { results, error } = res;
    if (results) {
      dispatch(submitFillWorkingOrderCompleted({ data: results }));
    } else {
      dispatch(submitFillWorkingOrderFailed({ error: error || 'API_ERROR' }));
    }
  });
};

export const submitEditFill = (fillData) => (dispatch) => {
  dispatch(submitEditFillStart());
  api.orders.submitFillEdit(fillData).then((res) => {
    const { results, error } = res;
    if (results) {
      dispatch(submitEditFillCompleted({ data: results }));
    } else {
      dispatch(submitEditFillFailed({ error: error || 'API_ERROR' }));
    }
  });
};

export const getFillData = (orderId) => (dispatch) => {
  dispatch(loadFillDataStart());
  api.orders.getFillInfo(orderId).then((res) => {
    const { results, error } = res;
    if (results) {
      dispatch(loadFillDataCompleted({ data: results }));
    } else {
      dispatch(loadFillDataFailed({ error: error || 'APIERROR' }));
    }
  });
};

export const retrieveMarketDataState = (dataState) => async (dispatch) => {
  dispatch(loadMarketFilterDataStateStart());
  if (dataState != undefined) {
    dispatch(loadMarketFilterDataStateCompleted(dataState));
  } else {
    dispatch(loadMarketFilterDataStateFailed());
  }
};

export const retrieveWorkingDataState = (dataState) => async (dispatch) => {
  dispatch(loadWorkingFilterDataStateStart());
  if (dataState != undefined) {
    dispatch(loadWorkingFilterDataStateCompleted(dataState));
  } else {
    dispatch(loadWorkingFilterDataStateFailed());
  }
};

export const retrieveAllFillsDataState = (dataState) => async (dispatch) => {
  dispatch(loadAllFillsFilterDataStateStart());
  if (dataState != undefined) {
    dispatch(loadAllFillsFilterDataStateCompleted(dataState));
  } else {
    dispatch(loadAllFillsFilterDataStateFailed());
  }
};

export const retrieveOpenOrdersDataState = (dataState) => async (dispatch) => {
  dispatch(loadOpenOrdersFilterDataStateStart());
  if (dataState != undefined) {
    dispatch(loadOpenOrdersFilterDataStateCompleted(dataState));
  } else {
    dispatch(loadOpenOrdersFilterDataStateFailed());
  }
};
