import api from '../../core/index';

const authentication = {
  loginCreds: (credentialDetails) => {
    const createUrl = 'Authenticate/login';
    return api.client
      .post(createUrl, { ...credentialDetails, deviceName: 'TestPC' })
      .then(
        (result) => {
          return result;
        },

        (error) => {
          return error;
        }
      );
  },
};

export default authentication;
