import React from 'react';
import './FormPartsStyle.css';
import { FormDropDown } from './FormDropDown';

export class FormInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: (props.locked && props.active) || false,
      value: props.value || '',
      error: props.error || '',
      label: props.label || '',
      inputType: '',
    };
  }

  changeValue(event) {
    const currentVal = event.target.value;
    this.setState({ value: currentVal, error: '' });
    this.props.onInputChange(currentVal, this.props.position);
  }

  handleKeyPress(event) {
    if (event.which === 13) {
      this.setState({ value: this.props.predicted });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.label !== prevState.label) {
      if (this.state.label === 'Password') {
        this.setState({ inputType: 'password' });
      } else {
        this.setState({ inputType: 'text' });
      }
    }
  }

  render() {
    const { active, value, error, label } = this.state;
    const { predicted, locked } = this.props;
    const fieldClassName = `field ${
      (locked ? active : active || value) && 'active'
    } ${locked && !active && 'locked'}`;

    return (
      <div className={fieldClassName}>
        {active && value && predicted && predicted.includes(value) && (
          <p className="predicted">{predicted}</p>
        )}
        <input
          id={1}
          type={this.state.inputType}
          value={this.state.value}
          onChange={this.changeValue.bind(this)}
          onKeyPress={this.handleKeyPress.bind(this)}
          onFocus={() => !locked && this.setState({ active: true })}
          onBlur={() => !locked && this.setState({ active: false })}
        />
        <label htmlFor={1} className={error && 'error'}>
          {error || label}
        </label>
      </div>
    );
  }
}
