import React, { useState, useEffect } from 'react';
import NewRoleContainer from 'components/Admin/User/Container/NewRoleContainer';
import PropTypes from 'prop-types';
import { Table, Button, Space, Modal, Input, message } from 'antd';
import '../UserWindowStyle.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  retrieveUserDetails,
  retrieveUserList,
  updateUserStatus,
  resetUserPassword,
  clearPasswordReset,
  clearSelectedUser,
} from 'store/modules/users';

const { confirm } = Modal;
export default function UserListContainer(props) {
  const dispatch = useDispatch();
  const {
    userList,
    selectedUser,
    loadingUsers,
    loadedUsers,
    updatingUser,
    passwordResetted,
    passwordResetMessage,
    passwordResetValue,
    apiResponse,
    updatedUserMembership,
  } = useSelector((state) => state.users);
  const [showRoleSelector, setShowRoleSelector] = useState(false);
  const [filteredUserList, setFilteredUserList] = useState(userList);

  useEffect(() => {
    if (
      apiResponse.message === 'Role Added/Removed Successfully' &&
      updatedUserMembership === true
    ) {
      handleCloseModal();
    }
  }, [apiResponse, updatedUserMembership]);

  useEffect(() => {
    dispatch(retrieveUserList());
  }, []);

  useEffect(() => {
    if (loadedUsers) {
      setFilteredUserList(userList);
    }
  }, [userList]);

  useEffect(() => {
    if (passwordResetted) {
      successMessage();
    }
  }, [userList]);

  const handleUserSelection = (userSelected) => {
    dispatch(retrieveUserDetails(userSelected));
    props.onModeChange('detail');
  };

  const handleUserActivation = (userSelected) => {
    dispatch(
      updateUserStatus({ ...userSelected, active: !userSelected.active })
    );
  };

  const handleUserPasswordReset = (userSelected) => {
    dispatch(resetUserPassword({ ...userSelected }));
  };

  const successMessage = () => {
    if (
      passwordResetted &&
      passwordResetMessage === 'User Password Reset Successfully'
    ) {
      message.success(
        passwordResetMessage + '. The new password is ' + passwordResetValue,
        5
      );
    } else {
      message.success(passwordResetMessage + '. Kindly try to reset again.', 5);
    }
    dispatch(clearPasswordReset());
  };

  const addNewUser = () => {
    props.onModeChange('form');
  };

  const handleShowRoleSelector = (userSelected) => {
    dispatch(retrieveUserDetails(userSelected));
    setShowRoleSelector(true);
  };

  const handleCloseModal = () => {
    setShowRoleSelector(false);
    dispatch(clearSelectedUser());
  };

  const onSearch = (event) => {
    const {
      target: { value },
    } = event;

    const newUserList = userList.filter(
      (user) =>
        user.userName.toLowerCase().includes(value.toLowerCase()) ||
        user.fullName.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredUserList(newUserList);
  };

  const confirmActivation = (userInfo) => {
    const { active } = userInfo;
    if (active) {
      confirm({
        title: `Are you sure you want to deactivate ${userInfo.fullName}?`,
        content: 'Deactivating user will ....',
        okType: 'danger',
        okText: 'Yes',
        cancelText: 'No',
        onOk: () => {
          handleUserActivation(userInfo);
        },
      });
    } else {
      confirm({
        title: `Are you sure you want to activate ${userInfo.fullName}?`,
        content: 'Activating user will ....',
        okText: 'Yes',
        cancelText: 'No',
        onOk: () => {
          handleUserActivation(userInfo);
        },
      });
    }
  };

  const confirmResetPassword = (userInfo) => {
    confirm({
      title: `Are you sure you want to reset ${userInfo.fullName} password?`,
      content: 'Resetting user password will ....',
      okType: 'danger',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        handleUserPasswordReset(userInfo);
      },
    });
  };
  const userColumns = [
    {
      title: 'Name',
      dataIndex: 'fullName',
      key: 'fullName',
      render: (text, record) => (
        <Button type="link" onClick={() => handleUserSelection(record)}>
          {text}
        </Button>
      ),
    },
    {
      title: 'Username',
      dataIndex: 'userName',
      key: 'userName',
      width: 500,
    },
    {
      title: 'Action',
      key: 'action',
      width: 300,
      fixed: 'right',
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="primary"
            className={
              record.active ? 'deactivate-user-btn' : 'activate-user-btn'
            }
            onClick={() => confirmActivation(record)}
          >
            {record.active ? 'Deactivate' : 'Activate'}
          </Button>
          <Button type="dashed" onClick={() => handleShowRoleSelector(record)}>
            Update Roles
          </Button>
          <Button
            type="primary"
            className="reset-password"
            onClick={() => confirmResetPassword(record)}
          >
            Reset Password
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div className="user-list">
      <div>
        <Space className="search-add-container">
          <Input
            placeholder="Search User"
            onChange={onSearch}
            className="search-input"
          />
          <Button
            className="add-button"
            style={{ float: 'right', backgroundColor: '#5fb471' }}
            size="large"
            onClick={addNewUser}
          >
            Add New User
          </Button>
        </Space>
        <Table
          columns={userColumns}
          dataSource={filteredUserList}
          filterSearch={true}
          loading={loadingUsers || !loadedUsers || updatingUser}
          rowKey="userName"
        />
        <Modal
          open={showRoleSelector}
          footer={null}
          onCancel={handleCloseModal}
          title={`Update User Role for ${
            selectedUser && selectedUser.fullName
          }`}
        >
          <NewRoleContainer userRoles={selectedUser && selectedUser.roles} />
        </Modal>
      </div>
    </div>
  );
}

UserListContainer.propTypes = {
  onUserSelect: PropTypes.func,
  onModeChange: PropTypes.func,
  selectedUser: PropTypes.object,
  userList: PropTypes.array,
};
