import { createSlice } from '@reduxjs/toolkit';
import api from 'services/api';
import location from 'services/api/modules/location';

const initialState = {
  locationList: [],
  selectedLocation: null,
  loadingLocations: false,
  loadedLocations: false,
  updatingLocation: false,
  updatedLocation: false,
  creatingLocation: false,
  createdLocation: false,
  createLocationError: '',
  loadLocationsError: '',
  loadLocationsDetailError: '',
  updateLocationError: '',
  createLocationMessage: '',
  updateLocationMessage: '',
};

export const locations = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    loadLocationsStart: (state) => {
      state.loadingLocations = true;
      state.loadedLocations = false;
    },
    loadLocationComplete: (state, { payload }) => {
      const { locationList } = payload;
      state.locationList = locationList;
      state.loadedLocations = true;
      state.loadingLocations = false;
    },
    loadLocationsFailed: (state, { payload }) => {
      const { error } = payload;
      state.loadingLocations = false;
      state.loadedLocations = false;
      state.loadLocationsError = error;
    },
    updateLocationStart: (state) => {
      state.updatingLocation = true;
      state.updatedLocation = false;
    },
    updateLocationComplete: (state, { payload }) => {
      const { locationList, updateLocationMessage } = payload;
      state.locationList = locationList;
      state.updatingLocation = false;
      state.updatedLocation = true;
      state.updateLocationMessage = updateLocationMessage;
    },
    updateLocatonFailed: (state, { payload }) => {
      const { error } = payload;
      state.updatingLocation = false;
      state.updatedLocation = false;
      state.updateLocationError = error;
    },
    createLocationStart: (state) => {
      state.createLocationStart = true;
      state.createdLocation = false;
    },
    createLocationComplete: (state, { payload }) => {
      const { locationList, createLocationMessage } = payload;
      state.locationList = locationList;
      state.createdLocation = true;
      state.creatingLocation = false;
      state.createLocationMessage = createLocationMessage;
    },
    createLocationFailed: (state, { payload }) => {
      const { error } = payload;
      state.creatingLocation = false;
      state.createdLocation = false;
      state.createLocationError = error;
    },
    loadLocationDetailComplete: (state, { payload }) => {
      const { locationDetails } = payload;
      state.selectedLocation = locationDetails;
      state.loadingLocations = false;
      state.loadedLocations = true;
    },
    clearSelectedLocation: (state) => {
      state.selectedLocation = null;
    },
    clearLocations: (state) => {
      state.locationList = [];
      state.selectedLocation = null;
      state.loadingLocations = false;
      state.loadedLocations = false;
      state.loadLocationsError = '';
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  loadLocationsStart,
  loadLocationComplete,
  loadLocationsFailed,
  updateLocationStart,
  updateLocationComplete,
  updateLocatonFailed,
  loadLocationDetailComplete,
  clearSelectedLocation,
  clearLocations,
  createLocationStart,
  createLocationComplete,
  createLocationFailed,
} = locations.actions;

export default locations.reducer;

//Async Functions
export const retrieveLocationList = () => async (dispatch) => {
  dispatch(loadLocationsStart);
  api.location.getLocations().then((res) => {
    const { results, error } = res;
    if (results) {
      dispatch(loadLocationComplete({ locationList: results }));
    } else {
      dispatch(loadLocationsFailed({ error: error || 'API_ERROR' }));
    }
  });
};

export const retrieveLocationDetails =
  (locationSelected) => async (dispatch) => {
    dispatch(loadLocationsStart);
    const { locationID, locationName } = locationSelected;
    api.location
      .getLocationDetail(
        locationID,
        locationName === undefined ? '' : locationName
      )
      .then((res) => {
        const { results, error } = res;
        if (results) {
          dispatch(loadLocationDetailComplete({ locationDetails: results }));
        } else {
          dispatch(loadLocationsFailed({ error: error || 'API_ERROR' }));
        }
      });
  };

export const updateLocation =
  (locationDetails) => async (dispatch, getState) => {
    dispatch(updateLocationStart);
    const {
      locations: { locationList },
    } = getState();
    api.location.updateLocation(locationDetails).then((res) => {
      const { results, error } = res;
      if (results) {
        const currentLocations = locationList.map((location) => {
          if (location.locationName === locationDetails.locationName) {
            return locationDetails;
          }
          return location;
        });
        dispatch(
          updateLocationComplete({
            locationList: currentLocations,
            updateLocationMessage: results.message,
          })
        );
      } else {
        dispatch(updateLocatonFailed({ error: error || 'API_ERROR' }));
      }
    });
  };

export const createLocation =
  (locationDetails) => async (dispatch, getState) => {
    dispatch(createLocationStart);
    const {
      locations: { locationList },
    } = getState();
    api.location.createLocation(locationDetails).then((res) => {
      const { results, error } = res;
      if (results) {
        const currentLocations = locationList.map((location) => {
          if (location.locationName === locationDetails.locationname) {
            return locationDetails;
          }
          return location;
        });
        dispatch(
          createLocationComplete({
            locationList: currentLocations,
            createLocationMessage: results.message,
          })
        );
      } else {
        dispatch(createLocationFailed({ error: error || 'API_ERROR' }));
      }
    });
  };
