import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  retrieveOrderDetails,
  retrieveOrderList,
  clearCancelOrder,
  retrieveOpenOrdersDataState,
} from 'store/modules/order';
import { ColumnMenu, DateCell } from '../../../Common/columnMenu';
import LoadingIcons from 'react-loading-icons';
import { process } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Input } from '@progress/kendo-react-inputs';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Loader } from '@progress/kendo-react-indicators';
import CancelCell from './CancelOrder';
import { Popup } from '@progress/kendo-react-popup';

export default function OpenOrdersList(props) {
  const dispatch = useDispatch();

  const {
    orderList,
    loadedOrders,
    cancelledOrder,
    cancelOrderError,
    cancelOrderMessage,
    filtersOpenOrdersDataState,
    withOpenOrdersFilters,
  } = useSelector((state) => state.orders);

  const createDataState = (dataState) => {
    return {
      result: process(orderList.slice(0), dataState),
      sort: dataState.sort,
      dataState: dataState,
    };
  };

  const initialDataState = createDataState({
    sort: [
      {
        field: 'submitDate',
        dir: 'desc',
      },
    ],
    take: 15,
    skip: 0,
  });

  const offset = {
    // left: '50%',
    // top: '30%',
  };

  const [result, setResult] = useState(initialDataState.result);
  const [dataState, setDataState] = useState(initialDataState.dataState);
  const [date, setDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);

  useEffect(() => {
    dispatch(retrieveOrderList(false, date, ''));
  }, []);

  useEffect(() => {
    if (cancelledOrder === true) {
      setShowPopUp(true);
    }
  }, [cancelOrderMessage, cancelOrderError]);

  useEffect(() => {
    if (loadedOrders && !withOpenOrdersFilters) {
      setLoading(false);
      setResult(initialDataState.result);
      setDataState(initialDataState.dataState);
    } else if (loadedOrders && withOpenOrdersFilters) {
      const updatedState = createDataState(filtersOpenOrdersDataState);
      setResult(updatedState.result);
      setDataState(updatedState.dataState);
    }
  }, [orderList]);

  const refreshOrderList = () => {
    setShowPopUp(false);
    dispatch(retrieveOrderList(false, date, ''));
    dispatch(clearCancelOrder());
  };

  const dataStateChange = (e) => {
    const updatedState = createDataState(e.dataState);
    setResult(updatedState.result);
    setDataState(updatedState.dataState);
    dispatch(retrieveOpenOrdersDataState(e.dataState));
  };

  const handleOrderSelection = (orderSelected) => {
    dispatch(retrieveOrderDetails(orderSelected));
    props.onModeChange('detail');
  };

  const onSearch = (event) => {
    const {
      target: { value },
    } = event;

    const newOrderList = orderList.filter(
      (order) =>
        order.boardPeriod.toLowerCase().includes(value.toLowerCase()) ||
        order.commodity.toLowerCase().includes(value.toLowerCase()) ||
        order.contractCount
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()) ||
        order.contractType.toLowerCase().includes(value.toLowerCase()) ||
        order.submitDateOnly
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()) ||
        order.submitTimeOnly
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()) ||
        order.group.toLowerCase().includes(value.toLowerCase()) ||
        order.id.toString().toLowerCase().includes(value.toLowerCase()) ||
        order.locationID.toLowerCase().includes(value.toLowerCase()) ||
        order.orderType.toLowerCase().includes(value.toLowerCase()) ||
        order.quantity.toString().toLowerCase().includes(value.toLowerCase()) ||
        order.section.toLowerCase().includes(value.toLowerCase()) ||
        order.deliveryEndDateOnly
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()) ||
        order.deliveryEndTimeOnly
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()) ||
        order.uom.toLowerCase().includes(value.toLowerCase()) ||
        order.userFullName.toLowerCase().includes(value.toLowerCase()) ||
        order.userName.toLowerCase().includes(value.toLowerCase()) ||
        order.wOlimitPrice
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()) ||
        order.wOstopPrice.toString().toLowerCase().includes(value.toLowerCase())
    );
    setResult(newOrderList);
  };

  const _export = useRef(null);

  const handleExport = () => {
    setLoading(false);
  };

  const excelExport = () => {
    delete dataState.take;
    const exportList = process(orderList.slice(0), dataState);
    if (!_export.current) {
      return;
    }
    setLoading(true);
    _export.current.save(exportList);
  };

  const customCell = (props) => {
    return <CancelCell {...props} onRowClick={props.dataItem} />;
  };

  return !loadedOrders ? (
    <LoadingIcons.Oval stroke="#006730" />
  ) : (
    <div id="adminwindow">
      <div className="all-open-orders-grid">
        <div className="toolbar-container">
          <div className="search-box">
            <Input
              onChange={onSearch}
              label="Enter Text Here"
              className="search-input"
            />
          </div>
          <div className="export-btn">
            <button
              title="Export Excel"
              className="export-open k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
              onClick={excelExport}
            >
              Export Open Orders
            </button>
          </div>
        </div>
        <Popup
          show={showPopUp}
          popupClass={'popup-content'}
          offset={offset}
          style={{
            position: 'absolute',
            top: '30%',
            left: '50%',
          }}
        >
          <div className="cancel-success">
            <div className="cancel-message">
              {cancelOrderMessage === 'Entity Updated Successfully'
                ? 'Order Cancelled Successfully'
                : ''}
            </div>
            <div className="cancel-button">
              <button className="ok-button" onClick={refreshOrderList}>
                OK
              </button>
            </div>
          </div>
        </Popup>
        <ExcelExport
          ref={_export}
          fileName="Open Orders.xlsx"
          onExportComplete={handleExport}
        >
          <Grid
            data={result}
            {...dataState}
            onDataStateChange={dataStateChange}
            pageable={true}
            resizable={true}
            onRowClick={handleOrderSelection}
            sortable={{
              allowUnsort: true,
              mode: 'multiple',
            }}
          >
            <Column title="Action" cell={customCell} />
            <Column
              field="submitDate"
              title="Sub. Date"
              filter={'date'}
              cell={DateCell}
              columnMenu={ColumnMenu}
            />
            <Column
              field="userFullName"
              title="Trader"
              filter={'text'}
              columnMenu={ColumnMenu}
            />
            <Column
              field="vendorID"
              title="Vendor ID"
              filter={'text'}
              columnMenu={ColumnMenu}
            />
            <Column
              field="vendorName"
              title="Farmer/Vendor"
              filter={'text'}
              columnMenu={ColumnMenu}
            />
            <Column
              field="contractType"
              title="Buy/Sell"
              filter={'text'}
              columnMenu={ColumnMenu}
            />
            <Column
              field="contractCount"
              title="# Contracts"
              filter={'text'}
              columnMenu={ColumnMenu}
            />
            <Column
              field="boardPeriod"
              title="Board Period"
              filter={'text'}
              columnMenu={ColumnMenu}
            />
            <Column
              field="wOstopPrice"
              title="Stop Price"
              filter={'text'}
              columnMenu={ColumnMenu}
            />
            <Column
              field="wOlimitPrice"
              title="Limit Price"
              filter={'text'}
              columnMenu={ColumnMenu}
            />
            <Column
              field="orderType"
              title="Trade Type"
              filter={'text'}
              columnMenu={ColumnMenu}
            />
            <Column
              field="id"
              title="Order Trans #"
              filter={'text'}
              columnMenu={ColumnMenu}
            />
            <Column
              field="locationID"
              title="Delivery Location"
              filter={'text'}
              columnMenu={ColumnMenu}
            />
          </Grid>
        </ExcelExport>
        {loading && (
          <Loader
            size={'large'}
            style={{
              position: 'absolute',
              top: '30%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
            }}
            type={'infinite-spinner'}
          />
        )}
      </div>
    </div>
  );
}
