import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  assignSelectedMarketOrder,
  assignSelectedWorkingOrder,
  assignSelectedFillEdit,
  getFillData,
} from 'store/modules/order';

export default function MyCell(props) {
  const dispatch = useDispatch();
  const [fillType, setFillType] = useState('Fill Order');
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (props.editFill === true) {
      setFillType('Edit Fill');
      setIsEdit(true);
    }
  }, []);

  const fillOrder = () => {
    dispatch(getFillData(props.dataItem.id));
    if (isEdit === false) {
      if (props.orderType === 'Market Order') {
        dispatch(assignSelectedMarketOrder(props.dataItem));
      } else {
        dispatch(assignSelectedWorkingOrder(props.dataItem));
      }
    } else {
      dispatch(assignSelectedFillEdit(props.dataItem));
    }
  };

  return (
    <td onClick={fillOrder}>
      <div style={{ cursor: 'pointer', color: 'blue', fontWeight: '550' }}>
        {fillType}
      </div>
    </td>
  );
}
