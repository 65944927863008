import { createSlice } from '@reduxjs/toolkit';
import api from 'services/api';
import user from 'services/api/modules/user';

const initialState = {
  apiResponse: [],
  userList: [],
  selectedUser: null,
  loadingUsers: false,
  loadedUsers: false,
  updatingUserStatus: false,
  updatedUserStatus: false,
  loadUsersError: '',
  loadUserDetailError: '',
  updateUserStatusError: '',
  passwordResetted: false,
  passwordResetting: false,
  passwordResetError: '',
  passwordResetMessage: '',
  passwordResetValue: '',
  creatingUser: false,
  createdUser: false,
  createUserError: '',
  updatingUser: false,
  updatedUser: false,
  updatingUserError: '',
  updatingUserMembership: false,
  updatedUserMembership: false,
  updateUserMembershipError: '',
};

export const users = createSlice({
  name: 'users',
  initialState,
  reducers: {
    loadUsersStart: (state) => {
      state.loadingUsers = true;
      state.loadedUsers = false;
    },
    loadUsersComplete: (state, { payload }) => {
      const { userList } = payload;
      state.userList = userList;
      state.loadedUsers = true;
      state.loadingUsers = false;
    },
    loadUsersFailed: (state, { payload }) => {
      const { error } = payload;
      state.loadingUsers = false;
      state.loadedUsers = false;
      state.loadUsersError = error;
    },
    updateUsersStatusStart: (state) => {
      state.updatingUserStatus = true;
      state.updatedUserStatus = false;
    },
    updateUsersStatusComplete: (state, { payload }) => {
      const { userList } = payload;
      state.userList = userList;
      state.updatedUserStatus = true;
      state.updatingUserStatus = false;
    },
    updateUsersStatusFailed: (state, { payload }) => {
      const { error } = payload;
      state.updatedUserStatus = false;
      state.updatingUserStatus = false;
      state.updateUserStatusError = error;
    },
    resetUserPwdStart: (state) => {
      state.passwordResetting = true;
      state.passwordResetted = false;
    },
    resetUserPwdComplete: (state, { payload }) => {
      const { userList, passwordResetMessage, passwordResetValue } = payload;
      state.userList = userList;
      state.passwordResetted = true;
      state.passwordResetting = false;
      state.passwordResetMessage = passwordResetMessage;
      state.passwordResetValue = passwordResetValue;
    },
    resetUserPwdFailed: (state, { payload }) => {
      const { error } = payload;
      state.passwordResetted = false;
      state.passwordResetting = false;
      state.passwordResetError = error;
    },
    clearPasswordReset: (state) => {
      state.passwordResetted = false;
      state.passwordResetting = false;
      state.passwordResetMessage = '';
      state.passwordResetValue = '';
    },
    changeUserPwdStart: (state) => {
      state.passwordChange = true;
      state.passwordChanged = false;
    },
    changeUserPwdComplete: (state, { payload }) => {
      const { userList } = payload;
      state.userList = userList;
      state.passwordChanged = true;
      state.passwordChange = false;
    },
    changeUserPwdFailed: (state, { payload }) => {
      const { error } = payload;
      state.passwordChanged = false;
      state.passwordChange = false;
      state.passwordChangeError = error;
    },
    loadUserDetailComplete: (state, { payload }) => {
      const { userDetails } = payload;
      state.selectedUser = userDetails;
      state.loadingUsers = false;
      state.loadedUsers = true;
    },
    clearSelectedUser: (state) => {
      state.selectedUser = null;
    },
    clearUsers: (state) => {
      state.userList = [];
      state.selectedUser = null;
      state.loadingUsers = false;
      state.loadedUsers = false;
      state.loadUsersError = '';
    },
    createUserStart: (state) => {
      state.creatingUser = true;
      state.createdUser = false;
    },
    createUserComplete: (state, { payload }) => {
      const { result } = payload;
      state.apiResponse = result;
      state.creatingUser = false;
      state.createdUser = true;
    },
    createUserFailed: (state, { payload }) => {
      const { error } = payload;
      state.createUserError = error;
      state.creatingUser = false;
      state.createdUser = false;
    },
    userMembershipStart: (state) => {
      state.updatingUserMembership = true;
      state.updatedUserMembership = false;
    },
    userMembershipComplete: (state, { payload }) => {
      const { data } = payload;
      state.apiResponse = data;
      state.updatingUserMembership = false;
      state.updatedUserMembership = true;
    },
    userMembershipFailed: (state, { payload }) => {
      const { error } = payload;
      state.updateUserMembershipError = error;
      state.updatingUserMembership = false;
      state.updatedUserMembership = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  loadUsersStart,
  loadUsersComplete,
  loadUsersFailed,
  loadUserDetailComplete,
  clearUsers,
  clearSelectedUser,
  updateUsersStatusStart,
  updateUsersStatusComplete,
  updateUsersStatusFailed,
  resetUserPwdStart,
  resetUserPwdComplete,
  resetUserPwdFailed,
  clearPasswordReset,
  changeUserPwdStart,
  changeUserPwdComplete,
  changeUserPwdFailed,
  createUserStart,
  createUserComplete,
  createUserFailed,
  userMembershipStart,
  userMembershipComplete,
  userMembershipFailed,
} = users.actions;

export default users.reducer;

//Async Functions
export const retrieveUserList = () => async (dispatch) => {
  dispatch(loadUsersStart);
  api.user.getUserList().then((res) => {
    const { results, error } = res;
    if (results) {
      dispatch(loadUsersComplete({ userList: results }));
    } else {
      dispatch(loadUsersFailed({ error: error || 'API_ERROR' }));
    }
  });
};

export const retrieveUserDetails = (userSelected) => async (dispatch) => {
  dispatch(loadUsersStart);
  const { userName } = userSelected;
  api.user.getUserDetails(userName).then((res) => {
    const { results, error } = res;
    if (results) {
      dispatch(loadUserDetailComplete({ userDetails: results }));
    } else {
      dispatch(loadUsersFailed({ error: error || 'API_ERROR' }));
    }
  });
};

export const updateUserStatus =
  (userSelected) => async (dispatch, getState) => {
    dispatch(updateUsersStatusStart);
    const {
      users: { userList },
    } = getState();
    api.user.updateUserStatus(userSelected).then((res) => {
      const { results, error } = res;
      if (results) {
        const currentUsers = userList.map((user) => {
          if (user.userName === userSelected.userName) {
            return userSelected;
          }
          return user;
        });
        dispatch(updateUsersStatusComplete({ userList: currentUsers }));
      } else {
        dispatch(updateUsersStatusFailed({ error: error || 'API_ERROR' }));
      }
    });
  };

export const resetUserPassword =
  (userSelected) => async (dispatch, getState) => {
    dispatch(resetUserPwdStart);
    const {
      users: { userList },
    } = getState();
    api.user.resetUserPassword(userSelected).then((res) => {
      const { results, error } = res;
      if (results) {
        const currentUsers = userList.map((user) => {
          if (user.userName === userSelected.userName) {
            return userSelected;
          }
          return user;
        });
        dispatch(
          resetUserPwdComplete({
            userList: currentUsers,
            passwordResetMessage: results.message,
            passwordResetValue: results.payload,
          })
        );
      } else {
        dispatch(resetUserPwdFailed({ error: error || 'API_ERROR' }));
      }
    });
  };

export const resetUserPwd = (userSelected) => async (dispatch, getState) => {
  dispatch(changeUserPwdStart);
  const {
    users: { userList },
  } = getState();
  api.user.userChangePassword(userSelected).then((res) => {
    const { results, error } = res;
    if (results) {
      const currentUsers = userList.map((user) => {
        if (user.userName === userSelected.userName) {
          return userSelected;
        }
        return user;
      });
      dispatch(changeUserPwdComplete({ userList: currentUsers }));
    } else {
      dispatch(changeUserPwdFailed({ error: error || 'API_ERROR' }));
    }
  });
};

export const createUser = (formData) => async (dispatch) => {
  dispatch(createUserStart);
  api.user.createUser(formData).then((res) => {
    const { results, error } = res;
    if (results) {
      dispatch(createUserComplete({ apiResponse: results }));
    } else {
      dispatch(createUserFailed({ error: error || 'API_ERROR' }));
    }
  });
};

export const updateUserMembership = (formData) => async (dispatch) => {
  dispatch(userMembershipStart);
  api.user.updateUserRole(formData).then((res) => {
    const { results, error } = res;
    if (results) {
      dispatch(userMembershipComplete({ data: results }));
    } else {
      dispatch(userMembershipFailed({ error: error || 'API_ERROR' }));
    }
  });
};

// export const updateUserMembership = (formData, action) => async (dispatch) => {
//   dispatch(userMembershipStart);
//   api.user.createUser(formData, action).then((res) => {
//     const { results, error } = res;
//     if (results) {
//       dispatch(userMembershipComplete);
//     } else {
//       dispatch(userMembershipFailed({ error: error || 'API_ERROR' }));
//     }
//   });
// };
