import { combineReducers } from '@reduxjs/toolkit';
import authenticationReducer from './modules/authentication';
import currentUserReducer from './modules/currentUser';
import usersReducer from './modules/users';
import locationsReducer from './modules/location';
import vendorsReducer from './modules/vendor';
import ordersReducer from './modules/order';
import lovReducer from './modules/lov';

const rootReducer = combineReducers({
  authentication: authenticationReducer,
  currentUser: currentUserReducer,
  users: usersReducer,
  locations: locationsReducer,
  vendors: vendorsReducer,
  orders: ordersReducer,
  lov: lovReducer,
});

export default rootReducer;
