import React, { useState, useEffect } from 'react';
import './Login.css';
import logo from 'Assets/logo.jpg';
import { useSelector, useDispatch } from 'react-redux';
import { authorizeUser } from 'store/modules/authentication';
import { useHistory } from 'react-router-dom';
import whitelogo from 'Assets/white-logo.png';

const loginModel = {
  credentials: {
    userName: '',
    password: '',
  },
};

export default function LoginBox(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoggingIn, isAuthenticated, sessionToken, authenticationFailed } =
    useSelector((state) => state.authentication);
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  useEffect(() => {
    if (!isLoggingIn && isAuthenticated) {
      history.push('/');
    }
  }, [isLoggingIn, isAuthenticated]);

  const handleChangeUserName = (e) => {
    const userNameValue = e.target.value;
    setUserName(userNameValue);
  };
  const handleChangePassword = (e) => {
    const passwordValue = e.target.value;
    setPassword(passwordValue);
  };

  const submitCredentials = (e) => {
    e.preventDefault();
    dispatch(authorizeUser(username, password));
  };

  return (
    <div className="login-page">
      <div className="login-header">
        <img src={whitelogo} className="whitelogo" />
        <a
          href="https://smithfieldfoods.service-now.com/"
          className="tech-support"
        >
          Technology Support
        </a>
      </div>
      <div className="login-container" id="login-container">
        <div className="form-container">
          <form className="login-form" action="#" onSubmit={submitCredentials}>
            <img className="login-logo" src={logo} />
            <p style={{ color: 'red' }}>
              {authenticationFailed & !isAuthenticated ? 'Login Failed!' : null}
            </p>
            <input
              className="login-field"
              type="text"
              placeholder="User Name"
              name="email"
              value={username}
              onChange={handleChangeUserName}
            />
            <input
              className="password-field"
              type="password"
              placeholder="Password"
              name="password"
              value={password}
              onChange={handleChangePassword}
            />
            <div className="signed-in-box">
              <input
                type="checkbox"
                id="keep-signed-in"
                name="keep-signed-in"
                value="keeploggedin"
              />
              <label className="sign-in-label" htmlFor="keep-signed-in">
                Keep me logged in
              </label>
            </div>
            <a className="sf-logo" href="#">
              Forgot your password?
            </a>
            <button
              className="login-button"
              type="submit"
              onClick={submitCredentials}
            >
              Login Now
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
