export const vendorFormModel = {
  columns: 2,
  formInputs: {
    active: {
      type: 'drop-down',
      isVisible: true,
      label: 'Status',
      value: '',
      column: 1,
      randomNumber: 1,
      options: [
        {
          value: '',
          label: 'Select Status',
        },
        {
          value: true,
          label: 'Activate',
        },
        {
          value: false,
          label: 'Deactivate',
        },
      ],
    },
    vendorID: {
      type: 'input',
      isVisible: true,
      label: 'Vendor ID',
      value: '',
      column: 1,
      randomNumber: 1,
    },
    vendorName: {
      type: 'input',
      isVisible: true,
      label: 'Vendor Name',
      value: '',
      column: 1,
      randomNumber: 1,
    },
    vendorEIN: {
      type: 'input',
      isVisible: true,
      label: 'Vendor EIN',
      value: '',
      column: 1,
      randomNumber: 1,
    },
    fullName: {
      type: 'input',
      isVisible: true,
      label: 'Full Name',
      value: '',
      column: 2,
      randomNumber: 1,
    },
    contactEmail: {
      type: 'input',
      isVisible: true,
      label: 'Contact Email',
      value: '',
      column: 2,
      randomNumber: 1,
    },
    contactAddress: {
      type: 'input',
      isVisible: true,
      label: 'Contact Address',
      value: '',
      column: 2,
      randomNumber: 1,
    },
  },
};

export const vendorUpdateFormModel = {
  columns: 2,
  formInputs: {
    active: {
      type: 'drop-down',
      isVisible: true,
      label: 'Status',
      value: '',
      column: 1,
      randomNumber: 1,
      options: [
        {
          value: '',
          label: 'Select Status',
        },
        {
          value: true,
          label: 'Activate',
        },
        {
          value: false,
          label: 'Deactivate',
        },
      ],
    },
    vendorID: {
      type: 'input',
      isVisible: true,
      label: 'Vendor ID',
      value: '',
      column: 1,
      randomNumber: 1,
    },
    vendorName: {
      type: 'input',
      isVisible: true,
      label: 'Vendor Name',
      value: '',
      column: 1,
      randomNumber: 1,
    },
    vendorEIN: {
      type: 'input',
      isVisible: true,
      label: 'Vendor EIN',
      value: '',
      column: 1,
      randomNumber: 1,
    },
    fullName: {
      type: 'input',
      isVisible: true,
      label: 'Full Name',
      value: '',
      column: 2,
      randomNumber: 1,
    },
    contactEmail: {
      type: 'input',
      isVisible: true,
      label: 'Contact Email',
      value: '',
      column: 2,
      randomNumber: 1,
    },
    contactAddress: {
      type: 'input',
      isVisible: true,
      label: 'Contact Address',
      value: '',
      column: 2,
      randomNumber: 1,
    },
  },
};
