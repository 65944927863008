import React from 'react';
import PropTypes from 'prop-types';
import './SideBarStyle.css';

export default function SidebarItem(props) {
  return <div className="side-bar-outer">{props.name}</div>;
}

SidebarItem.propTypes = {
  name: PropTypes.string,
};
