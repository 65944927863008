import React, { useState } from 'react';
import './AccountSettings.css';

export default function ChangePasswordForm({
  onChange,
  currentPassword,
  newPassword,
  confirmPassword,
  onSubmitPasswordChange,
}) {
  const [isMatched, setIsMatched] = useState(true);
  const handleCheckConfirmMatch = () => {
    if (confirmPassword === newPassword) {
      setIsMatched(true);
    } else {
      setIsMatched(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newPassword === confirmPassword) {
      setIsMatched(true);
      onSubmitPasswordChange();
    } else {
      setIsMatched(false);
    }
  };

  return (
    <form className="account-setting-form" action="#">
      <div className="account-setting-input">
        <label htmlFor="currentPassword" className="account-settings-label">
          Current Password
        </label>
        <input
          className="current-password-field"
          type="password"
          placeholder="Current Password"
          name="currentPassword"
          value={currentPassword}
          onChange={onChange}
        />
      </div>
      {!isMatched && (
        <div className="change-password-error">
          <p style={{ marginTop: '10px', marginBottom: '0px' }}>
            {"Password Doesn't Match"}
          </p>
        </div>
      )}
      <div className="account-setting-input">
        <label htmlFor="currentPassword" className="account-settings-label">
          New Password
        </label>
        <input
          className="new-password-field"
          type="password"
          placeholder="New Password"
          name="newPassword"
          value={newPassword}
          onChange={onChange}
        />
      </div>
      <div className="account-setting-input">
        <label htmlFor="currentPassword" className="account-settings-label">
          Confirm Password
        </label>
        <input
          className="confirm-password-field"
          type="password"
          placeholder="Confirm Password"
          name="confirmPassword"
          value={confirmPassword}
          onChange={onChange}
          onBlur={handleCheckConfirmMatch}
        />
      </div>
      <button
        className="change-password-submit"
        type="submit"
        onClick={handleSubmit}
        disabled={!isMatched}
      >
        Submit
      </button>
    </form>
  );
}
