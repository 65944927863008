import React, { useState } from 'react';
import LoadingIcons from 'react-loading-icons';
import { useDispatch } from 'react-redux';
import AllOrdersList from './Container/AllOrdersList';
import OrderDetails from './Container/OrderDetails';
import { clearSelectedOrder, retrieveOrderList } from 'store/modules/order';
import OrderUpdateCompleted from 'components/OriginatorHome/OpenOrders/Container/OrderUpdateCompleted';
import NewHedgeOrder from 'components/New Orders/NewHedgeOrders/Containers/NewHedgeOrder';
import ReviewOrderDetails from 'components/New Orders/NewHedgeOrders/Containers/ReviewOrderDetails';
import { BiXCircle } from 'react-icons/bi';

export default function AllOrdersWindow(props) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [currentMode, setCurrentMode] = useState('list');
  const [formData, setFormData] = useState();
  const [isUpdate, setIsUpdate] = useState(false);

  const handleModeChange = (mode) => {
    setCurrentMode(mode);
  };

  const returnHome = () => {
    handleModeChange('list');
  };

  const formMode = (mode, data) => {
    if (mode === 'edit') {
      setIsUpdate(true);
      setFormData(data);
    } else {
      setIsUpdate(false);
      setFormData();
    }
  };

  const changeToList = () => {
    dispatch(clearSelectedOrder());
    dispatch(retrieveOrderList(false, '', ''));
  };

  const handleFormValues = (formData) => {
    setFormData(formData);
  };

  const handleCurrentContainer = () => {
    switch (currentMode) {
      case 'list':
        return <AllOrdersList onModeChange={handleModeChange} />;

      case 'detail':
        return (
          <OrderDetails
            onModeChange={handleModeChange}
            returnBack={changeToList}
            isUpdateMode={formMode}
          />
        );
      case 'form':
        return (
          <div>
            <BiXCircle
              className="close-button"
              size={40}
              onClick={returnHome}
              style={{ float: 'right' }}
            ></BiXCircle>
            <NewHedgeOrder
              onModeChange={handleModeChange}
              formValue={handleFormValues}
              isUpdate={isUpdate}
              initialValues={formData}
            />
          </div>
        );
      case 'detailreview':
        return (
          <div>
            <BiXCircle
              className="close-button"
              size={40}
              onClick={returnHome}
              style={{ float: 'right' }}
            ></BiXCircle>
            <ReviewOrderDetails
              onModeChange={handleModeChange}
              formData={formData}
              isUpdateMode={formMode}
            />
          </div>
        );

      case 'completedorder':
        return <OrderUpdateCompleted onModeChange={handleModeChange} />;

      default:
        return <div>{"I'm sorry an error has occured"}</div>;
    }
  };

  return isLoading ? (
    <div className="location-loading-icon">
      <LoadingIcons.Oval stroke="#006730" />
      <br /> <br />
    </div>
  ) : (
    <div className="all-orders-form">
      <div className="handle-container" style={{ backgroundColor: 'white' }}>
        {handleCurrentContainer()}
      </div>
    </div>
  );
}
