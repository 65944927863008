import React, { useEffect, useState, useMemo } from 'react';
import './NotificationStyle.css';
import api from 'services/api';
import LoadingIcons from 'react-loading-icons';

import NotificationPage from './Container/NotificationPage';

export default function NotificationWindow(props) {
  const [notifLocationId, setnotifLocationId] = useState('');

  const [currentNotifLocations, setCurrentNotifLocations] = useState([]);
  const [currentUsersbyLocation, setCurrentUsersbyLocation] = useState([]);
  const [userList, setUserList] = useState([]);

  const [currentMode, setCurrentMode] = useState('list');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getCurrentNotifLocationList();
    getUserList();
  }, []);

  useEffect(() => {
    if (notifLocationId) {
      setIsLoading(true);
      getNotificationUserList();
    }
  }, [notifLocationId]);

  const filteredUserList = useMemo(() => {
    const currentUserIDs = currentUsersbyLocation
      .filter((user) => user.active)
      .map((user) => user.userName);
    return userList.filter((user) => !currentUserIDs.includes(user.userName));
  }, [currentUsersbyLocation, userList]);

  const getNotificationUserList = () => {
    api.notification.getNotifbyLocation(notifLocationId).then((results) => {
      setCurrentUsersbyLocation(results);
      setIsLoading(false);
    });
  };

  const getCurrentNotifLocationList = () => {
    api.notification.getNotifLocations().then((results) => {
      setCurrentNotifLocations(results);
      setIsLoading(false);
    });
  };

  const getUserList = () => {
    api.notification.getUserList().then((results) => {
      setUserList(results);
      setIsLoading(false);
    });
  };

  const createNotification = (notificationDetails) => {
    api.notification
      .createUserNotification(notificationDetails)
      .then((results) => {
        if (results.status === 200 && results.data.status === 'OK') {
          getNotificationUserList();
          getUserList();
        } else {
          api.notification
            .updateUserNotification(notificationDetails)
            .then((results) => {
              getNotificationUserList();
              getUserList();
            });
        }
      });
  };

  const updateNotification = (updateNotificationDetails) => {
    api.notification
      .updateUserNotification(updateNotificationDetails)
      .then((results) => {
        if (results.status === 200) {
          getNotificationUserList();
          getUserList();
        }
      });
  };

  const handleModeChange = (mode) => {
    setCurrentMode(mode);
  };

  const handleLocationChange = (locationID) => {
    setnotifLocationId(locationID);
  };

  const handleCurrentContainer = () => {
    switch (currentMode) {
      case 'list':
        return (
          <NotificationPage
            onModeChange={handleModeChange}
            onAddNotificationUSer={createNotification}
            onRemoveNotificationUSer={updateNotification}
            notifLocation={currentNotifLocations}
            userList={filteredUserList}
            notificationUsersByLocation={currentUsersbyLocation}
            locationID={notifLocationId}
            onLocationChange={handleLocationChange}
          />
        );

      default:
        return <div>{"I'm sorry an error has occured"}</div>;
    }
  };

  return (
    <div className="notification-form">
      <br />
      {isLoading ? (
        <div className="notification-loading-icon">
          <LoadingIcons.Oval stroke="#006730" />
          <br /> <br />
        </div>
      ) : (
        handleCurrentContainer()
      )}
    </div>
  );
}
