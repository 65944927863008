import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cancelOrder } from 'store/modules/order';
import { Popup } from '@progress/kendo-react-popup';

export default function cancelCell(props) {
  const dispatch = useDispatch();

  const anchor = useRef(null);
  const [showPopUp, setShowPopUp] = useState(false);

  const cancelOder = () => {
    setShowPopUp(!showPopUp);
  };

  const confirmCancelOrder = () => {
    const { id, locationID } = props.dataItem;
    const formaData = {};
    formaData.id = id;
    formaData.locationID = locationID;
    dispatch(cancelOrder(formaData));
  };

  return (
    <td onClick={cancelOder} ref={anchor}>
      <div style={{ cursor: 'pointer', color: 'blue', fontWeight: '550' }}>
        Cancel
      </div>
      <Popup
        anchor={anchor.current}
        show={showPopUp}
        popupClass={'popup-content'}
      >
        <div className="confirm-cancel">
          <h3 style={{ marginBottom: '20px', color: 'white' }}>
            Are you sure you want to cancel order {props.dataItem.id}?
          </h3>
          <button className="yes-button" onClick={confirmCancelOrder}>
            Yes
          </button>
          <button className="no-button" onClick={cancelOder}>
            No
          </button>
        </div>
      </Popup>
    </td>
  );
}
