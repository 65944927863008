import React from 'react';

const spacer = {
  width: '100%',
  height: '50px',
  backgroundColor: '#006730',
  color: 'black',
  left: '0',
};

class SidebarTop extends React.Component {
  state = {
    isHighlight: false,
    count: 0,
  };

  ///Place cool grain pic or something
  render() {
    return <div style={spacer}></div>;
  }
}

export { SidebarTop };
