import React, { useState, useEffect } from 'react';
import { Table, Button, Input, Space } from 'antd';
import '../LocationStyle.css';
import { cssHooks } from 'jquery';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  retrieveLocationDetails,
  retrieveLocationList,
} from 'store/modules/location';
import LoadingOverlay from 'components/Common/LoadingOverlay';
export default function LocationListContainer(props) {
  const dispatch = useDispatch();
  const { locationList, selectedLocation, loadingLocations, loadedLocations } =
    useSelector((state) => state.locations);

  const [filteredLocationlist, setFilteredLocationlist] =
    useState(locationList);

  const [formData, setFormData] = useState([]);

  useEffect(() => {
    dispatch(retrieveLocationList());
  }, []);

  useEffect(() => {
    if (loadedLocations) {
      setFilteredLocationlist(locationList);
    }
  }, [locationList]);

  const handleLocationSelection = (locationSelected) => {
    dispatch(retrieveLocationDetails(locationSelected));
    props.onModeChange('detail');
  };

  const handleValueUpdates = (childData) => {
    setFormData(childData);
  };

  const handleAddLocation = () => {
    props.onModeChange('form');
  };

  const onSearch = (event) => {
    const {
      target: { value },
    } = event;

    const newLocationList = locationList.filter(
      (location) =>
        location.locationName.toLowerCase().includes(value.toLowerCase()) ||
        location.locationID.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredLocationlist(newLocationList);
  };

  const locationColumns = [
    {
      title: 'Location ID',
      dataIndex: 'locationID',
      key: 'locationID',
      render: (text, record) => (
        <Button type="link" onClick={() => handleLocationSelection(record)}>
          {text}
        </Button>
      ),
    },
    {
      title: 'Location Name',
      dataIndex: 'locationName',
      key: 'locationName',
      width: 500,
    },
    {
      title: 'Status',
      dataIndex: 'active',
      key: 'status',
      width: 300,
      render: (text, record) => (
        <span>{record.active ? 'Active' : 'Inactive'}</span>
      ),
    },
  ];

  return (
    <div className="location-list">
      <div className="table-container">
        <Space className="search-add-container">
          <Input
            placeholder="Search Location"
            onChange={onSearch}
            className="search-input"
          />
          <Button
            className="add-button"
            style={{ float: 'right', backgroundColor: '#5fb471' }}
            size="large"
            onClick={handleAddLocation}
          >
            Add New Location
          </Button>
        </Space>
        <Table
          columns={locationColumns}
          dataSource={filteredLocationlist}
          filterSearch={true}
          loading={loadingLocations || !loadedLocations}
          rowKey="locationName"
          rowClassName={(record, index) =>
            record.active ? 'active-loc-row' : 'inactive-loc-row'
          }
        />
      </div>
    </div>
  );
}

LocationListContainer.propTypes = {
  locationList: PropTypes.array,
  onLocationSelect: PropTypes.string,
  onModeChange: PropTypes.func,
};
