import React from 'react';
import WorkingOrdersList from './WorkingOrder';
import MarketOrdersList from './MarketOrders';

export default function AllOrdersList(props) {
  const handleModeChange = (mode) => {
    props.onModeChange(mode);
  };

  return (
    <div id="adminwindow">
      <h2>Market Order</h2>
      <MarketOrdersList onModeChange={handleModeChange}></MarketOrdersList>
      <h2>Working Order</h2>
      <WorkingOrdersList onModeChange={handleModeChange}></WorkingOrdersList>
    </div>
  );
}
