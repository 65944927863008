import React from 'react';
import './FormPartsStyle.css';

export class FormDropDown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: (props.locked && props.active) || false,
      value: props.value || '',
      error: props.error || '',
      label: props.label || '',
    };
  }

  buildComboBox = () => {
    try {
      var valueArray = [];
      this.props.dropDownItems.map((itemVal, index) => {
        valueArray.push(<option value={itemVal.value}>{itemVal.label}</option>);
      });
      return valueArray;
    } catch {
      return <div></div>;
    }
  };

  changeValue = (event) => {
    const currentVal = event.target.value;
    this.setState({ value: currentVal, error: '' });
    this.props.onInputChange(currentVal, this.props.position);
  };

  handleKeyPress(event) {
    if (event.which === 13) {
      this.setState({ value: this.props.predicted });
    }
  }

  render() {
    const { active, value, error, label } = this.state;
    const { predicted, locked } = this.props;
    const fieldClassName = `field ${
      (locked ? active : active || value) && 'active'
    } ${locked && !active && 'locked'}`;

    return (
      <div className={fieldClassName}>
        {active && value && predicted && predicted.includes(value) && (
          <p className="predicted">{predicted}</p>
        )}

        <select placeholder={label} onChange={this.changeValue}>
          {this.buildComboBox()}
        </select>
      </div>
    );
  }
}
