import { useState } from 'react';
import api from 'services/api';

/*
 * Hooks to contain password functionalities such as
 *    Reset Password & Update Password
 * TODO: Add reset password api call and functionalities here
 */

const usePasswordUpdate = () => {
  const [updatingPassword, setUpdatingPassword] = useState(false);
  const [passwordUpdated, setPasswordUpdated] = useState(false);
  const [updateError, setUpdateError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showModal, setShowModal] = useState(false);

  const changePassword = (userName, oldPassword, newPassword) => {
    setUpdatingPassword(true);
    setSuccessMessage('');
    setShowModal(false);
    api.user
      .userChangePassword(userName, newPassword, oldPassword)
      .then((results) => {
        const { status, data } = results;
        if (status === 200 && data.status !== 'ERROR') {
          setUpdatingPassword(false);
          setPasswordUpdated(false);
          setShowModal(true);
          setSuccessMessage(data.message);
        } else {
          setUpdateError(data.message || data.messageCode);
          setUpdatingPassword(false);
          setSuccessMessage(data.message);
          setShowModal(true);
        }
      })
      .catch((error) => {
        setUpdateError('Unknown Error');
        console.log(error);
      });
  };

  return {
    updatingPassword,
    passwordUpdated,
    updateError,
    successMessage,
    showModal,
    changePassword,
  };
};

export default usePasswordUpdate;
