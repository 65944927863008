import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import StandardForm from 'components/Common/Forms/StandardFormV2';
import '../UserWindowStyle.css';
import userRoleModel from 'utils/forms/userRoleModel';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserMembership } from 'store/modules/users';
export default function NewRoleContainer({ userRoles }) {
  const dispatch = useDispatch();
  const { selectedUser } = useSelector((state) => state.users);
  const generateFormFromModel = () => {
    const initialForm = {};
    Object.keys(userRoleModel.formInputs).forEach((formKey) => {
      const currentInput = userRoleModel.formInputs[formKey];
      initialForm[formKey] = currentInput.value;
    });

    return initialForm;
  };

  const [formData, setFormData] = useState(generateFormFromModel());

  useEffect(() => {
    setFormData({
      ...formData,
      // userType: [...formData.userType, ...(userRoles || [])],
      userType: [...(userRoles || [])],
    });
  }, [userRoles]);

  const handleValueUpdates = (newFormData) => {
    setFormData({ ...formData, ...newFormData });
  };

  const submitUpdateRole = () => {
    formData['userName'] = selectedUser.userName;
    dispatch(updateUserMembership(formData));
    // Call API
    // Dependency on update role implementation with API.
  };

  return (
    <div className="user-container">
      <StandardForm
        valuesUpdate={handleValueUpdates}
        formModel={userRoleModel}
        formValues={formData}
      />
      <div className="btn-holder">
        <button className="add-user-btn" onClick={submitUpdateRole}>
          Update Role
        </button>
      </div>
    </div>
  );
}

NewRoleContainer.propTypes = {
  userRoles: PropTypes.arrayOf(PropTypes.string),
};
