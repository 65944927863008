import { Divider } from 'antd';
import FileUpload from 'components/Common/UploadForm/FileUpload';
import React from 'react';
import { BiXCircle } from 'react-icons/bi';

export default function VendorUploadForm(props) {
  const returnHome = () => {
    props.onModeChange('list');
  };

  return (
    <div style={{ marginBottom: '20px' }}>
      <div className="return-button" id="return-button">
        <h2>Update Vendors</h2>
        <BiXCircle className="close-button" size={40} onClick={returnHome} />
      </div>
      <Divider className="divider" />
      {/* <div id="divider" /> */}
      <div style={{ marginBottom: '20px' }}>
        Select your file to update the Vendor list.
      </div>
      <div style={{ marginBottom: '20px' }}>
        Excel columns must be as follows: <br /> Vendor ID, Name, Created
        Date(YYYY-MM-DD), City, State, Short Name
      </div>
      <FileUpload returnHome={returnHome}></FileUpload>
    </div>
  );
}
