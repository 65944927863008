import React from 'react';
import './App.css';
import { initializeAPIwithAuthentication } from 'services/api/core';
import MainLayout from 'components/layouts/MainLayout';
import sessionCookies from 'utils/cookies/sessionCookies';
import '@progress/kendo-theme-default/dist/all.css';
import version from '../package.json';

export const GlobalStateContext = React.createContext();

export default function App() {
  const { token } = sessionCookies.getSessionCookies();
  initializeAPIwithAuthentication(token);
  return (
    <div className="App" height="100%" width="100%">
      <MainLayout version={version.version} />
    </div>
  );
}
