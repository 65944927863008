import api from '../../core/index';

const notification = {
  getNotifLocations: () => {
    const notificationList = `Location/GetList?activeonly=${true}`;
    return api.client.get(notificationList).then(
      (result) => {
        const { status, data } = result;
        if (status === 200) {
          return data.payload;
        } else {
          return {};
        }
      },
      (error) => {
        console.log(error);
      }
    );
  },

  getUserList: () => {
    const userList = `User/getlist/${true}`;

    return api.client.get(userList).then(
      (result) => {
        const { status, data } = result;
        if (status === 200) {
          return data.payload;
        } else {
          return {};
        }
      },
      (error) => {
        return error;
      }
    );
  },

  getNotifbyLocation: (locationID) => {
    const userListByLocation = `Notification/GetList?locationID=${locationID}`;

    return api.client.get(userListByLocation).then(
      (result) => {
        const { status, data } = result;
        if (status === 200) {
          return data.payload;
        } else {
          return {};
        }
      },
      (error) => {
        return error;
      }
    );
  },
  createUserNotification: (notificationDetails) => {
    const createNotificationURL = 'Notification/Create';
    return api.client
      .post(createNotificationURL, {
        ...notificationDetails,
        deviceName: 'TestPC',
      })
      .then(
        (result) => {
          return result;
        },
        (error) => {
          return error;
        }
      );
  },
  updateUserNotification: (notificationDetails) => {
    const updateNotificationURL = 'Notification/Update';
    return api.client
      .put(updateNotificationURL, {
        ...notificationDetails,
        deviceName: 'TestPC',
      })
      .then(
        (result) => {
          return result;
        },

        (error) => {
          return error;
        }
      );
  },
};

export default notification;
