import { createSlice } from '@reduxjs/toolkit';
import api from 'services/api';

const initialState = {
  contractTypeLOV: [],
  locSectionLOV: [],
  orderGroupLOV: [],
  altContractTypeLOV: [],
  commodityLOV: [],
  commoditySymbolLOV: [],
  forecastTypeLOV: [],
  locAssignLOV: [],
  locTypeLOV: [],
  optionMonthLOV: [],
  shipmentLOV: [],
  shipModeLOV: [],
  uomLOV: [],
  woTypeLOV: [],
  loadedCommodity: false,
  loadedCommoditySymbol: false,
  loadedAltContractType: false,
  loadedShipMode: false,
  loadedWOTypeLOV: false,
  loadingLOVList: false,
  loadedLOVList: false,
  loadLOVListError: '',
  locationLOVList: [],
  loadingLocationLOVList: false,
  loadedLocationLOVList: false,
  loadLocationLOVListError: '',
  locationInfo: [],
  loadingLocationInfo: false,
  loadedLocationInfo: false,
  loadLocationInfoError: '',
  vendorLOVList: [],
  loadingVendorLOVList: true,
  loadedVendorLOVList: false,
  loadVendorLOVListError: '',
  // vendorInfo: [],
  // loadingVendorInfo: false,
  // loadedVendorInfo: false,
  // loadVendorInfoError: '',
};

export const lov = createSlice({
  name: 'lov',
  initialState,
  reducers: {
    loadLocationLOVListStart: (state) => {
      state.loadingLocationLOVList = true;
      state.loadedLocationLOVList = false;
    },
    loadLocationLOVListCompleted: (state, { payload }) => {
      const { locationLOVList, direction } = payload;
      const locationValueArray = [];
      Object.keys(locationLOVList).forEach((key) => {
        locationValueArray.push(
          // locationLOVList[key].locationName + ' ' + direction.toUpperCase()
          locationLOVList[key].locationName
        );
      });
      state.locationLOVList = locationValueArray;
      state.loadingLocationLOVList = false;
      state.loadedLocationLOVList = true;
    },
    loadLocationLOVListFailed: (state, { payload }) => {
      const { error } = payload;
      state.loadingLocationLOVList = false;
      state.loadedLocationLOVList = false;
      state.loadLocationLOVListError = error;
    },
    clearLocationLOVList: (state) => {
      state.locationLOVList = {};
      state.loadingLocationLOVList = false;
      state.loadedLocationLOVList = false;
      state.loadLocationLOVListError = '';
    },
    loadLocationInfoStart: (state) => {
      state.loadingLocationInfo = false;
      state.loadedLocationInfo = false;
    },
    loadLocationInfoCompleted: (state, { payload }) => {
      const { locationInfo } = payload;
      state.locationInfo = locationInfo[0].locationID;
      state.loadingLocationInfo = false;
      state.loadedLocationInfo = true;
    },
    loadLocationInfoFailed: (state, { payload }) => {
      const { error } = payload;
      state.loadingLocationInfo = false;
      state.loadedLocationInfo = false;
      state.loadLocationInfoError = error;
    },
    clearLocationInfo: (state) => {
      state.locationInfo = [];
      state.loadingLocationInfo = false;
      state.loadedLocationInfo = false;
      state.loadLocationInfoError = '';
    },
    loadVendorLOVListStart: (state) => {
      state.loadingVendorLOVList = true;
      state.loadedVendorLOVList = false;
    },
    loadVendorLOVListCompleted: (state, { payload }) => {
      const { vendorLOVList } = payload;
      const vendorValueArray = [];
      Object.keys(vendorLOVList).forEach((key) => {
        vendorValueArray.push(
          vendorLOVList[key].vendorName + '-' + vendorLOVList[key].vendorID
        );
      });
      state.vendorLOVList = vendorValueArray;
      state.loadingVendorLOVList = false;
      state.loadedVendorLOVList = true;
    },
    loadVendorLOVListFailed: (state, { payload }) => {
      const { error } = payload;
      state.loadingVendorLOVList = false;
      state.loadedVendorLOVList = false;
      state.loadVendorLOVListError = error;
    },
    clearVendorLOVList: (state) => {
      state.vendorLOVList = {};
      state.loadingVendorLOVList = false;
      state.loadedVendorLOVList = false;
      state.loadVendorLOVListError = '';
    },
    // loadVendorInfoStart: (state) => {
    //   state.loadingVendorInfo = false;
    //   state.loadedVendorInfo = false;
    // },
    // loadVendorInfoCompleted: (state, { payload }) => {
    //   const { vendorInfo } = payload;
    //   if (vendorInfo[0].vendorID !== undefined) {
    //     state.vendorInfo = vendorInfo[0].vendorID;
    //   } else {
    //     state.vendorInfo = '';
    //   }
    //   state.loadingVendorInfo = false;
    //   state.loadedVendorInfo = true;
    // },
    // loadVendorInfoFailed: (state, { payload }) => {
    //   const { error } = payload;
    //   state.loadingVendorInfo = false;
    //   state.loadedVendorInfo = false;
    //   state.loadVendorInfoError = error;
    // },
    // clearVendorInfo: (state) => {
    //   state.vendorInfo = [];
    //   state.loadingVendorInfo = false;
    //   state.loadedVendorInfo = false;
    //   state.loadVendorInfoError = '';
    // },
    loadLOVListStart: (state) => {
      state.loadingLOVList = true;
      state.loadedLOVList = false;
    },
    loadLOVListCompleted: (state, { payload }) => {
      const { lovList, config, prevValue } = payload;
      if (config.includes('AltContractType') === true) {
        const altContractTypeArray = [];
        lovList.map((itemVal) => {
          altContractTypeArray.push(itemVal.loVcode);
          state.loadedAltContractType = true;
          state.altContractTypeLOV = altContractTypeArray;
        });
      } else if (config.includes('CommoditySymbol') === true) {
        const commoditySymbolArray = [];
        lovList.map((itemVal) => {
          commoditySymbolArray.push(itemVal.loVcode);
          state.loadedCommoditySymbol = true;
          if (prevValue === 'Corn') {
            const filterValues = ['C'];
            const newCommoditySymbolArray = commoditySymbolArray.filter(
              (item) => filterValues.includes(item)
            );
            state.commoditySymbolLOV = newCommoditySymbolArray;
          } else if (prevValue === 'Wheat') {
            const filterValues = ['C', 'W'];
            const newCommodityArray = commoditySymbolArray.filter((item) =>
              filterValues.includes(item)
            );
            state.commoditySymbolLOV = newCommodityArray;
          } else if (prevValue === 'Soybeans') {
            const filterValues = ['S'];
            const newCommodityArray = commoditySymbolArray.filter((item) =>
              filterValues.includes(item)
            );
            state.commoditySymbolLOV = newCommodityArray;
          } else if (prevValue === 'SBM') {
            const filterValues = ['SM'];
            const newCommodityArray = commoditySymbolArray.filter((item) =>
              filterValues.includes(item)
            );
            state.commoditySymbolLOV = newCommodityArray;
          } else {
            const filterValues = ['X'];
            const newCommodityArray = commoditySymbolArray.filter(
              (item) => !filterValues.includes(item)
            );
            state.commoditySymbolLOV = newCommodityArray;
          }
        });
      } else if (config.includes('Commodity') === true) {
        const commodityArray = [];
        lovList.map((itemVal) => {
          commodityArray.push(itemVal.loVcode);
          state.loadedCommodity = true;
          if (prevValue === 'Grain') {
            const filterValues = [
              'Corn',
              'Milling Wheat',
              'Other',
              'Sorghum',
              'Soybeans',
              'Wheat',
            ];
            const newCommodityArray = commodityArray.filter((item) =>
              filterValues.includes(item)
            );
            state.commodityLOV = newCommodityArray;
          } else if (prevValue === 'Soybean Meal') {
            const filterValues = ['SBM'];
            const newCommodityArray = commodityArray.filter((item) =>
              filterValues.includes(item)
            );
            state.commodityLOV = newCommodityArray;
          } else if (prevValue === 'Other Ingredient') {
            const filterValues = [
              'Balance',
              'Corn',
              'Milling Wheat',
              'Sorghum',
              'Soybeans',
              'Wheat',
            ];
            const newCommodityArray = commodityArray.filter(
              (item) => !filterValues.includes(item)
            );
            newCommodityArray.push('Lactose');
            state.commodityLOV = newCommodityArray;
          } else {
            state.commodityLOV = commodityArray;
          }
        });
      } else if (config.includes('ShipMode') === true) {
        const shipModeArray = [];
        lovList.map((itemVal) => {
          shipModeArray.push(itemVal.loVcode);
          state.loadedShipMode = true;
          state.shipModeLOV = shipModeArray;
        });
      } else if (config.includes('WOtype') === true) {
        const WOtypeArray = [];
        lovList.map((itemVal) => {
          WOtypeArray.push(itemVal.loVcode);
          state.loadedWOTypeLOV = true;
          state.woTypeLOV = WOtypeArray;
        });
      }
      state.loadingLOVList = false;
      state.loadedLOVList = true;
    },
    loadLOVListFailed: (state, { payload }) => {
      const { error } = payload;
      state.loadingLOVList = false;
      state.loadedLOVList = false;
      state.loadLOVListError = error;
    },
    clearLOVList: (state) => {
      state.lovList = [];
      state.loadingLOVList = false;
      state.loadedLOVList = false;
      state.loadLOVListError = '';
    },
  },
});

export const {
  loadLOVListStart,
  loadLOVListCompleted,
  loadLOVListFailed,
  clearLOVList,
  loadLocationLOVListStart,
  loadLocationLOVListCompleted,
  loadLocationLOVListFailed,
  clearLocationLOVList,
  loadVendorLOVListStart,
  loadVendorLOVListCompleted,
  loadVendorLOVListFailed,
  clearVendorLOVList,
  loadLocationInfoStart,
  loadLocationInfoCompleted,
  loadLocationInfoFailed,
  // loadVendorInfoStart,
  // loadVendorInfoCompleted,
  // loadVendorInfoFailed,
} = lov.actions;

export default lov.reducer;

export const retrieveLOVList = (lovType, value) => async (dispatch) => {
  dispatch(loadLOVListStart);
  api.lov.getLOVList(lovType).then((res) => {
    const { results, error, config } = res;
    if (results) {
      dispatch(
        loadLOVListCompleted({
          lovList: results,
          config: config,
          prevValue: value,
        })
      );
    } else {
      dispatch(loadLOVListFailed({ error: error || 'API_ERROR' }));
    }
  });
};

export const retrieveLocationLOVList = (lovType) => async (dispatch) => {
  dispatch(loadLocationLOVListStart());
  api.lov.getLocationLOVList(lovType).then((res) => {
    const { results, error } = res;
    if (results) {
      dispatch(
        loadLocationLOVListCompleted({
          locationLOVList: results,
          direction: lovType,
        })
      );
    } else {
      dispatch(loadLocationLOVListFailed({ error: error || 'API_ERROR' }));
    }
  });
};

export const retrieveLocationInfo = (lovName) => async (dispatch) => {
  dispatch(loadLocationInfoStart());
  api.lov.getLocationInfo(lovName).then((res) => {
    const { results, error } = res;
    if (results) {
      dispatch(
        loadLocationInfoCompleted({
          locationInfo: results,
        })
      );
    } else {
      dispatch(loadLocationInfoFailed({ error: error || 'API_ERROR' }));
    }
  });
};

export const retrieveVendorLOVList = () => async (dispatch) => {
  dispatch(loadVendorLOVListStart());
  api.lov.getVendorLOVList().then((res) => {
    const { results, error } = res;
    if (results) {
      dispatch(
        loadVendorLOVListCompleted({
          vendorLOVList: results,
        })
      );
    } else {
      dispatch(loadVendorLOVListFailed({ error: error || 'API_ERROR' }));
    }
  });
};

// export const retrieveVendorInfo = (lovName) => async (dispatch) => {
//   dispatch(loadVendorInfoStart());
//   api.lov.getVendorInfo(lovName).then((res) => {
//     const { results, error } = res;
//     if (results) {
//       dispatch(
//         loadVendorInfoCompleted({
//           vendorInfo: results,
//         })
//       );
//     } else {
//       dispatch(loadVendorInfoFailed({ error: error || 'API_ERROR' }));
//     }
//   });
// };
