import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './MainLayout.css';
import Header from 'components/layouts/Header/Header';
import Sidebar from 'components/layouts/Sidebar/Sidebar';
import OrdersScreen from 'components/Homepage/OrdersScreen';
import OriginatorScreen from 'components/OriginatorHome/OriginatorScreen';
import AdminScreen from 'components/Admin/AdminScreen';
import { NewOrderScreen } from 'components/New Orders/NewOrderScreen';
import LoginBox from 'components/LoginBox';
import PrivateRoute from 'components/Common/PrivateRoute';
import useSessionAuth from 'hooks/useSessionAuth';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

export default function MainLayout(props) {
  const { isAuthenticated } = useSelector((state) => state.authentication);
  const { checkingSession, validateCurrentSession } = useSessionAuth();
  const { userRoles } = useSelector((state) => state.currentUser);
  const [isOriginator, setIsOriginator] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isModerator, setIsModerator] = useState(false);
  const [isHedgeDesk, setIsHedgeDesk] = useState(false);
  const [version, setVersion] = useState(props.version);

  useEffect(() => {
    setIsAdmin(Array.from(userRoles).includes('Administrator'));
    setIsModerator(Array.from(userRoles).includes('Moderator'));
    setIsHedgeDesk(Array.from(userRoles).includes('HedgeDesk'));
    setIsOriginator(Array.from(userRoles).includes('Originator'));
  }, [userRoles]);

  useEffect(() => {
    validateCurrentSession();
  }, []);

  useEffect(() => {
    const MINUTE_MS = 300000;
    const interval = setInterval(() => {
      validateCurrentSession();
    }, MINUTE_MS);

    return () => clearInterval(interval); // Unmount function to avoid memory leaks.
  }, []);

  return (
    <Router>
      {isAuthenticated && (
        <>
          <Header />
          <Sidebar version={version} />
        </>
      )}
      <div className={isAuthenticated ? 'mainActionWindow' : 'loginWindow'}>
        <div className="actionwindow">
          {!checkingSession ? (
            <Switch>
              <Route path="/login">
                <LoginBox />
              </Route>
              <PrivateRoute isAuthenticated={isAuthenticated} path="/admin">
                <AdminScreen />
              </PrivateRoute>
              <PrivateRoute isAuthenticated={isAuthenticated} path="/neworder">
                <NewOrderScreen />
              </PrivateRoute>
              <PrivateRoute isAuthenticated={isAuthenticated} path="/">
                {isOriginator && !isAdmin ? (
                  <OriginatorScreen />
                ) : (
                  <OrdersScreen />
                )}
              </PrivateRoute>
              {/* <PrivateRoute isAuthenticated={isAuthenticated} path="/">
              <OriginatorScreen />
              </PrivateRoute> */}
            </Switch>
          ) : (
            <Switch>
              <Route path="/login">
                <LoginBox />
              </Route>
              <PrivateRoute isAuthenticated={isAuthenticated} path="/admin">
                <AdminScreen />
              </PrivateRoute>
              <PrivateRoute isAuthenticated={isAuthenticated} path="/neworder">
                <NewOrderScreen />
              </PrivateRoute>
              <PrivateRoute isAuthenticated={isAuthenticated} path="/">
                {isOriginator && !isAdmin ? (
                  <OriginatorScreen />
                ) : (
                  <OrdersScreen />
                )}
              </PrivateRoute>
            </Switch>
          )}
        </div>
      </div>
    </Router>
  );
}
