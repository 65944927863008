import React, { useState } from 'react';
import LoadingIcons from 'react-loading-icons';
import { useDispatch } from 'react-redux';
import AllFillsList from './Container/AllFillsList';
import EditFill from '../FillOrders/EditFill';
import OrderDetails from './Container/OrderDetails';
import { clearSelectedOrder, retrieveOrderList } from 'store/modules/order';

export default function AllFillsWindow(props) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [currentMode, setCurrentMode] = useState('list');

  const handleModeChange = (mode) => {
    setCurrentMode(mode);
  };

  const changeToList = () => {
    dispatch(clearSelectedOrder());
    // dispatch(retrieveOrderList(true, '', ''));
  };

  const handleCurrentContainer = () => {
    switch (currentMode) {
      case 'list':
        return <AllFillsList onModeChange={handleModeChange} />;

      case 'edit':
        return (
          <EditFill onModeChange={handleModeChange} returnBack={changeToList} />
        );

      case 'detail':
        return (
          <OrderDetails
            onModeChange={handleModeChange}
            returnBack={changeToList}
          />
        );

      default:
        return <div>{"I'm sorry an error has occured"}</div>;
    }
  };

  return isLoading ? (
    <div className="location-loading-icon">
      <LoadingIcons.Oval stroke="#006730" />
      <br /> <br />
    </div>
  ) : (
    <div className="all-orders-form">
      <div className="handle-container" style={{ backgroundColor: 'white' }}>
        {handleCurrentContainer()}
      </div>
    </div>
  );
}
