import React, { useState } from 'react';
import { BiXCircle } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import LoadingIcons from 'react-loading-icons';
import { Divider } from 'antd';
import { useEffect } from 'react';
import * as moment from 'moment';
import { retrieveLocationDetails } from 'store/modules/location';
import { retrieveVendorDetails } from 'store/modules/vendor';

export default function OpenOrderDetails(props) {
  const dispatch = useDispatch();
  const { loadingOrderDetails, selectedOrder } = useSelector(
    (state) => state.orders
  );
  const { selectedLocation } = useSelector((state) => state.locations);
  const { selectedVendor } = useSelector((state) => state.vendors);
  const [newOrderDetails, setNewOrderDetails] = useState();

  useEffect(() => {
    if (newOrderDetails !== undefined) {
      newOrderDetails.locationName = selectedLocation.locationName;
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (newOrderDetails !== undefined) {
      selectedVendor.vendorName === undefined
        ? ''
        : (newOrderDetails.vendorName = selectedVendor.vendorName);
    }
  }, [selectedVendor]);

  useEffect(() => {
    if (selectedOrder) {
      dispatch(retrieveLocationDetails(selectedOrder));
      dispatch(retrieveVendorDetails(selectedOrder));
      const newStartDate = moment(selectedOrder.deliveryStart).toDate();
      const newEndDate = moment(selectedOrder.deliveryEnd).toDate();
      const newContractType =
        selectedOrder.contractType.charAt(0).toUpperCase() +
        selectedOrder.contractType.slice(1);
      const newSelectedOrder = { ...selectedOrder };
      newSelectedOrder.deliveryStart = newStartDate;
      newSelectedOrder.deliveryEnd = newEndDate;
      newSelectedOrder.contractType = newContractType;
      newSelectedOrder.commoditySymbol =
        newSelectedOrder.commoditySymbol.replace(/\s/g, '');
      newSelectedOrder.accountNameAndNumber = selectedOrder.exchangeAccount;
      if (selectedOrder.optionMonth === 'F') {
        newSelectedOrder.futuresMonth = selectedOrder.optionMonth + ' - Jan';
      } else if (selectedOrder.optionMonth === 'H') {
        newSelectedOrder.futuresMonth = selectedOrder.optionMonth + ' - March';
      } else if (selectedOrder.optionMonth === 'K') {
        newSelectedOrder.futuresMonth = selectedOrder.optionMonth + ' - May';
      } else if (selectedOrder.optionMonth === 'N') {
        newSelectedOrder.futuresMonth = selectedOrder.optionMonth + ' - Jul';
      } else if (selectedOrder.optionMonth === 'Q') {
        newSelectedOrder.futuresMonth = selectedOrder.optionMonth + ' - Aug';
      } else if (selectedOrder.optionMonth === 'U') {
        newSelectedOrder.futuresMonth = selectedOrder.optionMonth + ' - Sep';
      } else if (selectedOrder.optionMonth === 'Z') {
        newSelectedOrder.futuresMonth = selectedOrder.optionMonth + ' - Dec';
      } else {
        newSelectedOrder.futuresMonth = selectedOrder.optionMonth + ' - Oct';
      }
      newSelectedOrder.contractType =
        selectedOrder.contractType.charAt(0).toUpperCase() +
        selectedOrder.contractType.slice(1);
      setNewOrderDetails(newSelectedOrder);
    }
  }, [selectedOrder]);

  const returnHome = () => {
    props.returnBack();
    props.onModeChange('list');
  };

  const updateOrder = () => {
    newOrderDetails.vendorName =
      newOrderDetails.vendorName + '-' + newOrderDetails.vendorID;
    props.onModeChange('form');
    props.isUpdateMode('edit', newOrderDetails);
  };

  if (
    selectedOrder !== null &&
    loadingOrderDetails === false &&
    selectedVendor !== null
  ) {
    return (
      <div className="order-detail-container">
        <div className="return-button" id="return-button">
          <h2>Order Details</h2>
          <div className="detail-buttons">
            <button className="update-order-btn" onClick={updateOrder}>
              Update Order
            </button>
            <BiXCircle
              className="close-button"
              size={40}
              onClick={returnHome}
            />
          </div>
        </div>
        <Divider style={{ margin: '10px' }} />
        <div className="market-detail-row">
          {/* First Column */}
          <div className="market-detail-column">
            <div className="data-item">
              <label className="order-detail-label">Order ID:</label>
              {' ' + selectedOrder.id}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Region:</label>
              {' ' + selectedOrder.section}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Order Group:</label>
              {' ' + selectedOrder.group}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Submit Date:</label>
              {' ' + selectedOrder.submitDate}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Order Type:</label>
              {' ' + selectedOrder.orderType}
            </div>
            <div className="data-item">
              <label className="order-detail-label">User Name:</label>
              {' ' + selectedOrder.userName}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Contract Type:</label>
              {' ' + selectedOrder.contractType}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Contract Count:</label>
              {' ' + selectedOrder.contractCount}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Board Period:</label>
              {' ' + selectedOrder.boardPeriod}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Quantity:</label>
              {' ' + selectedOrder.quantity}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Commodity:</label>
              {' ' + selectedOrder.commodity}
            </div>
            <div className="data-item">
              <label className="order-detail-label">UOM:</label>
              {' ' + selectedOrder.uom}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Delivery Location:</label>
              {' ' + selectedOrder.locationID}
            </div>
            <div className="data-item">
              <label className="order-detail-label">wO Limit Price:</label>
              {' ' + selectedOrder.wOlimitPrice}
            </div>
            <div className="data-item">
              <label className="order-detail-label">wO Stop Price:</label>
              {' ' + selectedOrder.wOstopPrice}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Delivery Start Date:</label>
              {' ' + selectedOrder.deliveryStart}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Delivery End Date:</label>
              {' ' + selectedOrder.deliveryEnd}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Last Audit Date:</label>
              {' ' + selectedOrder.auditWHEN}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Legacy ID:</label>
              {' ' + selectedOrder.legacyID}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Audit By:</label>
              {' ' + selectedOrder.auditWHO}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Vendor/Farmer:</label>
              {' ' + selectedVendor.vendorName + ' - ' + selectedOrder.vendorID}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Item Type:</label>
              {' ' + selectedOrder.itemType}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Verified?:</label>
              {' ' + selectedOrder.verified}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Filled?:</label>
              {' ' + selectedOrder.filled}
            </div>
          </div>
          {/* /Second Column */}
          <div className="market-detail-column">
            <div className="data-item">
              <label className="order-detail-label">Locked?:</label>
              {' ' + selectedOrder.locked}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Option Month:</label>
              {' ' + selectedOrder.optionMonth}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Option Year:</label>
              {' ' + selectedOrder.optionYear}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Forecast Type:</label>
              {' ' + selectedOrder.forecastType}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Exchange Type:</label>
              {' ' + selectedOrder.exchangeType}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Shipment:</label>
              {' ' + selectedOrder.shipment}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Note:</label>
              {' ' + selectedOrder.note}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Alt Location:</label>
              {' ' + selectedOrder.altLocation}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Alt Vendor: :</label>
              {' ' + selectedOrder.altVendor}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Option Type:</label>
              {' ' + selectedOrder.optionType}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Option Action:</label>
              {' ' + selectedOrder.optionAction}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Strike Price:</label>
              {' ' + selectedOrder.strikePrice}
            </div>
            <div className="data-item">
              <label className="order-detail-label">wO Type:</label>
              {' ' + selectedOrder.wOtype}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Limit Price:</label>
              {' ' + selectedOrder.limitPrice}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Exchange Price:</label>
              {' ' + selectedOrder.exchangePrice}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Exchange Account:</label>
              {' ' + selectedOrder.exchangeAccount}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Basis Price:</label>
              {' ' + selectedOrder.basisPrice}
            </div>
            <div className="data-item">
              <label className="order-detail-label">FoB Price:</label>
              {' ' + selectedOrder.foBprice}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Contract Number:</label>
              {' ' + selectedOrder.contractNumber}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Ship Mode:</label>
              {' ' + selectedOrder.shipMode}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Alt Contract Type:</label>
              {' ' + selectedOrder.altContractType}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Fill Date:</label>
              {' ' + selectedOrder.fillDate}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Fill Price:</label>
              {' ' + selectedOrder.fillPrice}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <LoadingIcons.Oval stroke="#006730" />
      </div>
    );
  }
}
