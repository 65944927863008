import React from 'react';
import './Accordion.css';

function ArrowUp(props) {
  return (
    <svg
      className={props.className}
      height={props.height}
      width={props.width}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill="currentColor"
        d="M8 256C8 119 119 8 256 8s248 111 248 248-111 248-248 248S8 393 8 256zm231-113.9L103.5 277.6c-9.4 9.4-9.4 24.6 0 33.9l17 17c9.4 9.4 24.6 9.4 33.9 0L256 226.9l101.6 101.6c9.4 9.4 24.6 9.4 33.9 0l17-17c9.4-9.4 9.4-24.6 0-33.9L273 142.1c-9.4-9.4-24.6-9.4-34 0z"
      ></path>
    </svg>
  );
}

function ArrowDown(props) {
  return (
    <svg
      className={props.className}
      height={props.height}
      width={props.width}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill="currentColor"
        d="M504 256c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zM273 369.9l135.5-135.5c9.4-9.4 9.4-24.6 0-33.9l-17-17c-9.4-9.4-24.6-9.4-33.9 0L256 285.1 154.4 183.5c-9.4-9.4-24.6-9.4-33.9 0l-17 17c-9.4 9.4-9.4 24.6 0 33.9L239 369.9c9.4 9.4 24.6 9.4 34 0z"
      ></path>
    </svg>
  );
}

export class Accordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: this.props.data[0],
    };
  }

  toggle = (i) => {
    //If already in the state min
    if (this.state.selected == i) {
      this.setState({ selected: '' });
    } else {
      this.setState({ selected: i });
    }
  };

  render() {
    return (
      <div className="accordiontop">
        <div className="accordion">
          {this.props.data.map((i, index) => (
            <div className="item" key={index}>
              <div className="title" onClick={() => this.toggle(i)}>
                <h2 id="accordTitle">{i}</h2>
                <span id="arrowdown">
                  {' '}
                  {this.state.selected == i ? (
                    <ArrowDown width={35} color={'#FFFFFF'} />
                  ) : (
                    <ArrowUp width={35} />
                  )}{' '}
                </span>
              </div>
              {this.state.selected === i && (
                <div className="content show">
                  {' '}
                  {this.props.forms[this.props.data.indexOf(i)]}{' '}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Accordion;
