import { createSlice } from '@reduxjs/toolkit';
import api from 'services/api';
import sessionCookies from 'utils/cookies/sessionCookies';
import { initializeAPIwithAuthentication } from 'services/api/core';
import { retrieveUserRoles } from 'store/modules/currentUser';
import jwt from 'jwt-decode';

const initialState = {
  isAuthenticated: false,
  isLoggingIn: false,
  isLoggingOut: false,
  isLoggedOut: false,
  isLoggedIn: false,
  sessionToken: '',
  sessionUser: '',
  authenticationFailed: false,
};

export const loginSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    loginUserStart: (state) => {
      state.isLoggingIn = true;
      state.isLoggedIn = false;
    },
    loginUserComplete: (state) => {
      state.isLoggingIn = false;
      state.isLoggedIn = true;
      state.isAuthenticated = true;
      state.sessionToken = sessionCookies.getSessionCookies().token;
      const decodedToken = jwt(state.sessionToken);
      decodedToken.name =
        decodedToken[
          'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'
        ];
      delete decodedToken[
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'
      ];
      state.sessionUser = decodedToken.name;
    },
    loginUserFailed: (state) => {
      state.isLoggingIn = false;
      state.isLoggedIn = false;
      state.isAuthenticated = false;
      state.authenticationFailed = true;
    },
    clearAuthorizationState: (state) => {
      state.isAuthenticated = false;
      state.isLoggingIn = false;
      state.isLoggingOut = false;
      state.isLoggedOut = false;
      state.isLoggedIn = false;
      state.sessionToken = '';
      state.sessionUser = '';
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  loginUserStart,
  loginUserComplete,
  loginUserFailed,
  clearAuthorizationState,
} = loginSlice.actions;

export default loginSlice.reducer;

//Async Functions
export const authorizeUser = (userName, password) => async (dispatch) => {
  dispatch(loginUserStart());
  const results = await api.authentication.loginCreds({ userName, password });
  const { data, status } = results;
  if (status === 200) {
    sessionCookies.updateSessionCookies({ token: data.payload.token });
    initializeAPIwithAuthentication(data.payload.token);
    await dispatch(retrieveUserRoles(userName));
    dispatch(loginUserComplete());
  } else {
    dispatch(loginUserFailed());
  }
};

export const logoutUser = () => async (dispatch) => {
  dispatch(clearAuthorizationState());
  sessionCookies.clearSessionCookies();
};
