import React, { useEffect, useState } from 'react';
import api from 'services/api';
import '../NotificationStyle.css';

export default function NotificationPage({
  onModeChange,
  userList,
  onLocationChange,
  onAddNotificationUSer,
  onRemoveNotificationUSer,
  notifLocation,
  notificationUsersByLocation,
  locationID,
}) {
  const [addSelectedUSer, setAddSelectedUSer] = useState('');
  const [isAddUser, setIsAddUser] = useState(false);

  const selectNotifLocation = (e) => {
    e.preventDefault();
    const currentVal = e.target.value;
    onLocationChange(currentVal);
  };

  const selectNotifUser = (e) => {
    e.preventDefault();
    const selectedUser = e.target.value;
    setAddSelectedUSer(selectedUser);
  };

  const addUserToList = (e) => {
    e.preventDefault();
    const notificationDetails = {
      userName: addSelectedUSer,
      locationID,
      active: true,
    };
    onAddNotificationUSer(notificationDetails);
    setIsAddUser(true);
  };

  const removeUserFromList = (e) => {
    e.preventDefault();
    const removeUserName = e.target.value;
    const updateNotificationDetails = {
      userName: removeUserName,
      locationID,
      active: false,
    };
    onRemoveNotificationUSer(updateNotificationDetails);
  };

  const buildNotifLocation = () => {
    return notifLocation.map((notifLocation) => (
      <option value={notifLocation.locationID} key={notifLocation.locationID}>
        {notifLocation.locationName}
      </option>
    ));
  };

  const buildNotifUsers = () => {
    return userList.map((notifUser) => (
      <option
        value={notifUser.userName}
        key={notifLocation.locationID}
      >{`${notifUser.userName} (${notifUser.fullName})`}</option>
    ));
  };

  const buildNotifUsersList = () => {
    return notificationUsersByLocation
      .filter((user) => user.active)
      .map((notifUserByLocation, index) => (
        <div className="userbylocation-list" key={index}>
          <div className="notification-user-list">
            <button
              key={index}
              className="remove-user-btn"
              onClick={removeUserFromList}
              value={notifUserByLocation.userName}
            >
              remove
            </button>
            <div className="notification-name">{`${notifUserByLocation.fullName} (${notifUserByLocation.userName})`}</div>
          </div>
        </div>
      ));
  };

  return (
    <div className="notification-page">
      <div className="notification-location">
        <h5>Select a Location to manage notifications:</h5>
        <select
          className="location-options"
          value={locationID}
          placeholder="location"
          onChange={selectNotifLocation}
        >
          <option value="">Select a Location...</option>
          {buildNotifLocation()}
        </select>
      </div>{' '}
      <br />
      <div
        className={!locationID ? 'no-location-selected' : 'notification-user'}
      >
        <h5>Select a User:</h5>
        <select
          className="user-options"
          value={addSelectedUSer}
          placeholder="location"
          onChange={selectNotifUser}
        >
          <option value="">Select a User...</option>
          {buildNotifUsers()}
        </select>
        <button
          className="addtoList-btn"
          onClick={addUserToList}
          disabled={!addSelectedUSer}
        >
          Add to List
        </button>
      </div>
      <br />
      <br />
      <div
        className={!locationID ? 'no-location-selected' : 'notification-user'}
      >
        <h5>Users that will be notified for orders for this location</h5>
        <div className="notification-user-list-holder">
          {buildNotifUsersList()}
        </div>
      </div>
      <br />
      <br />
    </div>
  );
}
