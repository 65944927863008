import React, { useState, useEffect } from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearSelectedMarketOrder,
  submitFillMarketOrder,
} from 'store/modules/order';
import '../OrdersStyle.css';
import { FormInput } from '../../Common/Forms/form-components';
import LoadingIcons from 'react-loading-icons';

export default function FillMarketOrders() {
  const { selectedMarketOrder, submittedMarketFill, loadedFillData } =
    useSelector((state) => state.orders);
  const dispatch = useDispatch();
  const [fillOrderDetails, setFillOrderDetails] = useState([
    selectedMarketOrder,
  ]);

  const [multiplier, setMultiplier] = useState();

  // const pick = (obj, arr) =>
  //   arr.reduce(
  //     (acc, record) => (record in obj && (acc[record] = obj[record]), acc),
  //     {}
  //   );

  const cancelFill = () => {
    dispatch(clearSelectedMarketOrder());
  };
  useEffect(() => {
    if (selectedMarketOrder.commodity === 'SBM') {
      setMultiplier(100);
    } else if (
      ['Corn', 'Milling Wheat', 'Sorghum', 'Soybeans', 'Wheat'].includes(
        selectedMarketOrder.commodity
      ) &&
      selectedMarketOrder.itemType.toLowerCase() === 'grain'
    ) {
      setMultiplier(5000);
    } else {
      setMultiplier(36);
    }
  }, [selectedMarketOrder]);

  useEffect(() => {
    setFillOrderDetails([selectedMarketOrder]);
  }, [selectedMarketOrder]);

  useEffect(() => {
    if (submittedMarketFill === true) {
      cancelFill();
    }
  }, [submittedMarketFill]);

  const fillOrder = (dataItem) => {
    dataItem.orderId = fillOrderDetails[0].id;
    const fillData = dataItem;
    // delete Object.assign(fillData, {
    //   ['contractCount']: fillData['fillBushels'],
    // })['fillBushels'];

    // const newFillData = pick(fillData, [
    //   'orderId',
    //   'fillPrice',
    //   'quantity',
    //   'contractCount',
    // ]);
    dispatch(submitFillMarketOrder(fillData));
  };

  return !loadedFillData ? (
    <LoadingIcons.Oval stroke="#006730" />
  ) : (
    <Form
      onSubmit={fillOrder}
      render={(formRenderProps) => (
        <FormElement>
          <div className="fill-order-container">
            <div className="fill-order-tbl">
              <div className="fill-order-inputs">
                <div className="fill-price-input">
                  <Field
                    id={'fillPrice'}
                    name={'fillPrice'}
                    label={'($) Fill Price'}
                    component={FormInput}
                  />
                </div>
                <div className="fill-qty-input">
                  <Field
                    id={'contractCount'}
                    name={'contractCount'}
                    label={'Fill Quantity'}
                    component={FormInput}
                    onChange={(e) => {
                      const quantityValue = e.target.value;
                      formRenderProps.onChange('quantity', {
                        value: quantityValue * multiplier,
                      });
                    }}
                  />
                </div>
                <div className="fill-bushels-input">
                  <Field
                    id={'quantity'}
                    name={'quantity'}
                    label={'# of Bushels'}
                    component={FormInput}
                    onChange={(e) => {
                      const quantityValue = e.target.value;
                      formRenderProps.onChange('contractCount', {
                        value: Math.round(quantityValue / multiplier),
                      });
                    }}
                  />
                </div>
                <div className="bushel-count">
                  <span>
                    Orders Bushel Count <br /> {selectedMarketOrder.quantity}
                  </span>
                </div>
              </div>
              <br />
              <Grid data={fillOrderDetails} resizable={true}>
                <Column field="submitDateOnly" title="Sub. Date" />
                <Column field="submitTimeOnly" title="Sub. Time" />
                <Column field="orderType" title="Trade Type" />
                <Column field="userFullName" title="Trader" />
                <Column field="contractType" title="Buy/Sell" />
                <Column field="contractCount" title="# Contracts" />
                <Column field="boardPeriod" title="Period" />
                <Column field="quantity" title="Quantity" />
                <Column field="locationID" title="Dest" />
                <Column field="commodity" title="Commodity" />
                <Column field="uom" title="UOM" />
                <Column field="wOstopPrice" title="W.O Stop Price" />
                <Column field="wOlimitPrice" title="W.O Limit Price" />
              </Grid>
              <div className="k-form-buttons">
                <Button
                  themeColor={'primary'}
                  type={'submit'}
                  disabled={!formRenderProps.allowSubmit}
                >
                  Fill Order
                </Button>
                <Button onClick={cancelFill}>Cancel</Button>
                <Button onClick={formRenderProps.onFormReset}>Clear</Button>
              </div>
            </div>
          </div>
        </FormElement>
      )}
    />
  );
}
