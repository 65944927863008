import React, { useState } from 'react';
import NewUserWindowContainer from './Container/NewUserContainer';
import UserListContainer from './Container/UserListContainer';
import UserDetailsContainer from './Container/UserDetailsContainer';
import { useDispatch, useSelector } from 'react-redux';
import { clearSelectedUser, retrieveUserList } from 'store/modules/users';

export default function UserWindow() {
  const dispatch = useDispatch();
  const { selectedUser } = useSelector((state) => state.users);
  const [formData, setFormData] = useState([]);
  const [currentMode, setCurrentMode] = useState('list');

  const handleModeChange = (mode) => {
    setCurrentMode(mode);
  };

  const handleValueUpdates = (childData) => {
    setFormData(childData);
  };

  const changeToList = () => {
    dispatch(clearSelectedUser());
    dispatch(retrieveUserList());
  };

  const handleCurrentContainer = () => {
    switch (currentMode) {
      case 'list':
        return <UserListContainer onModeChange={handleModeChange} />;

      case 'form':
        return (
          <NewUserWindowContainer
            onModeChange={handleModeChange}
            isUpdateMode={selectedUser !== null}
            valuesUpdate={handleValueUpdates}
            returnBack={changeToList}
          />
        );

      case 'detail':
        return (
          <UserDetailsContainer
            onModeChange={handleModeChange}
            returnBack={changeToList}
          />
        );

      default:
        return <div>{"I'm sorry an error has occured"}</div>;
    }
  };

  return (
    <div className="user-form">
      <div className="current-container">{handleCurrentContainer()}</div>
    </div>
  );
}
