import api from '../../core/index';

const location = {
  getLocations: () => {
    const locationList = 'Location/GetList';
    return api.client.get(locationList).then(
      (result) => {
        const { status, data } = result;
        if (status === 200) {
          return { results: data.payload };
        } else {
          return { error: 'Unknown Error' };
        }
      },
      (error) => {
        return { error };
      }
    );
  },
  getLocationDetail: (locationID, locationName) => {
    const locationDetail = `Location/GetLocation?locationID=${locationID}&locationName=${locationName}`;
    return api.client.get(locationDetail).then(
      (result) => {
        const { status, data } = result;
        if (status === 200 && data.status !== 'ERROR') {
          return { results: data.payload[0] };
        } else {
          return { error: 'Unknown Error' };
        }
      },
      (error) => {
        return { error };
      }
    );
  },
  createLocation: (locationDetails) => {
    const createUrl = 'Location/Create';
    return api.client
      .post(createUrl, {
        ...locationDetails,
        deviceName: 'TestPC',
      })
      .then(
        (result) => {
          const { status, data } = result;
          if (status === 200 || data.status === 'OK') {
            return { results: data, status: 'Success' };
          } else {
            return { error: 'Unknown Error' };
          }
        },
        (error) => {
          return { error };
        }
      );
  },

  updateLocation: (locationDetails) => {
    const updateUrl = 'Location/Update';
    return api.client
      .put(updateUrl, {
        ...locationDetails,
        deviceName: 'TestPC',
      })
      .then(
        (result) => {
          const { status, data } = result;
          if (status === 200 || data.status === 'OK') {
            return { results: data, status: 'Success' };
          } else {
            return { error: 'Unknown Error' };
          }
        },
        (error) => {
          return { error };
        }
      );
  },
};

export default location;
