import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveOrderList } from 'store/modules/order';
import { ColumnMenu } from '../../../Common/columnMenu';
import LoadingIcons from 'react-loading-icons';
import { process } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Input } from '@progress/kendo-react-inputs';
import * as moment from 'moment';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Loader } from '@progress/kendo-react-indicators';
import { styles } from './styles';

export default function HedgeFillsListMobile(props) {
  const dispatch = useDispatch();
  const { allFillsList, loadedAllFills } = useSelector((state) => state.orders);

  const createDataState = (dataState) => {
    return {
      result: process(allFillsList.slice(0), dataState),
      sort: dataState.sort,
      dataState: dataState,
    };
  };

  const initialDataState = createDataState({
    sort: [
      {
        field: 'fillDate',
        dir: 'desc',
      },
    ],
    take: 15,
    skip: 0,
  });
  const [result, setResult] = useState(initialDataState.result);
  const [dataState, setDataState] = useState(initialDataState.dataState);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const d = new Date();
    d.setMonth(d.getMonth() - 24);
    const date = moment(d).format('MM/DD/YYYY');
    dispatch(retrieveOrderList(true, date, ''));
  }, []);

  useEffect(() => {
    if (loadedAllFills) {
      setResult(initialDataState.result);
    }
  }, [allFillsList]);

  const dataStateChange = (e) => {
    const updatedState = createDataState(e.dataState);
    setResult(updatedState.result);
    setDataState(updatedState.dataState);
  };

  const onSearch = (event) => {
    const {
      target: { value },
    } = event;

    const newOrderList = allFillsList.filter(
      (order) =>
        order.boardPeriod.toLowerCase().includes(value.toLowerCase()) ||
        order.commodity.toLowerCase().includes(value.toLowerCase()) ||
        order.contractCount
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()) ||
        order.contractType.toLowerCase().includes(value.toLowerCase()) ||
        order.submitDateOnly
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()) ||
        order.submitTimeOnly
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()) ||
        order.group.toLowerCase().includes(value.toLowerCase()) ||
        order.id.toString().toLowerCase().includes(value.toLowerCase()) ||
        order.locationID.toLowerCase().includes(value.toLowerCase()) ||
        order.orderType.toLowerCase().includes(value.toLowerCase()) ||
        order.quantity.toString().toLowerCase().includes(value.toLowerCase()) ||
        order.section.toLowerCase().includes(value.toLowerCase()) ||
        order.deliveryEndDateOnly
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()) ||
        order.deliveryEndTimeOnly
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()) ||
        order.uom.toLowerCase().includes(value.toLowerCase()) ||
        order.userFullName.toLowerCase().includes(value.toLowerCase()) ||
        order.userName.toLowerCase().includes(value.toLowerCase()) ||
        order.wOlimitPrice
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()) ||
        order.wOstopPrice.toString().toLowerCase().includes(value.toLowerCase())
    );
    setResult(newOrderList);
  };

  const _export = useRef(null);

  const handleExport = () => {
    setLoading(false);
  };

  const excelExport = () => {
    delete dataState.take;
    const exportList = process(allFillsList.slice(0), dataState);
    if (!_export.current) {
      return;
    }
    setLoading(true);
    _export.current.save(exportList);
  };

  return !loadedAllFills ? (
    <LoadingIcons.Oval stroke="#006730" />
  ) : (
    <div id="adminwindow">
      <div className="all-fill-orders-grid">
        <div className="toolbar-container">
          <div className="search-box">
            <Input
              onChange={onSearch}
              label="Enter Text Here"
              className="search-input"
            />
          </div>
          <div className="export-btn">
            <button
              title="Export Excel"
              className="export-open k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
              onClick={excelExport}
              // style={{ position: 'absolute', right: '10px', top: '5px' }}
            >
              Export Filled Orders
            </button>
          </div>
        </div>
        <ExcelExport
          ref={_export}
          fileName="Filled Orders.xlsx"
          onExportComplete={handleExport}
        >
          <Grid
            data={result}
            {...dataState}
            onDataStateChange={dataStateChange}
            pageable={true}
            resizable={true}
            sortable={{
              allowUnsort: true,
              mode: 'multiple',
            }}
          >
            <Column
              field="id"
              title="Order Trans #"
              filter={'text'}
              columnMenu={ColumnMenu}
            />
            <Column
              field="submitDateOnly"
              title="Sub. Date"
              filter={'text'}
              columnMenu={ColumnMenu}
            />
            {/* <Column
              field="userFullName"
              title="Trader"
              filter={'text'}
              columnMenu={ColumnMenu}
            /> */}
            <Column
              field="vendorName"
              title="Farmer/Vendor"
              filter={'text'}
              columnMenu={ColumnMenu}
            />
            <Column
              field="contractCount"
              title="# Contracts"
              filter={'text'}
              columnMenu={ColumnMenu}
            />
            <Column
              field="boardPeriod"
              title="Board Period"
              filter={'text'}
              columnMenu={ColumnMenu}
            />
            <Column
              field="commodity"
              title="Commodity"
              filter={'text'}
              columnMenu={ColumnMenu}
            />
          </Grid>
        </ExcelExport>
        <style>{styles}</style>
        {loading && (
          <Loader
            size={'large'}
            style={{
              position: 'absolute',
              top: '30%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
            }}
            type={'infinite-spinner'}
          />
        )}
      </div>
    </div>
  );
}
