import React from 'react';
import { BiXCircle } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import LoadingIcons from 'react-loading-icons';
import PropTypes from 'prop-types';

export default function LocationDetailContainer(props) {
  const { selectedLocation, loadedLocations, loadingLocations } = useSelector(
    (state) => state.locations
  );

  const returnHome = () => {
    props.returnBack();
    props.onModeChange('list');
  };

  const updateLocation = () => {
    props.onModeChange('form');
  };

  if (loadingLocations) {
    <div>
      <LoadingIcons.Oval stroke="#006730" />
    </div>;
  }
  if (selectedLocation) {
    return (
      <div className="container">
        <div className="return-button" id="return-button">
          <h2>Location Details</h2>
          <BiXCircle className="close-button" size={40} onClick={returnHome} />
        </div>
        <br />
        <div>
          <label className="location-detail-label">Location ID:</label>{' '}
          {selectedLocation.locationID}
        </div>
        <br />
        <div>
          <label className="location-detail-label">Location Name:</label>{' '}
          {selectedLocation.locationName}
        </div>
        <br />
        <div>
          <label className="location-detail-label">Updated By:</label>{' '}
          {selectedLocation.auditWHO}
        </div>
        <br />
        <div>
          <label className="location-detail-label">Updated Date:</label>{' '}
          {selectedLocation.auditWHEN}
        </div>
        <br />
        <div>
          <label className="location-detail-label">Assignment:</label>{' '}
          {selectedLocation.assignment}
        </div>
        <br />
        <div>
          <label className="location-detail-label">Direction:</label>{' '}
          {selectedLocation.direction}
        </div>
        <br />
        <div>
          <label className="location-detail-label">Location Type:</label>{' '}
          {selectedLocation.locationType}
        </div>
        <br />
        <div>
          <label className="location-detail-label">Full Address:</label>{' '}
          {selectedLocation.fullAddress}
        </div>
        <br />
        <div>
          <label className="location-detail-label">Status:</label>{' '}
          {selectedLocation.active ? 'Active' : 'Inactive'}
        </div>
        <div className="button-holder">
          <button className="middle-button" onClick={updateLocation}>
            Update Location Details
          </button>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

LocationDetailContainer.propTypes = {
  returnBack: PropTypes.func,
  onModeChange: PropTypes.func,
};
