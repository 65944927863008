export const tradeTypeFormModel = [
  {
    label: 'Sell',
    value: 'Sell',
  },
  {
    label: 'Buy',
    value: 'Buy',
  },
  {
    label: 'Exchange',
    value: 'Exchange',
  },
  {
    label: 'Basis (SGC)',
    value: 'Basis',
  },
];

export const regionFormModel = [
  {
    label: 'East',
    value: 'East',
  },
  {
    label: 'West',
    value: 'West',
  },
];

export const orderGroupModel = [
  {
    label: 'Feed Mill',
    value: 'Feed Mill',
  },
  {
    label: 'Elevator',
    value: 'Elevator',
  },
  {
    label: 'Spot',
    value: 'Spot',
  },
  {
    label: 'Alternative',
    value: 'Alternative',
  },
  {
    label: 'Tyton',
    value: 'Tyton',
  },
];

export const itemTypeModel = ['Grain', 'Soybean Meal', 'Other Ingredient'];

export const uomModel = [
  {
    label: 'Bushels',
    value: 'Bushels',
  },
  {
    label: 'Tons',
    value: 'Tons',
  },
];

export const forecastTypeModel = [
  {
    label: 'Futures',
    value: 'Futures',
  },
  {
    label: 'Options',
    value: 'Options',
  },
];

export const optionsModel = [
  {
    label: 'PUT',
    value: 'PUT',
  },
  {
    label: 'CALL',
    value: 'CALL',
  },
];

export const buyOrSellModel = [
  {
    label: 'Buy',
    value: 'Buy',
  },
  {
    label: 'Sell',
    value: 'Sell',
  },
];

export const commoditySymbolModel = ['C', 'S', 'W', 'SM'];

export const cwFuturesMonthModel = [
  'H - March',
  'K - May',
  'N - Jul',
  'U - Sep',
  'Z - Dec',
];

export const sFuturesMonthModel = [
  'F - Jan',
  'H - March',
  'K - May',
  'N - Jul',
  'Q - Aug',
  'U - Sep',
  'X - Nov',
];

export const smFuturesMonthModel = [
  'F - Jan',
  'H - March',
  'K - May',
  'N - Jul',
  'Q - Aug',
  'U - Sep',
  'V - Oct',
  'Z - Dec',
];

export const yearModel = ['24', '25', '26', '27'];

export const otherOrderTypeModel = [
  'Market',
  'Working Order',
  // 'Limit',
  'MOC',
  'MOO',
];

export const exchangeOrderTypeModel = ['Exchange'];

export const exchangeTypeModel = [
  {
    label: 'Give',
    value: 'Give',
  },
  {
    label: 'Take',
    value: 'Take',
  },
];

export const workingOrderTypeModel = ['Good to Cancel (GTC)', 'Day Order'];

export const shipmentModel = [
  {
    label: 'FOB Freight',
    value: 'FOB Freight',
  },
  {
    label: 'Delivered',
    value: 'Delivered',
  },
];

// export const shipmentModeModel = ['Truck', 'Rail', 'Vessel', 'Barge'];

// export const altContractTypeModel = [
//   'Futures',
//   'Basis',
//   'Spot',
//   'DPQTY',
//   'HTA',
//   'Flat',
// ];
