import Cookies from 'js-cookie';

const COOKIE_NAME = 'token';

const sessionCookies = {
  getSessionCookies: () => {
    return JSON.parse(Cookies.get(COOKIE_NAME) || '{}');
  },

  updateSessionCookies: (sessionData) => {
    Cookies.set(COOKIE_NAME, JSON.stringify(sessionData));
  },

  clearSessionCookies: () => {
    Cookies.remove(COOKIE_NAME);
  },
};

export default sessionCookies;
