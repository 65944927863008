import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loginUserComplete } from 'store/modules/authentication';
import sessionCookies from 'utils/cookies/sessionCookies';
import { initializeAPIwithAuthentication } from 'services/api/core';
import { retrieveUserRoles } from 'store/modules/currentUser';
import jwt from 'jwt-decode';
import { logoutUser } from 'store/modules/authentication';

const useSessionAuth = () => {
  const [checkingSession, setCheckingSession] = useState(true);
  const [sessionValid, setSessionValid] = useState(false);
  const dispatch = useDispatch();

  const { isAuthenticated } = useSelector((state) => state.authentication);

  useEffect(() => {
    if (isAuthenticated) {
      setSessionValid(true);
      setCheckingSession(false);
    } else {
      setSessionValid(false);
    }
  }, [isAuthenticated]);

  const validateCurrentSession = () => {
    setCheckingSession(true);
    const { token } = sessionCookies.getSessionCookies();
    if (token) {
      const decodedToken = jwt(token);
      //convert to Date Format by using .toUTCString()
      if (decodedToken.exp * 1000 > Date.now()) {
        decodedToken.name =
          decodedToken[
            'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'
          ];
        delete decodedToken[
          'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'
        ];
        initializeAPIwithAuthentication(token);
        dispatch(retrieveUserRoles(decodedToken.name));
        dispatch(loginUserComplete());
        setCheckingSession(false);
      } else {
        setSessionValid(false);
        setCheckingSession(false);
        dispatch(logoutUser());
      }
    } else {
      dispatch(logoutUser());
    }
  };

  return {
    checkingSession,
    sessionValid,
    validateCurrentSession,
  };
};

export default useSessionAuth;
