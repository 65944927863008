const userRoleModel = {
  columns: 1,
  formInputs: {
    userType: {
      type: 'checkbox',
      isVisible: true,
      label: 'User Type',
      value: [],
      column: 1,
      randomNumber: 1,
      options: [
        {
          value: 'Administrator',
          label: 'Administrator',
          name: 'admin',
          flag: 1,
        },
        {
          value: 'Originator',
          label: 'Originator',
          name: 'originator',
          flag: 2,
        },
        {
          value: 'Moderator',
          label: 'Moderator',
          name: 'moderator',
          flag: 3,
        },
        {
          value: 'HedgeDesk',
          label: 'Hedge Desk',
          name: 'hedgedesk',
          flag: 4,
        },
      ],
    },
  },
};

export default userRoleModel;
