import React, { useEffect, useState } from 'react';
import LoadingIcons from 'react-loading-icons';

import LocationListContainer from './Container/LocationListContainer';
import NewLocationWindowContainer from './Container/NewLocationContainer';
import LocationDetailContainer from './Container/LocationDetailContainer';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearSelectedLocation,
  retrieveLocationList,
} from 'store/modules/location';

export default function LocationWindow(props) {
  const dispatch = useDispatch();
  const { selectedLocation } = useSelector((state) => state.locations);
  const [currentMode, setCurrentMode] = useState('list');
  const [formData, setFormData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleModeChange = (mode) => {
    setCurrentMode(mode);
  };

  const handleValueUpdates = (childData) => {
    setFormData(childData);
  };

  const changeToList = () => {
    dispatch(clearSelectedLocation());
    dispatch(retrieveLocationList());
  };

  const handleCurrentContainer = () => {
    switch (currentMode) {
      case 'list':
        return <LocationListContainer onModeChange={handleModeChange} />;

      case 'form':
        return (
          <NewLocationWindowContainer
            onModeChange={handleModeChange}
            isUpdateMode={selectedLocation !== null}
            valuesUpdate={handleValueUpdates}
            returnBack={changeToList}
          />
        );

      case 'detail':
        return (
          <LocationDetailContainer
            onModeChange={handleModeChange}
            returnBack={changeToList}
          />
        );

      default:
        return <div>{"I'm sorry an error has occured"}</div>;
    }
  };
  return isLoading ? (
    <div className="location-loading-icon">
      <LoadingIcons.Oval stroke="#006730" />
      <br /> <br />
    </div>
  ) : (
    <div className="location-form">
      <div>{handleCurrentContainer()}</div>
    </div>
  );
}
