import React, { useState, useRef } from 'react';
import whitelogo from 'Assets/white-logo.png';
import './Header.css';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logoutUser } from 'store/modules/authentication';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Avatar,
  AppBar,
  AppBarSection,
  AppBarSpacer,
} from '@progress/kendo-react-layout';
import { Popup } from '@progress/kendo-react-popup';
let kendokaAvatar =
  'https://www.telerik.com/account/images/AvatarImages/default.gif';

export default function Header() {
  const { userRoles } = useSelector((state) => state.currentUser);
  const [showMenu, setShowMenu] = useState(false);
  const anchor = useRef(null);
  const user = useRef(null);
  const [isUserInfo, setIsUserInfo] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logoutUser());
    history.push('/login');
  };

  const showUserInfo = () => {
    setIsUserInfo(!isUserInfo);
  };

  const handleBurgerClick = () => {
    setShowMenu(!showMenu);
  };

  const avatarStyle = {
    flexBasis: '60px',
    width: '60px',
    height: '60px',
    marginRight: '20px',
    cursor: 'pointer',
  };

  return (
    <React.Fragment>
      <AppBar style={{ backgroundColor: '#006730' }}>
        <AppBarSection>
          <button
            className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base overflow-button"
            ref={anchor}
            onClick={handleBurgerClick}
          >
            <span className="k-icon k-i-menu" style={{ fontSize: '25px' }} />
          </button>
          <Popup
            anchor={anchor.current}
            show={showMenu}
            style={{
              marginLeft: -100,
            }}
          >
            <div className="content">
              <ul className="popup-ul">
                <Link to="/">
                  <li className="popup-li">
                    <span>Home</span>
                  </li>
                </Link>
                <Link to="/neworder">
                  <li className="popup-li">
                    <span>New Order</span>
                  </li>
                </Link>
                <Link to="/admin">
                  <li className="popup-li">
                    <span>Admin</span>
                  </li>
                </Link>
              </ul>
            </div>
          </Popup>
        </AppBarSection>
        <AppBarSection style={{ margin: '10px' }}>
          <img src={whitelogo} />
        </AppBarSection>
        <AppBarSpacer />
        <AppBarSection>
          <Avatar type="image" style={avatarStyle}>
            <img src={kendokaAvatar} onClick={showUserInfo} ref={user} />
          </Avatar>
          <Popup
            anchor={user.current}
            show={isUserInfo}
            style={{ borderRadius: '5px' }}
          >
            <div className="header-user-info">
              <div id="header-container">
                <div id="user-type">User Type:</div>
                <div id="user-role">
                  {userRoles[0]} {userRoles[1] !== undefined ? <br /> : null}
                  {userRoles[1]} {userRoles[2] !== undefined ? <br /> : null}
                  {userRoles[2]} {userRoles[3] !== undefined ? <br /> : null}
                  {userRoles[3]}
                </div>
                <button id="header-logout" onClick={handleLogout}>
                  Logout
                </button>
              </div>
            </div>
          </Popup>
        </AppBarSection>
      </AppBar>
      <style>{`
            body {
                background: #dfdfdf;
            }
            .title {
                font-size: 18px;
                margin: 0;
            }
            .k-badge-container {
                margin-right: 8px;
            }
            .popup-ul {
                font-size: 14px;
                list-style-type: none;
                padding: 0;
                margin: 0;
                display: flex;
            }
            .popup-li {
                margin: 0 9px;
            }
            .popup-li:hover {
                cursor: pointer;
                color: #84cef1;
            }
            .k-appbar-section {
                display: flex;
            }
            .k-button k-button-md k-rounded-md k-button-solid k-button-solid-base {
                padding: 0 8px;
                color:
                white;
            }
            .content .popup-li {
                margin: 0;
                padding: 6px 5px;
                max-height: 100px;
            }
            .content .popup-ul {
                display: block;
                max-height: 100px;
            }
            .content {
                padding: 4px 8px;
                max-height: 100px;
            }
            @media only screen and (max-width: 600px) {
                .appbar-items,
                .user-actions,
                
                .k-appbar-separator {
                    display: none;
                }
                .overflow-button {
                    display: block;
                    color:
                 white;
                }
                .content .popup-ul .popup-li:last-child {
                    display: block;
                }
            }
            /* Small devices (portrait tablets and large phones, 600px and up) */
            @media only screen and (min-width: 600px) {
                .appbar-items,
                .user-actions .k-badge-container,
                
                .k-appbar-separator {
                    display: none;
                }
                .overflow-button {
                    display: block;
                    color:
                    white;
                }
              /*  .content .popup-ul .popup-li:last-child {
                    display: none;
                }   */
            }
            /* Medium devices (landscape tablets, 769px and up) */
            @media only screen and (min-width: 769px) {
                .appbar-items,
                .user-actions .k-badge-container,
                .k-appbar-separator,
                .k-avatar {
                    display: block;
                }
                .overflow-button {
                    display: none;
                    color:
                 white;
                }
            }
            /* Large devices (laptops/desktops, 992px and up) */
            @media only screen and (min-width: 992px) {}
            `}</style>
    </React.Fragment>
  );
}
