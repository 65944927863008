import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { uploadVendorFile } from 'store/modules/vendor';
import { Button } from '@progress/kendo-react-buttons';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Modal } from 'antd';
import { clearUpdaloadVendorStates } from 'store/modules/vendor';
import { Loader } from '@progress/kendo-react-indicators';

export default function FileUpload(props) {
  const { uploadedVendor, uploadVendorMessage, uploadVendorError } =
    useSelector((state) => state.vendors);
  const dispatch = useDispatch();
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [showRoleSelector, setShowRoleSelector] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (uploadedVendor || uploadVendorError !== '') {
      if (
        uploadVendorMessage ===
        'Failed to read the request form. Request body is required.'
      ) {
        setErrorMessage('No File Uploaded');
      } else if (uploadVendorMessage === 'The file is not supported') {
        setErrorMessage('Incorrect File Type');
      } else if (
        uploadVendorMessage.includes('Successful Entity Details') === true &&
        uploadedVendor === true
      ) {
        setErrorMessage('File Sucessfully Uploaded');
      } else if (
        uploadVendorMessage ===
        'Problem Updating Entity: There is no row at position 2.'
      ) {
        setErrorMessage('Invalid file content format');
      } else {
        setErrorMessage(
          'Something went wrong. Please contact your system Administrator'
        );
      }
      setShowRoleSelector(true);
      setLoading(false);
    } else {
      setErrorMessage(
        'Something went wrong. Please contact your system Administrator'
      );
    }
  }, [uploadVendorMessage]);

  const handleCloseModal = () => {
    if (errorMessage === 'File Sucessfully Uploaded') {
      props.returnHome();
    }
    setShowRoleSelector(false);
    dispatch(clearUpdaloadVendorStates());
  };

  const saveFile = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const uploadFile = (e) => {
    e.preventDefault();
    const batchVendors = new FormData();
    batchVendors.append('batchVendors', file);
    batchVendors.append('fileName', fileName);
    dispatch(uploadVendorFile(batchVendors));
    setLoading(true);
  };

  return (
    <div>
      <form>
        <input type="file" onChange={saveFile}></input>
        <Button type="submit" onClick={uploadFile}>
          Upload
        </Button>
      </form>
      {loading && (
        <Loader
          size={'large'}
          style={{
            position: 'absolute',
            top: '30%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
          }}
          type={'infinite-spinner'}
        />
      )}
      <Modal
        open={showRoleSelector}
        footer={null}
        onCancel={handleCloseModal}
        title={'Upload Status Message'}
      >
        {errorMessage}
      </Modal>
    </div>
  );
}
