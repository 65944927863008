import React, { useState, useEffect, useRef } from 'react';
import {
  retrieveOrderDetails,
  retrieveOrderList,
  retrieveWorkingDataState,
} from 'store/modules/order';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Input } from '@progress/kendo-react-inputs';
import { process } from '@progress/kendo-data-query';
import FillWorkingOrders from '../../FillOrders/FillWorkingOrders';
import MyCell from '../../FillOrders/CustomCell';
import { ColumnMenu, DateCell } from '../../../Common/columnMenu';
import '../../OrdersStyle.css';
import {
  ExcelExport,
  ExcelExportColumn as ExportColumn,
} from '@progress/kendo-react-excel-export';
import { Loader } from '@progress/kendo-react-indicators';
import { styles } from './styles';

export default function WorkingOrdersList(props) {
  const dispatch = useDispatch();
  const {
    workingOrderList,
    loadedWorkingOrders,
    loadedWorkingOrderFill,
    submittedWorkingFill,
    filtersWorkingDataState,
    withWorkingFilters,
  } = useSelector((state) => state.orders);

  const createDataState = (dataState) => {
    return {
      result: process(workingOrderList.slice(0), dataState),
      sort: dataState.sort,
      dataState: dataState,
    };
  };

  const initialDataState = createDataState({
    sort: [
      {
        field: 'submitDate',
        dir: 'desc',
      },
    ],
    take: 10,
    skip: 0,
  });

  const [result, setResult] = useState();
  const [dataState, setDataState] = useState();
  const [date, setDate] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(retrieveOrderList(false, date, 1));
  }, []);

  useEffect(() => {
    setLoading(true);
    if (submittedWorkingFill === true) {
      dispatch(retrieveOrderList(false, date, 1));
    }
  }, [submittedWorkingFill]);

  useEffect(() => {
    if (loadedWorkingOrders && !withWorkingFilters) {
      setLoading(false);
      setResult(initialDataState.result);
      setDataState(initialDataState.dataState);
    } else if (loadedWorkingOrders && withWorkingFilters) {
      setLoading(false);
      const updatedState = createDataState(filtersWorkingDataState);
      setResult(updatedState.result);
      setDataState(updatedState.dataState);
    }
  }, [workingOrderList]);

  const dataStateChange = (e) => {
    const updatedState = createDataState(e.dataState);
    setResult(updatedState.result);
    setDataState(updatedState.dataState);
    dispatch(retrieveWorkingDataState(e.dataState));
  };

  const handleOrderSelection = (orderSelected) => {
    dispatch(retrieveOrderDetails(orderSelected));
    props.onModeChange('detail');
  };

  const onSearch = (event) => {
    const {
      target: { value },
    } = event;

    const newOrderList = workingOrderList.filter(
      (order) =>
        order.boardPeriod.toLowerCase().includes(value.toLowerCase()) ||
        order.commodity.toLowerCase().includes(value.toLowerCase()) ||
        order.contractCount
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()) ||
        order.contractType.toLowerCase().includes(value.toLowerCase()) ||
        order.submitDateOnly
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()) ||
        order.submitTimeOnly
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()) ||
        order.group.toLowerCase().includes(value.toLowerCase()) ||
        order.id.toString().toLowerCase().includes(value.toLowerCase()) ||
        order.locationID.toLowerCase().includes(value.toLowerCase()) ||
        order.orderType.toLowerCase().includes(value.toLowerCase()) ||
        order.quantity.toString().toLowerCase().includes(value.toLowerCase()) ||
        order.section.toLowerCase().includes(value.toLowerCase()) ||
        order.deliveryEndDateOnly
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()) ||
        order.deliveryEndTimeOnly
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()) ||
        order.uom.toLowerCase().includes(value.toLowerCase()) ||
        order.userFullName.toLowerCase().includes(value.toLowerCase()) ||
        order.userName.toLowerCase().includes(value.toLowerCase()) ||
        order.wOlimitPrice
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()) ||
        order.wOstopPrice.toString().toLowerCase().includes(value.toLowerCase())
    );
    setResult(newOrderList);
  };

  const myCustomCell = (props) => {
    return (
      <MyCell
        {...props}
        onRowClick={props.dataItem}
        orderType={'Working Order'}
      />
    );
  };

  const _export = useRef(null);

  const handleExport = () => {
    setLoading(false);
  };

  const excelExport = () => {
    delete dataState.take;
    const exportList = process(workingOrderList.slice(0), dataState);
    if (!_export.current) {
      return;
    }
    setLoading(true);
    _export.current.save(exportList);
  };

  const grid = (
    <Grid
      data={result}
      {...dataState}
      onDataStateChange={dataStateChange}
      pageable={true}
      resizable={true}
      onRowClick={handleOrderSelection}
      sortable={{
        allowUnsort: true,
        mode: 'multiple',
      }}
    >
      <Column title="Action" cell={myCustomCell} width="60px" />
      <Column
        field="section"
        title="Region"
        style={{ whiteSpace: 'pre-wrap' }}
        filter={'text'}
        columnMenu={ColumnMenu}
        width="95px"
      />
      <Column
        field="group"
        title="Order Group"
        filter={'text'}
        columnMenu={ColumnMenu}
      />
      <Column
        field="id"
        title="Order Trans #"
        filter={'text'}
        columnMenu={ColumnMenu}
      />

      <Column
        field="submitDate"
        title="Sub. Date"
        filter={'text'}
        columnMenu={ColumnMenu}
        cell={DateCell}
      />
      <Column
        field="orderType"
        title="Trade Type"
        filter={'text'}
        columnMenu={ColumnMenu}
      />
      <Column
        field="userFullName"
        title="Trader"
        filter={'text'}
        columnMenu={ColumnMenu}
      />
      {/* <Column
      field="vendorID"
      title="Vendor ID"
      filter={'text'}
      columnMenu={ColumnMenu}
    /> */}
      <Column
        field="vendorName"
        title="Farmer/ Vendor"
        filter={'text'}
        columnMenu={ColumnMenu}
      />
      <Column
        field="contractType"
        title="Buy / Sell"
        filter={'text'}
        columnMenu={ColumnMenu}
      />
      <Column
        field="contractCount"
        title="# Contracts"
        filter={'text'}
        columnMenu={ColumnMenu}
      />
      <Column
        field="boardPeriod"
        title="Board Period"
        filter={'text'}
        columnMenu={ColumnMenu}
      />
      <Column
        field="deliveryEndDateOnly"
        title="Del. Date"
        filter={'text'}
        columnMenu={ColumnMenu}
      />
      {/* <Column
      field="deliveryEnd"
      title="Del. Date"
      filter={'text'}
      columnMenu={ColumnMenu}
    /> 
    <Column
      field="deliveryEndTimeOnly"
      title="Del. Time"
      filter={'text'}
      columnMenu={ColumnMenu}
    /> */}
      <Column
        field="quantity"
        title="Quantity"
        filter={'text'}
        columnMenu={ColumnMenu}
      />
      <Column
        field="locationID"
        title="Delivery Location"
        filter={'text'}
        columnMenu={ColumnMenu}
      />
      <Column
        field="commodity"
        title="Commodity"
        filter={'text'}
        columnMenu={ColumnMenu}
      />
      <Column field="uom" title="UOM" filter={'text'} columnMenu={ColumnMenu} />
      <Column
        field="wOstopPrice"
        title="Stop Price"
        filter={'text'}
        columnMenu={ColumnMenu}
      />
      <Column
        field="wOlimitPrice"
        title="Limit Price"
        filter={'text'}
        columnMenu={ColumnMenu}
      />
    </Grid>
  );
  return (
    <div id="adminwindow">
      <div className="market-fill-order">
        {loadedWorkingOrderFill && <FillWorkingOrders />}
      </div>
      <div className="all-open-orders-grid">
        <div className="toolbar-container">
          <div className="search-box">
            <Input
              onChange={onSearch}
              label="Enter Text Here"
              className="search-input"
            />
          </div>
          <div className="export-btn">
            <button
              title="Export Excel"
              className="export-open k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
              onClick={excelExport}
            >
              Export Working Orders
            </button>
          </div>
        </div>
        <ExcelExport
          ref={_export}
          fileName="Open Working Orders.xlsx"
          onExportComplete={handleExport}
        >
          <ExportColumn field="vendorID" title="Vendor ID" />
          {grid}
        </ExcelExport>
        <style>{styles}</style>
        {loading && (
          <Loader
            size={'large'}
            style={{
              position: 'absolute',
              top: '30%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
            }}
            type={'infinite-spinner'}
          />
        )}
      </div>
    </div>
  );
}
