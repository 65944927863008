import React, { useEffect } from 'react';
import { BiXCircle } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import LoadingIcons from 'react-loading-icons';
import { Divider } from 'antd';
import { retrieveVendorDetails } from 'store/modules/vendor';
import { useDispatch } from 'react-redux';
// import PropTypes from 'prop-types';

export default function OrderDetails(props) {
  const dispatch = useDispatch();
  const { loadingOrderDetails, selectedOrder } = useSelector(
    (state) => state.orders
  );
  const { selectedVendor } = useSelector((state) => state.vendors);

  const returnHome = () => {
    props.returnBack();
    props.onModeChange('list');
  };

  useEffect(() => {
    if (selectedOrder) {
      dispatch(retrieveVendorDetails(selectedOrder));
    }
  }, [selectedOrder]);

  if (
    loadingOrderDetails === true &&
    selectedOrder === null &&
    selectedVendor !== null
  ) {
    <div>
      <LoadingIcons.Oval stroke="#006730" />
    </div>;
  }
  if (
    selectedOrder !== null &&
    loadingOrderDetails === false &&
    selectedVendor !== null
  ) {
    return (
      <div className="order-detail-container">
        <div className="return-button" id="return-button">
          <h2>Order Details</h2>
          <BiXCircle className="close-button" size={40} onClick={returnHome} />
        </div>
        <Divider style={{ margin: '10px' }} />
        <div className="market-detail-row">
          {/* First Column */}
          <div className="market-detail-column">
            <div className="data-item">
              <label className="order-detail-label">Order ID:</label>
              {' ' + selectedOrder.id}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Region:</label>
              {' ' + selectedOrder.section}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Order Group:</label>
              {' ' + selectedOrder.group}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Submit Date:</label>
              {' ' + selectedOrder.submitDate}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Order Type:</label>
              {' ' + selectedOrder.orderType}
            </div>
            <div className="data-item">
              <label className="order-detail-label">User Name:</label>
              {' ' + selectedOrder.userName}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Contract Type:</label>
              {' ' + selectedOrder.contractType}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Contract Count:</label>
              {' ' + selectedOrder.contractCount}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Board Period:</label>
              {' ' + selectedOrder.boardPeriod}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Quantity:</label>
              {' ' + selectedOrder.quantity}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Commodity:</label>
              {' ' + selectedOrder.commodity}
            </div>
            <div className="data-item">
              <label className="order-detail-label">UOM:</label>
              {' ' + selectedOrder.uom}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Delivery Location:</label>
              {' ' + selectedOrder.locationID}
            </div>
            <div className="data-item">
              <label className="order-detail-label">wO Limit Price:</label>
              {' ' + selectedOrder.wOlimitPrice}
            </div>
            <div className="data-item">
              <label className="order-detail-label">wO Stop Price:</label>
              {' ' + selectedOrder.wOstopPrice}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Delivery Start Date:</label>
              {' ' + selectedOrder.deliveryStart}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Delivery End Date:</label>
              {' ' + selectedOrder.deliveryEnd}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Last Audit Date:</label>
              {' ' + selectedOrder.auditWHEN}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Legacy ID:</label>
              {' ' + selectedOrder.legacyID}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Audit By:</label>
              {' ' + selectedOrder.auditWHO}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Vendor:</label>
              {' ' + selectedOrder.vendorID + ' - ' + selectedVendor.vendorName}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Item Type:</label>
              {' ' + selectedOrder.itemType}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Verified?:</label>
              {' ' + selectedOrder.verified}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Filled?:</label>
              {' ' + selectedOrder.filled}
            </div>
          </div>
          {/* /Second Column */}
          <div className="market-detail-column">
            <div className="data-item">
              <label className="order-detail-label">Locked?:</label>
              {' ' + selectedOrder.locked}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Option Month:</label>
              {' ' + selectedOrder.optionMonth}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Option Year:</label>
              {' ' + selectedOrder.optionYear}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Forecast Type:</label>
              {' ' + selectedOrder.forecastType}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Exchange Type:</label>
              {' ' + selectedOrder.exchangeType}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Shipment:</label>
              {' ' + selectedOrder.shipment}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Note:</label>
              {' ' + selectedOrder.note}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Alt Location:</label>
              {' ' + selectedOrder.altLocation}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Alt Vendor: :</label>
              {' ' + selectedOrder.altVendor}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Option Type:</label>
              {' ' + selectedOrder.optionType}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Option Action:</label>
              {' ' + selectedOrder.optionAction}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Strike Price:</label>
              {' ' + selectedOrder.strikePrice}
            </div>
            <div className="data-item">
              <label className="order-detail-label">wO Type:</label>
              {' ' + selectedOrder.wOtype}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Limit Price:</label>
              {' ' + selectedOrder.limitPrice}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Exchange Price:</label>
              {' ' + selectedOrder.exchangePrice}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Exchange Account:</label>
              {' ' + selectedOrder.exchangeAccount}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Basis Price:</label>
              {' ' + selectedOrder.basisPrice}
            </div>
            <div className="data-item">
              <label className="order-detail-label">FoB Price:</label>
              {' ' + selectedOrder.foBprice}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Contract Number:</label>
              {' ' + selectedOrder.contractNumber}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Ship Mode:</label>
              {' ' + selectedOrder.shipMode}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Alt Contract Type:</label>
              {' ' + selectedOrder.altContractType}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Fill Date:</label>
              {' ' + selectedOrder.fillDate}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Fill Price:</label>
              {' ' + selectedOrder.fillPrice}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <LoadingIcons.Oval stroke="#006730" />
      </div>
    );
  }
}

// MarkerOrderDetail.propTypes = {
//   onModeChange: PropTypes.string,
//   returnBack: PropTypes.string,
// };
