import React, { useState, useEffect } from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { useDispatch, useSelector } from 'react-redux';
import { clearSelectedFillEdit, submitEditFill } from 'store/modules/order';
import '../OrdersStyle.css';
import { FormInput } from '../../Common/Forms/form-components';
import LoadingIcons from 'react-loading-icons';

export default function EditFill() {
  const {
    selectedFillEdit,
    fillDataInfo,
    loadedFillData,
    loadedEditFill,
    submittedEditFill,
  } = useSelector((state) => state.orders);
  const dispatch = useDispatch();
  const [fillOrderDetails, setFillOrderDetails] = useState([selectedFillEdit]);
  const [fillData, setFillData] = useState();

  const [multiplier, setMultiplier] = useState();

  // const pick = (obj, arr) =>
  //   arr.reduce(
  //     (acc, record) => (record in obj && (acc[record] = obj[record]), acc),
  //     {}
  //   );

  const cancelFill = () => {
    dispatch(clearSelectedFillEdit());
  };

  useEffect(() => {
    if (selectedFillEdit.commodity === 'SBM') {
      setMultiplier(100);
    } else if (
      ['Corn', 'Milling Wheat', 'Sorghum', 'Soybeans'].includes(
        selectedFillEdit.commodity
      ) &&
      selectedFillEdit.itemType.toLowerCase() === 'grain'
    ) {
      setMultiplier(5000);
    } else {
      setMultiplier(36);
    }
  }, [selectedFillEdit]);

  useEffect(() => {
    const fillInfo = { ...fillDataInfo, ...selectedFillEdit };
    setFillData(fillInfo);
  }, [fillDataInfo]);

  useEffect(() => {
    if (loadedFillData === true && loadedEditFill === true) {
      setFillOrderDetails([fillData]);
    }
  }, [fillData]);
  useEffect(() => {
    if (submittedEditFill === true) {
      cancelFill();
    }
  }, [submittedEditFill]);

  const fillOrder = (dataItem) => {
    dataItem.orderId = fillOrderDetails[0].id;
    dataItem.fillID = fillOrderDetails[0].fillID;
    const fillData = dataItem;
    // delete Object.assign(fillData, {
    //   ['contractCount']: fillData['fillBushels'],
    // })['fillBushels'];

    // const newFillData = pick(fillData, [
    //   'orderId',
    //   'fillPrice',
    //   'quantity',
    //   'contractCount',
    //   'fillID',
    // ]);
    dispatch(submitEditFill(fillData));
  };

  return !loadedFillData ? (
    <LoadingIcons.Oval stroke="#006730" />
  ) : (
    <Form
      onSubmit={fillOrder}
      render={(formRenderProps) => (
        <FormElement>
          <div className="fill-order-container">
            <div className="fill-order-tbl">
              <div className="fill-order-inputs">
                <div className="fill-price-input">
                  <Field
                    id={'fillPrice'}
                    name={'fillPrice'}
                    label={'($) Fill Price'}
                    component={FormInput}
                  />
                </div>
                <div className="fill-qty-input">
                  <Field
                    id={'contractCount'}
                    name={'contractCount'}
                    label={'Fill Quantity'}
                    component={FormInput}
                    onChange={(e) => {
                      const quantityValue = e.target.value;
                      formRenderProps.onChange('quantity', {
                        value: quantityValue * multiplier,
                      });
                    }}
                  />
                </div>
                <div className="fill-bushels-input">
                  <Field
                    id={'quantity'}
                    name={'quantity'}
                    label={'# of Bushels'}
                    component={FormInput}
                    onChange={(e) => {
                      const quantityValue = e.target.value;
                      formRenderProps.onChange('contractCount', {
                        value: Math.round(quantityValue / multiplier),
                      });
                    }}
                  />
                </div>
                <div className="bushel-count">
                  <span>
                    Orders Bushel Count <br /> {selectedFillEdit.quantity}
                  </span>
                </div>
              </div>
              <br />
              <Grid data={fillOrderDetails} resizable={true}>
                <Column field="fillID" title="Fill Trans #" />
                <Column field="fillPrice" title="Fill Price" />
                <Column field="quantity" title="Quantity" />
                <Column field="submitDateOnly" title="Sub. Date" />
                <Column field="submitTimeOnly" title="Sub. Time" />
                <Column field="orderType" title="Trade Type" />
                <Column field="userFullName" title="Trader" />
                <Column field="contractType" title="Buy/Sell" />
                <Column field="contractCount" title="# Contracts" />
                <Column field="boardPeriod" title="Period" />
                <Column field="locationID" title="Dest" />
                <Column field="commodity" title="Commodity" />
                <Column field="uom" title="UOM" />
                <Column field="wOstopPrice" title="W.O Stop Price" />
                <Column field="wOlimitPrice" title="W.O Limit Price" />
              </Grid>
              <div className="k-form-buttons">
                <Button
                  themeColor={'primary'}
                  type={'submit'}
                  disabled={!formRenderProps.allowSubmit}
                >
                  Fill Order
                </Button>
                <Button onClick={cancelFill}>Cancel</Button>
                <Button onClick={formRenderProps.onFormReset}>Clear</Button>
              </div>
            </div>
          </div>
        </FormElement>
      )}
    />
  );
}
