import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Accordion from '../Common/Accordion/Accordion';
import VendorWindow from './Vendor/VendorWindow';
import UserWindow from './User/UserWindow';
import LocationWindow from './Location/LocationWindow';
import NotificationWindow from './Notification/NotificationWindow';
import AccountSettingsWindow from './AccountSettings/AccountSettingsWindow';

export default function AdminScreen(props) {
  const { userRoles } = useSelector((state) => state.currentUser);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isModerator, setIsModerator] = useState(false);
  const [isOriginator, setIsOriginator] = useState(false);
  const [isHedgeDesk, setIsHedgeDesk] = useState(false);
  const [subWindows, setSubwindows] = useState([]);
  const [subForms, setSubForms] = useState([]);

  useEffect(() => {
    setIsAdmin(Array.from(userRoles).includes('Administrator'));
    setIsModerator(Array.from(userRoles).includes('Moderator'));
    setIsOriginator(Array.from(userRoles).includes('Originator'));
    setIsHedgeDesk(Array.from(userRoles).includes('HedgeDesk'));
  }, [userRoles]);

  useEffect(() => {
    if (isAdmin === true || isModerator === true) {
      setSubwindows([
        'User Information',
        'Vendor Information',
        'Location Information',
        'Manage Notification',
        'Account Settings',
      ]);
    } else {
      setSubwindows(['Account Settings']);
    }
  }, [isAdmin, isModerator, isOriginator, isHedgeDesk]);

  useEffect(() => {
    if (isAdmin === true || isModerator === true) {
      setSubForms([
        <UserWindow key="userWindow" />,
        <VendorWindow key="vendorWindow" />,
        <LocationWindow key="locationWindow" />,
        <NotificationWindow key="notificationWindow" />,
        <AccountSettingsWindow key="accountWindow" />,
      ]);
    } else {
      setSubForms([<AccountSettingsWindow key="accountWindow" />]);
    }
  }, [isAdmin, isModerator, isOriginator, isHedgeDesk]);

  return (
    <div id="adminwindow" style={{ height: '100%' }}>
      <Accordion forms={subForms} data={subWindows}></Accordion>
    </div>
  );
}
